import React, { useEffect, useState } from "react";

import './RoundRobin.css';
import '../../Styles/RobinStyles.css';

import { Participant, Group, Round, Match, Stage, ChangesTournamentByBracketData } from "../../Inerfaces";
import { CommonConstants } from "../../BracketHelpers/Constants";
import { Id } from "../../Types";
import ResultGrid from "../../BracketHelpers/BracketHelperComponents/ResultGrid/ResultGrid";
import EditMatchModal from "../../BracketHelpers/BracketHelperComponents/EditMatchModal/EditMatchModal";
import BracketMatch from "../../BracketHelpers/BracketHelperComponents/BracketMatch/BracketMatch";
import HelperFunctions from "../../BracketHelpers/BracketHelperFunctions";
import { changeTournamentByBracket } from "../../../../http/tournament/tournamentActionsAPI";
import { Spinner } from "react-bootstrap";
import Draggable from "react-draggable";

interface RoundRobinProps {
    participants: Participant[];
    rounds: Round[];
    matches: Match[];
    groups: Group[];
    stages: Stage[];
    match_game: Match;
    isAdmin: boolean;
    editMode: boolean;
    playerTeamId: string;
}

const RoundRobin: React.FC<RoundRobinProps> = ({ participants, rounds, matches, groups, stages, match_game, isAdmin, editMode, playerTeamId }) => {
    // Стейты
    const [selectedOpponent, setSelectedOpponent] = useState<number | string | null>(null); // Отслеживание курсора на команде
    const [currentMatches, setCurrentMatches] = useState<Match[]>(matches); // Актуальный массив матчей
    const [isEditing, setIsEditing] = useState(false); // Если режим редактирования | TODO: На неве надо сделать доступ только для админа!
    const [changedMatches, setChangedMatches] = useState<Match[]>([]);
    const [originalMatches, setOriginalMatches] = useState<Match[]>([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
    const [shouldUpdateOriginal, setShouldUpdateOriginal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRollBack, setIsRollBack] = useState<boolean>(false);
    
    
    
    const participantMap = new Map(participants.map(participant => [participant.id, participant]));
    
    
    
    useEffect(() => {
        setOriginalMatches(JSON.parse(JSON.stringify(matches)));
    }, []);
    
    
    
    // Функции для отслеживания курсора на мыши
    const handleMouseEnter = (id: Id): void => {
        setSelectedOpponent(id);
    };
    
    
    
    const handleMouseLeave = (): void => {
        setSelectedOpponent(null);
    };
    
    
    
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
    };
    
    
    
    // Функция для отслеживания отпускания div
    const handleDrop = (event: React.DragEvent<HTMLDivElement>, targetId: Id | null, targetPosition: number | null) => {
        event.preventDefault();
        
        let dragData = JSON.parse(event.dataTransfer.getData("application/json"));
        let draggedId = dragData.id;
        let draggedPosition = dragData.position;
        
        if (draggedId !== targetId && isEditing) {
            updateCurrentMatches(draggedId, targetId, draggedPosition, targetPosition);
            
            setTimeout(() => {
                determineResults(currentMatches);
            }, 0);
        }
    }
    
    
    
    // Обработчик для начала редактирования конкретного матча
    const handleEditStart = (matchId: string | number): void => {
        if (!isAdmin) { return }
        
        if (isEditing) {
            const match = currentMatches.find(m => m.id === matchId) || null;
            setSelectedMatch(match);
            setModalIsOpen(true);
        }
    };
    
    
    
    const handleSaveMatch = (updatedMatch: Match) => {
        setCurrentMatches(prevMatches => {
            const newMatches = prevMatches.map(match =>
                match.id === updatedMatch.id ? updatedMatch : match
            );
            determineResults(newMatches);
            return newMatches;
        });
        
        setChangedMatches(prev => {
            const index = prev.findIndex(match => match.id === updatedMatch.id);
            if (index !== -1) {
                return prev.map(match => match.id === updatedMatch.id ? updatedMatch : match);
            } else {
                return [...prev, updatedMatch];
            }
        });
    };
    
    
    
    const handleCloseModal = () => {
        setModalIsOpen(false);
        setSelectedMatch(null);
    };
    
    
    
    // Функция сохранения изменений
    const handleSave = async () => {
        setIsSaving(true);
        
        const updatedJson = generateFullJson();
        const changes: ChangesTournamentByBracketData = {
            tournament_id: stages[0].tournament_id as string,
            bracketData: updatedJson,
            matches: changedMatches,
            roll_back: isRollBack
        };
        
        try {
            const result = await changeTournamentByBracket(changes);
            if (changes) {
                setIsEditing(false);
                setChangedMatches([]);
            } else {
                throw new Error("Error while changing tournament");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSaving(false);
            setIsRollBack(false);
        }
        
        setIsSaving(false);
        setIsEditing(false);
    };
    
    
    
    useEffect(() => {
        if (shouldUpdateOriginal) {
            setOriginalMatches(currentMatches.map(match => ({
                ...match,
                opponent1: { ...match.opponent1 },
                opponent2: { ...match.opponent2 }
            })));
            setShouldUpdateOriginal(false);
        }
    }, [shouldUpdateOriginal, currentMatches]);
    
    
    
    const startEditing = () => {
        setShouldUpdateOriginal(true);
        setIsEditing(true);
    };
    
    
    
    const handleCancel = () => {
        setCurrentMatches(originalMatches);
        setChangedMatches([]);
        setIsEditing(false);
    };
    
    
    
    const generateFullJson = (): string => {
        return JSON.stringify({
            participant: participants,
            round: rounds,
            match: currentMatches,
            stage: stages,
            group: groups,
            match_game: match_game
        });
    };
    
    
    
    function updateOrAddMatch(currentMatch: Match) {
        const index = changedMatches.findIndex(match => match.id === currentMatch.id); // Предполагаем, что каждый match имеет уникальный идентификатор id
        
        if (index !== -1) {
            changedMatches[index] = currentMatch;
        } else {
            changedMatches.push(currentMatch);
        }
    }
    
    
    
    // Функция сброса результатов всех матчей
    const resetMatchResults = async () => {
        setIsRollBack(true);
        setChangedMatches([]);
        await handleSave();
        window.location.reload();
    };
    
    
    
    const updateCurrentMatches = (
        draggedId: Id | null,
        targetId: Id | null,
        draggedPosition: number | null,
        targetPosition: number | null,
    ) => {
        let newMatches = [...currentMatches];
        
        let draggedMatchIndex = newMatches.findIndex(match =>
            (match.opponent1?.id === draggedId && match.opponent1.position === draggedPosition) ||
            (match.opponent2?.id === draggedId && match.opponent2.position === draggedPosition)
        );
        
        let targetMatchIndex = newMatches.findIndex(match =>
            (match.opponent1?.id === targetId && match.opponent1.position === targetPosition) ||
            (match.opponent2?.id === targetId && match.opponent2.position === targetPosition)
        );
        
        if (draggedMatchIndex !== -1 && targetMatchIndex !== -1) {
            let draggedMatch = newMatches[draggedMatchIndex];
            let targetMatch = newMatches[targetMatchIndex];
            
            if (draggedMatch.group_id !== targetMatch.group_id) {
                currentMatches.forEach(currentMatch => {
                    if (currentMatch.opponent1 && currentMatch.opponent2) {
                        if (currentMatch.opponent1.id === draggedId) {
                            currentMatch.opponent1.id = targetId;
                            updateOrAddMatch(currentMatch);
                        } else if (currentMatch.opponent2.id === draggedId) {
                            currentMatch.opponent2.id = targetId;
                            updateOrAddMatch(currentMatch);
                        } else if (currentMatch.opponent1.id === targetId) {
                            currentMatch.opponent1.id = draggedId;
                            updateOrAddMatch(currentMatch);
                        } else if (currentMatch.opponent2.id === targetId) {
                            currentMatch.opponent2.id = draggedId;
                            updateOrAddMatch(currentMatch);
                        }
                    }
                });
                
                setCurrentMatches(newMatches);
                determineResults(newMatches);
            }
        } else {
            console.error('Не удалось найти draggedId или targetId');
        }
    };
    
    
    
    // Функция для изменения результата матчей
    const handleScoreChange = (matchId: Id, opponentPosition: number, score: string): void => {
        let updatedMatches = currentMatches.map(match => {
            if (match.id === matchId) {
                const parsedScore = parseInt(score, 10);
                
                if (opponentPosition === 1 && match.opponent1) {
                    match.opponent1.score = parsedScore;
                } else if (opponentPosition === 2 && match.opponent2) {
                    match.opponent2.score = parsedScore;
                }
            }
            return match;
        });
        
        setCurrentMatches(updatedMatches);
        
        setChangedMatches(prev => {
            const updatedChangedMatches = [...prev];
            const matchIndex = prev.findIndex(match => match.id === matchId);
            const changedMatch = updatedMatches.find(match => match.id === matchId);
            
            if (changedMatch) {
                if (matchIndex === -1) {
                    updatedChangedMatches.push(changedMatch);
                } else {
                    updatedChangedMatches[matchIndex] = changedMatch;
                }
            }
            
            return updatedChangedMatches;
        });
        
        determineResults(updatedMatches);
    };
    
    
    
    const handleStatusChange = (matchId: Id, status: string | number): void => {
        let updatedMatches = currentMatches.map(match => {
            if (match.id === matchId) {
                match.status = status;
            }
            
            return match;
        });
        
        setCurrentMatches(updatedMatches);
        
        setChangedMatches(prev => {
            const updatedChangedMatches = [...prev];
            const matchIndex = prev.findIndex(match => match.id === matchId);
            const changedMatch = updatedMatches.find(match => match.id === matchId);
            
            if (changedMatch) {
                if (matchIndex === -1) {
                    updatedChangedMatches.push(changedMatch);
                } else {
                    updatedChangedMatches[matchIndex] = changedMatch;
                }
            }
            
            return updatedChangedMatches;
        });
        
        determineResults(updatedMatches);
    };
    
    
    
    // Определяем результат матча на основе счета
    const determineResults = (updatedMatches: Match[]) => {
        let matches = [...updatedMatches];
        let changedMatchesSet = new Set<Match>(changedMatches);
        
        matches.forEach(match => {
            const isOpponent2Empty = match.opponent2?.id === CommonConstants.GuidEmpty;
            
            if (match.opponent1?.score != null && match.opponent2?.score != null) {
                if (match.opponent1.score === match.opponent2.score) {
                    // Ничья
                    match.opponent1.result = 'draw';
                    match.opponent2.result = 'draw';
                } else {
                    const isOpponent1Winner = isOpponent2Empty || match.opponent1.score > match.opponent2.score;
                    
                    match.opponent1.result = isOpponent1Winner ? 'win' : 'loss';
                    match.opponent2.result = isOpponent1Winner ? 'loss' : 'win';
                }
            }
        });
        
        setCurrentMatches(matches); // Обновляем состояние текущих матчей
        setChangedMatches(Array.from(changedMatchesSet));
    };
    
    
    
    let windowBracketContainer = document.getElementsByClassName(`breacket_mainContainer`)[0] as HTMLElement;
    let bracketContainer = document.getElementsByClassName(`contentContainer`)[0] as HTMLElement;
    
    const maxHeight = () => {
        if (windowBracketContainer && bracketContainer) {
            if (bracketContainer.offsetHeight > windowBracketContainer.offsetHeight) {
                return bracketContainer.offsetHeight - windowBracketContainer.offsetHeight;
            } else {
                return bracketContainer.offsetHeight - windowBracketContainer.offsetHeight;
            }
        } else {
            return 0;
        }
    }
    
    
    
    return (
        <Draggable disabled={!!selectedOpponent} bounds={{ left: 0, top: -maxHeight(), right: 0, bottom: 0 }}>
            <div className="contentContainer" style={{width: '100%'}}>
                <div className="roundRobin_mainContainer">
                    <EditMatchModal
                        show={modalIsOpen}
                        onHide={handleCloseModal}
                        match={selectedMatch}
                        participants={participantMap}
                        handleScoreChange={handleScoreChange}
                        handleStatusChange={handleStatusChange}
                        onSave={handleSaveMatch} // Передаем функцию сохранения
                    />
                    
                    <div className="adminsButton_container">
                        {isSaving  ? (
                                <Spinner className="bracketSavedSpinner" />
                            ) : (
                                <>
                                    {!isEditing && editMode && <button onClick={startEditing}>Редактирование</button>}
                                    {isEditing && (
                                        <>
                                            <button onClick={handleSave} disabled={isSaving}>Сохранить</button>
                                            <button onClick={handleCancel}>Отмена</button>
                                        </>
                                    )}
                                    {isAdmin && isEditing && <button onClick={resetMatchResults}>Сброс сетки</button>}
                                </>
                            )
                        }
                    </div>
                    
                    {groups.map(group => {
                        const matchesInGroup = currentMatches.filter(match => match.group_id === group.id);
                        
                        const participantIdsInGroup = new Set(matchesInGroup.flatMap(match => [
                            match.opponent1.id,
                            match.opponent2.id
                        ].filter(id => id !== null)));
                        
                        const participantsInGroup = participants.filter(participant => participantIdsInGroup.has(participant.id));
                        
                        return (
                            <div key={group.id} className="roundRobin_group">
                                <div className="robin_groupNameContainer">
                                    <h2 className="robin_groupName">Группа {group.number}</h2>
                                </div>
                                {rounds.filter((round) => round.group_id === group.id).map(round => (
                                    <div key={round.id} className="roundRobin_round">
                                        <div className="roundRobin_roundUpper">
                                            {matchesInGroup.filter((match) => match.round_id === round.id).map((match) => (
                                                <BracketMatch
                                                    key={match.id}
                                                    match={match}
                                                    participantMap={participantMap}
                                                    selectedOpponent={selectedOpponent}
                                                    isEditing={isEditing}
                                                    handleEditStart={handleEditStart}
                                                    handleMouseEnter={handleMouseEnter}
                                                    handleMouseLeave={handleMouseLeave}
                                                    handleDragStart={HelperFunctions.handleDragStart}
                                                    handleDragOver={handleDragOver}
                                                    handleDrop={handleDrop}
                                                    bracketType={stages[0].type}
                                                    matches={currentMatches}
                                                    roundName={`${round.id}`}
                                                    playerTeamId={playerTeamId}
                                                />
                                            ))}
                                        </div>
                                        
                                        <div className="roundRobin_roundLower">
                                            <hr />
                                        </div>
                                    </div>
                                ))}
                                
                                <ResultGrid participants={participantsInGroup} matches={currentMatches} selectedTeam={selectedOpponent} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </Draggable>    
    );
}

export default RoundRobin;