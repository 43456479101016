import React from 'react';
import { IMatchLobbyModel } from '../../../models/dto/lobby/IMatchLobbyModel';
import { useNavigate } from 'react-router-dom';
import { BoxArrowInRight } from 'react-bootstrap-icons';

const LobbyUnit: React.FC<{
    lobbyModel: IMatchLobbyModel;
}> = ({ lobbyModel }) => {
    const navigate  = useNavigate();
    
    return (
        <div className='lobby_unit' >
            <span>{lobbyModel.name}</span> <button onClick={() => navigate(`/matchlobby/${lobbyModel.lobbyId}`)}><BoxArrowInRight size={20} /></button>
        </div>
    ); 
};

export default LobbyUnit;