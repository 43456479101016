import React from 'react';
import { Spinner } from 'react-bootstrap';
import './ButtonWithLoader.css'

interface LoadingButtonProps {
    isLoading: boolean;
    onClick: (event: React.FormEvent) => void; 
    disabled?: boolean;
    children: React.ReactNode;
}

const ButtonWithLoader: React.FC<LoadingButtonProps> = ({
    isLoading,
    onClick,
    disabled = false,
    children,
}) => {
    return (
        <button
            className='button_with_loader'
            onClick={onClick}
            disabled={isLoading || disabled}
            type="submit"
        >
            {isLoading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
                children
            )}
        </button>
    );
};

export default ButtonWithLoader;