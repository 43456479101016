import 'react-alice-carousel/lib/alice-carousel.css'
import './Games.css'
import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import GamesItem from './GamesItem';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';

let deviceType: 'desktop' | 'tablet' | 'mobile';
const width = window.innerWidth;
if (width < 600) {
    deviceType = 'mobile';
} else if (width >= 600 && width <= 1024) {
    deviceType = 'tablet';
} else {
    deviceType = 'desktop';
}

const responsive = {
    2500: {
        items: 3
    },
    1920: {
        items: 3
    },
    1024: {
        items: 1,
        itemsFit: 'contain'
    },
    0: { 
        items: 1
    },
};

const Games: React.FC = () => { 
    const items = TournamentConstants.Games.map((game, idx) => (
        <GamesItem
            key={idx} 
            title={game.gameName}
            imgSrc={game.gameMainPageImg}
            gameTypeId={game.gameTypeId}
        />
    ));
    
    return (
        <div className='games_container'>
            <div className='games_title'>ИГРЫ</div>
            <AliceCarousel paddingRight={300} autoWidth items={items} responsive={responsive} mouseTracking={true} disableButtonsControls={true}/>
        </div>
    );
}


export default Games;