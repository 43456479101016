import React, { useState, useEffect } from 'react';
import './Main.css';
import { getTournamentsByFilters } from '../../http/tournament/tournamentActionsAPI';
import { ICommonTournamentModel } from '../../models/dto/tournament/ICommonTournamentModel';
import neva_text from '../../assets/img/NEVA.svg';
import News from '../../components/News/News';
import newsPhoto1 from '../../components/News/NewsPhotos/NewsPhoto1.jpg';
import newsPhoto2 from '../../components/News/NewsPhotos/NewsPhoto2.jpg';
import newsPhoto3 from '../../components/News/NewsPhotos/NewsPhoto3.png';
import { INewsModel } from '../../models/INewsModel';
import Games from '../../components/Games/Games';
import Tournaments from '../../components/Tournaments/Tournaments';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import { useNavigate } from 'react-router-dom';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';
import { TournamentSF } from '../../viewModels/filters/TournamentSF';

const messages = [
  'Киберспорт в сердце Северной столицы!',
  'Где сердце Северной столицы бьется в ритме киберспорта!',
  'Под звездами Петербурга рождаются киберлегенды!',
  'Твой киберспортивный путь начинается с северной столицы!'
];
const newsArr: INewsModel[] = [
  {
    title: 'Перввый турнир на NEVA',
    description: `
    Мы рады объявить о запуске первого тестового киберспортивного турнира на нашей киберспортивной платформе NEVA. Это событие станет важной вехой в развитии нашего проекта, открывая новые горизонты для развития киберспорта и создания уникального сообщества.
    
    Турнир пройдет с участием студентов одного из ведущих университетов страны - Государственного Университета Технологий и Инноваций Дизайна (ГУТИД). Их участие не только подчеркивает активное вовлечение молодежи в киберспортивную культуру, но и демонстрирует возможности нашей платформы в организации и проведении масштабных мероприятий.
    
    На турнире студенты ГУТИД смогут помериться силами в CS 2, демонстрируя свои навыки и стратегическое мышление. Это не только отличная возможность для участников проявить себя в конкурентной среде, но и ценный опыт, который пригодится в будущем.
    
    Мы верим, что этот турнир по CS 2 станет началом долгосрочного партнерства с учебными заведениями и организациями, стремящимися развивать киберспорт и цифровую культуру. Наша платформа создана для того, чтобы предоставлять уникальные возможности для проведения киберспортивных мероприятий любого уровня сложности, обеспечивая высокое качество организации и взаимодействия участников.
    
    Приглашаем всех заинтересованных следить за ходом турнира и поддерживать студентов ГУТИД в их стремлении к победе. Участие в турнире по CS 2 - это не просто соревнование, это шаг к формированию новой эры в киберспорте, где каждый может найти свое место и стать частью большого и вдохновляющего сообщества.
    
    Присоединяйтесь к нам в этом захватывающем путешествии и станьте свидетелями рождения новой киберспортивной платформы!
    `,
    imgSrc: newsPhoto1
  },
  {
    title: 'Итоги первого турнира',
    description: `
      Мы с восторгом делимся новостями о завершении первого тестового турнира на нашей платформе NEVA. Это мероприятие позволило продемонстрировать участникам текущий функционал и  выявить новые направления для развития  платформы "NEVA".  
      
      Турнир собрал талантливых студентов из Государственного Университета Технологий и Инноваций Дизайна (ГУТИД), которые продемонстрировали выдающиеся навыки и стратегическое мышление в игре CS 2. Их победа не только подчеркнула значимость молодежного участия в киберспорте, но и показала, насколько эффективной может быть наша платформа для организации подобных событий.
      
      Этот турнир не только дал возможность студентам ГУТИД проявить себя в конкурентной борьбе, но и обеспечил им ценный опыт, который окажется полезным в их будущем. Мы убеждены, что успешное проведение этого турнира заложит основу для долгосрочного сотрудничества с учебными заведениями и организациями, заинтересованными в развитии киберспорта и цифровой культуры.
    `,
    imgSrc: newsPhoto2
  },
  {
    title: 'Разработчики платформы',
    description: `
      Наша киберспортивная платформа, находящаяся на стадии разработки, обещает стать значимым новшеством в мире киберспорта. За её создание отвечает команда профессионалов из студии разработки GreenSpaceDev. Mы хотели бы представить вам этих талантливых людей, чья работа скоро позволит нам предложить вам уникальный продукт.
      
      Команда GreenSpaceDev:
      
      •Артём Шершнёв, CEO
      •Олег Кучеренко, Технический директор
      •Роман Ефременко, Frontend-разработчик
      •Юрий Зидерер, Project Manager
      •Евгения Ильина, Ведущий Разработчик
      •Владислав Дзирко, Backend-разработчик
      •Олег Соколов, Дизайнер
      •Богдан Рудневский, DevOps
      •Павел Лукин, DevOps
      
      Каждый член команды вносит свой важный вклад в разработку платформы, и мы благодарны им за их профессионализм и труд.
    `,
    imgSrc: newsPhoto3
  },
]

const Main: React.FC = () => {
  const [tournamentsList, setTournamentsList] = useState<ICommonTournamentModel[]>([]);
  const [fetchingTournamentsList, setFetchingTournamentsList] = useState(true);
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [currentMessage, setCurrentMessage] = useState('Киберспорт в сердце Северной столицы!');
  const [gameTypeIdsSelected, setGameTypeIdsSelected] = useState<string[]>([TournamentConstants.CS2.gameTypeId]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<TournamentSF>({
    gameTypeIds: gameTypeIdsSelected,
    page: 1,
    limit: 12
  });
  
  useEffect(() => {
    setFetchingTournamentsList(true);
    const updatedFilters = { ...filters, page: currentPage, gameTypeIds: gameTypeIdsSelected };
    getTournamentsByFilters(updatedFilters)
      .then(data => {
        if (data) {
          setTournamentsList(data.tournaments || []);
        } else {
          setTournamentsList([]);
        }
      })
      .catch(error => {
        console.error('Error fetching tournaments list:', error);
      })
      .finally(() => setFetchingTournamentsList(false));
  }, [gameTypeIdsSelected, currentPage]);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (index + 1) % messages.length;
      setIndex(nextIndex);
      setCurrentMessage(messages[nextIndex]);
    }, 5000);
    
    return () => clearInterval(intervalId);
  }, [index]);
  
  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };
  
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };
  
  const handleGameTypeChange = (gameTypeId: string) => {
    setGameTypeIdsSelected([gameTypeId]);
    setCurrentPage(1);
  };
  
  return (
    <div className='main_container'>
      <div className='main_NEVA'>
        <div className='test'><img src={neva_text} alt='NEVA' className='neva_text' /></div>
        <p>northern esports versus arena</p>
      </div>
      <div className='rotating_text'>
        {currentMessage}
      </div>
      <div className='gardient_animation_text'>
        northern esports versus arena
      </div>
      <div className='games_container'>
        <Games />
      </div>
      <div className='tournaments_container'>
        <div className='games_title'>ТУРНИРЫ</div>
        <div className='tournaments_games_container'>
          {fetchingTournamentsList ? (
            <CustomSpinner1 />
          ) : (
            <div className='pagination_wrapper'>
              {/* <button className='pagination_button' onClick={handlePrevPage} disabled={currentPage === 1}>{'<'}</button> */}
              {tournamentsList.length > 0 ? (
                <Tournaments tournaments={tournamentsList} />
              ) : (
                <p className='tournaments_games_container_empty'>НЕТ ДОСТУПНЫХ ТУРНИРОВ</p>
              )}
              {/* <button className='pagination_button' onClick={handleNextPage} disabled={tournamentsList.length < filters.limit}>{'>'}</button> */}
            </div>
          )}
          <div className='tournaments_games'>
            <div className="main-mini-game-container">
              {
                TournamentConstants.Games.slice(0, 2).map((game, index) => (
                  <div className={`main-mini-game-card ${gameTypeIdsSelected.includes(game.gameTypeId) ? 'selected' : ''}`}
                    key={index}
                    onClick={() => handleGameTypeChange(game.gameTypeId)}
                    style={{ backgroundImage: `url(${game.gameSmallImage})` }}
                  >
                    <div className='main-mini-game-card-title'>{game.gameName}</div>
                  </div>
                ))
              }
            </div>
            <div className='tournaments_games_link' onClick={() => { navigate('/games') }}>Смотреть больше</div>
          </div>
        </div>
      </div>
      <div className='news_container'>
        <News newsArr={newsArr} />
      </div>
    </div>
  );
};

export default Main;