import { Match } from "../Inerfaces";
import { Id } from "../Types";

class HelperFunctions {
    static incrementId(id: Id): Id {
        if (typeof id === 'number') {
            return id + 1;
        }
        if (typeof id === 'string') {
            return id;
        }
        return id;
    }
    
    static deepEqual(obj1: any, obj2: any): boolean {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }
    
    static getChangedMatches(originalMatches: Match[], currentMatches: Match[]): Match[] {
        return currentMatches.filter(currentMatch => {
            const originalMatch = originalMatches.find(m => m.id === currentMatch.id);
            return originalMatch && !HelperFunctions.deepEqual(originalMatch, currentMatch);
        });
    }
    
    static handleDragStart(event: React.DragEvent<HTMLDivElement>, id: Id | null, position: number | null, roundId: Id): void {
        const dragData = JSON.stringify({ id, position, round_id: roundId });
        event.dataTransfer.setData("application/json", dragData);
    }
}

export default HelperFunctions;