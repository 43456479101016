import React, { useContext, useEffect, useRef, useState } from 'react';
import './NotificationCenter.css';
import NotificationUnit from './NotificationUnit/NotificationUnit';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AppContext } from '../AppContext';
import { INotificationModel } from '../../models/dto/notification/INotificationModel';

interface NotificationCenterProps {
    isVisible: boolean;
    onUnreadNotificationsChange: (hasUnread: boolean) => void;
}

const NotificationCenter: React.FC<NotificationCenterProps> = ({ isVisible, onUnreadNotificationsChange }) => {
    const [notifications, setNotifications] = useState<INotificationModel[]>([]);
    const [activeTab, setActiveTab] = useState('inbox');
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const { player } = useContext(AppContext);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const inboxNotifications = notifications.filter(n => n.isPong === false);
    const sentNotifications = notifications.filter(n => n.isPong === true);

    const notificationActionHandlers: {
        [templateId: string]: {
            [action: string]: (notificationId: string) => Promise<void>;
        };
    } = {
        'TeamRequest': {
            'accept': async (notificationId: string) => {
                try {
                    await connection?.invoke("AcceptTeamNotification", notificationId);
                } catch (err) {
                    console.error('Error accepting team invite:', err);
                }
            },
            'decline': async (notificationId: string) => {
                try {
                    await connection?.invoke("DeclineTeamNotification", notificationId);
                } catch (err) {
                    console.error('Error declining team invite:', err);
                }
            }
        },
        'DeleteNotification': {
            'accept': async (notificationId: string) => {
                try {
                    await connection?.invoke("RemoveNotification", notificationId);
                } catch (err) {
                    console.error('Error deleting notification:', err);
                }
            }
        },
        'TournamentStaffRequest': {
            'accept': async (notificationId: string) => {
                try {
                    await connection?.invoke("AcceptAdditionalTournamentSlotInvitation", notificationId);
                } catch (err) {
                    console.error('Error accepting additional tournament slot invite:', err);
                }
            },
            'decline': async (notificationId: string) => {
                try {
                    await connection?.invoke("DeclineAdditionalTournamentSlotInvitation", notificationId);
                } catch (err) {
                    console.error('Error declining additional tournament slot invite:', err);
                }
            }
        },
        'TeamRoleRequest': {
            'accept': async (notificationId: string) => {
                try {
                    await connection?.invoke("AcceptAdditionalTeamSlotInvitation", notificationId);
                } catch (err) {
                    console.error('Error accepting additional team slot invite:', err);
                }
            },
            'decline': async (notificationId: string) => {
                try {
                    await connection?.invoke("DeclineAdditionalTeamSlotInvitation", notificationId);
                } catch (err) {
                    console.error('Error declining additional team slot invite:', err);
                }
            }
        }
    };

    const handleAction = async (notificationId: string, action: string | null, templateId: string | null): Promise<void> => {
        if (!action || !templateId) {
            console.warn(`Invalid action or templateId: action='${action}', templateId='${templateId}'`);
            return;
        }
        const handlers = notificationActionHandlers[templateId];
        if (handlers && handlers[action]) {
            try {
                await handlers[action](notificationId);
            } catch (err) {
                console.error(`Error executing action '${action}' for notification '${notificationId}':`, err);
            }
        } else {
            console.warn(`No handler found for action '${action}' in template '${templateId}'`);
        }
    };

    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_BACKEND_API_URL}notificationHub/${player?.playerId}`)
            .withAutomaticReconnect([500, 1000, 2000])
            .build();

        setConnection(connect);

        connect.start()
            .then(() => {
                connect.on('ReceiveNotifications', (receivedNotifications: INotificationModel[]) => {
                    setNotifications(receivedNotifications);
                    const hasUnread = receivedNotifications.some(n => n.state === 1);
                    onUnreadNotificationsChange(hasUnread);
                });
                connect.on('ReceiveNotification', (notification: INotificationModel) => {
                    setNotifications(prevNotifications => {
                        const exists = prevNotifications.some(n => n.notificationId === notification.notificationId);
                        if (!exists) {
                            return [...prevNotifications, notification];
                        }
                        return prevNotifications;
                    });
                    const hasUnread = notification.state === 1;
                    onUnreadNotificationsChange(hasUnread);
                });
                connect.on("ReceiveNotificationStateUpdate", (notificationState: INotificationModel) => {
                    setNotifications(prevNotifications => {
                        const index = prevNotifications.findIndex(n => n.notificationId === notificationState.notificationId);
                        if (index !== -1) {
                            return prevNotifications.map(n =>
                                n.notificationId === notificationState.notificationId ? notificationState : n
                            );
                        } else {
                            return [...prevNotifications, notificationState];
                        }
                    });
                });
            })
            .catch(err => console.error('SignalR Connection Error: ', err));

        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, [onUnreadNotificationsChange]);

    useEffect(() => {
        if (isVisible && connection) {
            const unreadNotifications = notifications.filter(n => n.state === 1  && !n.isPong);

            if (unreadNotifications.length > 0) {
                const timer = setTimeout(() => {
                    unreadNotifications.forEach(notification => {
                        if (notification.isPong) return;
                        connection.invoke("MarkNotificationsAsRead", notification.notificationId, player?.playerId)
                    });
                }, 3000); // 3 seconds delay

                return () => clearTimeout(timer);
            }
        }
    }, [isVisible, connection, notifications, player?.playerId]);


    return (
        <div ref={containerRef} className={`notification_center ${isVisible ? 'visible' : 'hidden'}`}>
            <div className='notification_center_header'>Центр уведомлений</div>
            <div className='notification_center_switch'>
                <div
                    onClick={() => setActiveTab('inbox')}
                    className={activeTab === 'inbox' ? 'notif_tab_active' : ''}
                >
                    Входящие
                </div>
                <div
                    onClick={() => setActiveTab('sent')}
                    className={activeTab === 'sent' ? 'notif_tab_active' : ''}
                >
                    Отправленные
                </div>
            </div>
            
            <div className='notification_units_container'>
                {activeTab === 'inbox' ? (
                    inboxNotifications.length > 0 ? (
                        inboxNotifications
                            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                            .map((notification) => (
                                <NotificationUnit
                                    key={notification.notificationId}
                                    notification={notification}
                                    onAction={handleAction}
                                />
                            ))
                    ) : (
                        <p className='notification_units_empty'>Здесь появятся Ваши уведомления.</p>
                    )
                ) : (
                    sentNotifications.length > 0 ? (
                        sentNotifications
                            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                            .map((notification) => (
                                <NotificationUnit
                                    key={notification.notificationId}
                                    notification={notification}
                                    onAction={handleAction}
                                />
                            ))
                    ) : (
                        <p className='notification_units_empty'>Нет отправленных уведомлений.</p>
                    )
                )}
            </div>
        </div>
    );
};

export default NotificationCenter;