import React from 'react';
import './Tournaments.css';
import TournamentsItem from './TournamentItem';
import { ICommonTournamentModel } from '../../models/dto/tournament/ICommonTournamentModel';

interface TournamentsProps {
    tournaments: ICommonTournamentModel[];
}

const Tournaments: React.FC<TournamentsProps> = ({ tournaments }) => {
    const sortedTournaments = tournaments.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
    return (
        <div className="tournaments">
            {sortedTournaments.slice(0, 4).map((tournament, index) => (
                <TournamentsItem
                    key={index}
                    name={tournament.name}
                    gameTypeId={tournament.gameTypeId}
                    logoUrl={tournament.logoUrl}
                    startDate={tournament.startDate}
                    maxSlots={tournament.maxSlots}
                    activeSlots={tournament.activeSlots}
                    tournamentId={tournament.tournamentId}
                />
            ))}
        </div>
    );
};

export default Tournaments;