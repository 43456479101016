import React, { useState } from 'react';
import { INewsModel } from '../../models/INewsModel';
import './NewsItem.css';
import NewsModalWindow from './NewsModalWindow';

interface NewsItemProps extends INewsModel {}

const NewsItem: React.FC<NewsItemProps> = ({ title, description, imgSrc }) => {
    const [showNewsModal, setShowNewsModal] = useState(false);
    
    const toggleNewsModal = () => {
        setShowNewsModal(!showNewsModal);
    };
    
    return (
        <div className="news_item_container" style={{ backgroundImage: `url(${imgSrc})` }} onClick={toggleNewsModal}>
            <div className="news_item_title">{title}</div>
            {showNewsModal && (
                <NewsModalWindow 
                    showModal={showNewsModal} 
                    closeModal={toggleNewsModal} 
                    title={title} 
                    description={description} 
                    imgSrc={imgSrc} 
                />
            )}
        </div>
    );
};

export default NewsItem;