import { useEffect, useState } from 'react';
import './CS2PlayerStats.css';
import { useParams } from 'react-router-dom';
import { ITotalShootingStats } from '../../models/dto/stats/cs2/playerStats/ITotalShootingStats';
import CS2WeaponStatistics from '../../components/CS2WeaponStatistics/CS2WeaponStatistics';
import { IPlayerPageModel } from '../../models/dto/player/IPlayerPageModel';
import { getPLayerInfo } from '../../http/userAPI';
import InDevelop from '../InDevelop/InDevelop';
import CS2MapStatistics from '../CS2MapStatistics/CS2MapStatistics';
import CS2Chart from '../CS2Chart/CS2Chart';


const shootingStat: ITotalShootingStats[] = [
    {
        matchId: "1",
        matchTeams: {
            teamAId: "team001",
            teamATag: "GRN",
            teamBId: "team002",
            teamBTag: "BLU"
        },
        tournamentName: "GREENSPACETOURNAMENT",
        matchDate: "19.07.2024 21:00",
        HS: 15,
        accuracy: 80
    },
    {
        matchId: "2",
        matchTeams: {
            teamAId: "team001",
            teamATag: "GRN",
            teamBId: "team002",
            teamBTag: "BLU"
        },
        tournamentName: "GREENSPACETOURNAMENT",
        matchDate: "19.07.2024 21:00",
        HS: 45,
        accuracy: 16
    },
    {
        matchId: "3",
        matchTeams: {
            teamAId: "team001",
            teamATag: "GRN",
            teamBId: "team002",
            teamBTag: "BLU"
        },
        tournamentName: "GREENSPACETOURNAMENT",
        matchDate: "19.07.2024 21:00",
        HS: 85,
        accuracy: 10
    },
    {
        matchId: "4",
        matchTeams: {
            teamAId: "team001",
            teamATag: "GRN",
            teamBId: "team002",
            teamBTag: "BLU"
        },
        tournamentName: "GREENSPACETOURNAMENT",
        matchDate: "19.07.2024 21:00",
        HS: 35,
        accuracy: 22
    },
    {
        matchId: "5",
        matchTeams: {
            teamAId: "team001",
            teamATag: "GRN",
            teamBId: "team002",
            teamBTag: "BLU"
        },
        tournamentName: "GREENSPACETOURNAMENT",
        matchDate: "19.07.2024 21:00",
        HS: 85,
        accuracy: 79
    },
    {
        matchId: "6",
        matchTeams: {
            teamAId: "team001",
            teamATag: "GRN",
            teamBId: "team002",
            teamBTag: "BLU"
        },
        tournamentName: "GREENSPACETOURNAMENT",
        matchDate: "19.07.2024 21:00",
        HS: 15,
        accuracy: 14
    },
    {
        matchId: "7",
        matchTeams: {
            teamAId: "team001",
            teamATag: "GRN",
            teamBId: "team002",
            teamBTag: "BLU"
        },
        tournamentName: "GREENSPACETOURNAMENT",
        matchDate: "19.07.2024 21:00",
        HS: 15,
        accuracy: 15
    },
]

const CS2PlayerStats: React.FC = () => {
    const [playerData, setPlayerData] = useState<IPlayerPageModel>();
    const { playerId } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [playerShootingStatData, setPlayerShootingStatData] = useState<ITotalShootingStats[]>();
    
    const fetchPlayerData = async () => {
        if (playerId) {
            try {
                const data = await getPLayerInfo(playerId);
                if (data) {
                    setPlayerData(data);
                }
            } catch (error) {
                console.error('Fetch Player Data error:', error);
            }
        }
    };
    
    useEffect(() => {
        setIsLoading(true);
        
        // TODO: let playerShootingStatData = getShootingStats(playerId: string);
        
        setTimeout(() => {
            setPlayerShootingStatData(shootingStat);
            setIsLoading(false);
        }, 2000);
    }, [playerId]);
    
    useEffect(() => {
        fetchPlayerData();
    }, [playerId]);
    
    return (
        <div className="cs2playerStats_container">
            <h5>СТАТИСТИКА ЗА ВСЁ ВРЕМЯ</h5>
            <hr />
            <InDevelop>
                <div className='cs2match_stats'>
                    <div className='cs2_statunit_container'>
                        <CS2Chart 
                            chartName='ПРОЦЕНТ ПОПАДАНИЯ В ГОЛОВУ (HS), %' 
                            chartData={playerShootingStatData ?? []} 
                            chartDataKey='HS' 
                            chartXLineDataType={'matchId'} 
                            chartMainLineColor='#45FFA6'
                            isLoading={isLoading} 
                        />
                    </div>
                    
                    <div className='cs2_statunit_container'>
                        <CS2Chart 
                            chartName='ПРОЦЕНТ ТОЧНОСТИ (ACCURACY), %' 
                            chartData={playerShootingStatData ?? []} 
                            chartDataKey='accuracy' 
                            chartXLineDataType={'matchId'} 
                            chartMainLineColor='#45FFA6' 
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </InDevelop>
            <hr />
            <h5>СТАТИСТИКА НА КАРТЕ</h5>
            <hr />
            <InDevelop>
            <div className='cs2_mapStat_container'>
                <CS2MapStatistics playerId={playerData?.playerId ?? null} />
            </div>
            </InDevelop>
            
            <hr />
            <h5>ТЕПЛОВАЯ ПРОИЗВОДИТЕЛЬНОСТЬ КАРТЫ</h5>
            <hr />
            <InDevelop>
                <div className='cs2_warmth_stat'>
                    
                </div>
            </InDevelop>
            <hr />
            <h5>СТАТИСТИКА ПО ОРУЖИЮ</h5>
            <hr />
            <div className='cs2weapon_stats'>
                <InDevelop>
                    <CS2WeaponStatistics playerId={playerData?.playerId ?? null} />
                </InDevelop>
            </div>
        </div>
    );
};

export default CS2PlayerStats;