import { ITableStats } from '../../models/dto/stats/cs2/ITableStats';
import './PlayerStatTable.css';
import React from 'react';

interface PlayerStatTableProps {
  data: ITableStats[];
  isMatchPage: boolean;
}

const PlayerStatTable: React.FC<PlayerStatTableProps> = ({ data, isMatchPage }) => {
  return (
    <table className='user_stat_table'>
      <thead>
        <tr>
        {data.some(row => row.date) && <th></th>}
        <th></th>
          <th className='user_stat_table_header'>KILLS</th>
          <th className='user_stat_table_header'>DEATHS</th>
          <th className='user_stat_table_header'>ASSISTS</th>
          <th className='user_stat_table_header'>HEADSHOTS</th>
          <th className='user_stat_table_header'>MVP</th>
          <th className='user_stat_table_header'>TOTAL SCORE</th>
        </tr>
      </thead>
      <tbody className='user_stat_table_body'>
        {data.map((row, index) => (
          <tr key={index} className='user_stat_table_row'>
            {row.date && <td className='user_stat_table_td'>{row.date}</td>}
            <td className='user_stat_table_td teams'>
              <div className='user_stat_table_td teams_container'>
                {isMatchPage ? (
                  <div className='user_stat_table_td_row_team_container'>
                    <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${row.logoUrl}`} className='user_stat_table_td_img' alt='' />
                    <div className='user_stat_table_td_row_team player'>{row.subject}</div>
                  </div>
                ) : (
                  <>
                    <div className='user_stat_table_td_row_team_container'>
                      <img src={`${process.env.REACT_APP_IMG_MINIO_URL}team.png`} className='user_stat_table_td_img' alt='' />
                      <div className='user_stat_table_td_row_team'>{Array.isArray(row.subject) && row.subject[0]}</div>
                    </div>
                    <div className='user_stat_table_td_row_team_container'>
                      <div className='user_stat_table_td_row_team'>{Array.isArray(row.subject) && row.subject[1]}</div>
                      <img src={`${process.env.REACT_APP_IMG_MINIO_URL}team3.png`} className='user_stat_table_td_img' alt='' />
                    </div>
                  </>
                )}
              </div>
            </td>
            <td className='user_stat_table_td'>{row.kills}</td>
            <td className='user_stat_table_td'>{row.deaths}</td>
            <td className='user_stat_table_td'>{row.assists}</td>
            <td className='user_stat_table_td'>{row.headshots}%</td>
            <td className='user_stat_table_td'>{row.mvp}</td>
            <td className='user_stat_table_td'>{row.total}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PlayerStatTable;