import React, { useEffect, useRef, useState } from "react"
import './Styles/Bracket.css';
import { BracketData } from "./Inerfaces"
import SingleElimination from "./components/SingleElimination/SingleElimination";
import DoubleElimination from "./components/DoubleElimination/DoubleElimination";
import RoundRobin from "./components/RoundRobin/RoundRobin";


interface BracketDataProps {
    bracketData: BracketData;
    editMode: boolean;
    isAdmin: boolean;
    playerTeamId: string;
}

const Bracket: React.FC<BracketDataProps> = ({ bracketData, editMode, isAdmin, playerTeamId }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [zoom, setZoom] = useState(100);
    const [minZoom, setMinZoom] = useState(100);
    const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
    
    const adjustZoomToFitRounds = () => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
        const roundWidth = 195;
        const totalRoundsWidth = bracketData.round.length * roundWidth - 75;
        
        const calculatedMinZoom = containerWidth > 0 ? (containerWidth / totalRoundsWidth) * 100 : 100;
        setMinZoom(calculatedMinZoom);
        setZoom(calculatedMinZoom);
    };
    
    useEffect(() => {
        if (containerRef.current) {
            setElementSize({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight
            });
        }
    }, []);
    
    useEffect(() => {
        adjustZoomToFitRounds();
        
        const handleResize = () => {
            adjustZoomToFitRounds();
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [bracketData]);
    
    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            event.preventDefault();
            const maxZoom = 150;
            
            setZoom((currentZoom) => {
                let newZoom = currentZoom + (event.deltaY < 0 ? 5 : -5);
                return Math.max(minZoom, Math.min(maxZoom, newZoom));
            });
        };
        
        const div = containerRef.current;
        div?.addEventListener('wheel', handleWheel, { passive: false });
        
        return () => {
            div?.removeEventListener('wheel', handleWheel);
        };
    }, [minZoom]);
    
    useEffect(() => {
        const adjustSizes = () => {
            if (containerRef.current) {
                const scale = zoom / 100;
                setElementSize({
                    width: containerRef.current.offsetWidth * scale,
                    height: containerRef.current.offsetHeight * scale
                });
            }
        };
        
        adjustSizes();
        window.addEventListener('resize', adjustSizes);
        return () => window.removeEventListener('resize', adjustSizes);
    }, [zoom]);
    
    
    
    // Все массивы
    const groups = bracketData.group;
    const matches = bracketData.match;
    const participants = bracketData.participant;
    const rounds = bracketData.round;
    const stages = bracketData.stage;
    const matchGame = bracketData.match_game;
    
    // Проверка массиовов
    if (!groups) {throw new Error('groups пустой');}
    if (!matches) {throw new Error('matchs пустой');}
    if (!participants) {throw new Error('participants пустой');}
    if (!rounds) {throw new Error('rounds пустой');}
    if (!stages) {throw new Error('stages пустой');}
    
    // Тип турнира
    let bracketType = stages.length > 1 ? `${stages[0].type}_${stages[1].type}` : `${stages[0].type}`;
    
    let renderBracketByType;
    if (bracketType === 'single_elimination') {
        renderBracketByType = 
            <SingleElimination
                participants={participants}
                rounds={rounds}
                matches={matches}
                stages={stages}
                groups={groups}
                match_game={matchGame}
                editMode={editMode}
                isAdmin={isAdmin}
                playerTeamId={`${playerTeamId}`}
                
            />
    } else if (bracketType === 'double_elimination') {
        renderBracketByType =
            <DoubleElimination
                participants={participants}
                rounds={rounds}
                matches={matches}
                stages={stages}
                groups={groups}
                match_game={matchGame}
                editMode={editMode}
                isAdmin={isAdmin}
                playerTeamId={`${playerTeamId}`}
                
            />
    } else if (bracketType === 'round_robin') {
        renderBracketByType =                 
            <RoundRobin
                participants={participants}
                rounds={rounds}
                matches={matches}
                groups={groups}
                stages={stages}
                match_game={matchGame}
                editMode={editMode}
                isAdmin={isAdmin}
                playerTeamId={`${playerTeamId}`}
                
            />
    } else if (bracketType === 'round_robin_single_elimination') {
        // Пропсы для single_elimination
        let singleMatches = stages[1].type === 'single_elimination' ? matches.filter(singleMatch => singleMatch.stage_id === 1) : matches.filter(singleMatch => singleMatch.stage_id === 0);
        let singleRounds = stages[1].type === 'single_elimination' ? rounds.filter(singleRound => singleRound.stage_id === 1) : rounds.filter(singleRound => singleRound.stage_id === 0);
        let singleStage = stages.filter(singleStage => singleStage.type === 'single_elimination');
        
        // Пропсы для round_robin
        let robinMatches = stages[0].type === 'round_robin' ? matches.filter(robinMatch => robinMatch.stage_id === 0) : matches.filter(robinMatch => robinMatch.stage_id === 1);
        let robinRounds = stages[0].type === 'round_robin' ? rounds.filter(robinRound => robinRound.stage_id === 0) : rounds.filter(robinRound => robinRound.stage_id === 1);
        let robinGroups = stages[0].type === 'round_robin' ? groups.filter(robinGroup => robinGroup.stage_id === 0) : groups.filter(robinGroup => robinGroup.stage_id === 1);
        let robinStage = stages.filter(singleStage => singleStage.type === 'round_robin');
        
        renderBracketByType =  
            <>
                <RoundRobin
                    participants={participants}
                    rounds={robinRounds}
                    matches={robinMatches}
                    groups={robinGroups}
                    stages={robinStage}
                    match_game={matchGame}
                    editMode={editMode}
                    isAdmin={isAdmin}
                    playerTeamId={`${playerTeamId}`}                  
                />
                <SingleElimination
                    participants={participants}
                    rounds={singleRounds}
                    matches={singleMatches}
                    stages={singleStage}
                    groups={groups}
                    match_game={matchGame}
                    editMode={editMode}
                    isAdmin={isAdmin}
                    playerTeamId={`${playerTeamId}`}                  
                />
            </>   
    } else if (bracketType === 'round_robin_double_elimination') {
        // Пропсы для single_elimination
        let doubleMatches = stages[1].type === 'double_elimination' ? matches.filter(singleMatch => singleMatch.stage_id === 1) : matches.filter(singleMatch => singleMatch.stage_id === 0);
        let doubleRounds = stages[1].type === 'double_elimination' ? rounds.filter(singleRound => singleRound.stage_id === 1) : rounds.filter(singleRound => singleRound.stage_id === 0);
        let doubleStage = stages.filter(singleStage => singleStage.type === 'double_elimination');
        
        // Пропсы для round_robin
        let robinMatches = stages[0].type === 'round_robin' ? matches.filter(robinMatch => robinMatch.stage_id === 0) : matches.filter(robinMatch => robinMatch.stage_id === 1);
        let robinRounds = stages[0].type === 'round_robin' ? rounds.filter(robinRound => robinRound.stage_id === 0) : rounds.filter(robinRound => robinRound.stage_id === 1);
        let robinGroups = stages[0].type === 'round_robin' ? groups.filter(robinGroup => robinGroup.stage_id === 0) : groups.filter(robinGroup => robinGroup.stage_id === 1);
        let robinStage = stages.filter(singleStage => singleStage.type === 'round_robin');
        
        renderBracketByType =  
            <>
                <RoundRobin
                    participants={participants}
                    rounds={robinRounds}
                    matches={robinMatches}
                    groups={robinGroups}
                    stages={robinStage}
                    match_game={matchGame}
                    editMode={editMode}
                    isAdmin={isAdmin}
                    playerTeamId={`${playerTeamId}`}                  
                />
                <DoubleElimination
                    participants={participants}
                    rounds={doubleRounds}
                    matches={doubleMatches}
                    stages={doubleStage}
                    groups={groups}
                    match_game={matchGame}
                    editMode={editMode}
                    isAdmin={isAdmin}
                    playerTeamId={`${playerTeamId}`}                  
                />
            </>   
    } else {
        renderBracketByType = <h1>{bracketType} в разоаботке...</h1>
    }
    
    return (
        <div className="breacket_mainContainer" style={{ overflowY: editMode ? 'auto' : 'hidden' }}>
            {renderBracketByType}
        </div>
    );
}

export default Bracket;