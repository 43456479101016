import React from 'react';
import './WarningPage.css';

const WarningPage: React.FC = () => {
    return (
        <div className='warning_window_container'>
            <div className='warning_window'>
                Откройте полноразмерную версию
            </div>
        </div>
	)
};

export default WarningPage;