import { makeAutoObservable } from 'mobx';
import { ITeamStoreModel } from '../models/dto/team/ITeamStoreModel';
import { ICommonPlayerModel } from '../models/dto/player/ICommonPlayerModel';

class PlayerTeamStore {
    teamId: string | null = null;
    statusId: string | null = null;
    gameTypeId: string | null = null;
    captainId: string | null | undefined = null;
    ownerId: string | null | undefined = null; 
    playerCreatorId: string | null | undefined = null; 
    partnerCreatorId: string | null | undefined = null;

    name: string = '';
    tag: string = '';
    description: string = '';
    city: string | null = null;
    score: number = 0;
    logoUrl: string | null | undefined = null;
    website: string | null | undefined = null;
    contactEmail: string | null | undefined = null;
    socialMediaLinks: string | null | undefined = null;

    createdBy: number | null = null;

    creationDate: string = '';

    responsePlayers: ICommonPlayerModel[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

    update(teamData: ITeamStoreModel): void {
        this.teamId = teamData.teamId;
        this.statusId = teamData.statusId;
        this.gameTypeId = teamData.gameTypeId;
        this.captainId = teamData.captainId;
        this.ownerId = teamData.ownerId;
        this.playerCreatorId = teamData.playerCreatorId;
        this.partnerCreatorId = teamData.partnerCreatorId;
        
        this.name = teamData.name; 
        this.tag = teamData.tag; 
        this.description = teamData.description;
        this.city = teamData.city;
        this.score = teamData.score;
        this.logoUrl = teamData.logoUrl;
        this.website = teamData.website;
        this.contactEmail = teamData.contactEmail;
        this.socialMediaLinks = teamData.socialMediaLinks;

        this.createdBy = teamData.createdBy;

        this.creationDate = teamData.creationDate;

        this.responsePlayers = teamData.players;
    }

    clear(): void {
      this.teamId = null;
      this.statusId = null;
      this.gameTypeId = null;
      this.captainId = null;
      this.ownerId = null;
      this.playerCreatorId = null;
      this.partnerCreatorId = null;

        this.name = '';
        this.tag = '';
        this.description = '';
        this.city = null;
        this.score = 0;
        this.logoUrl = null;
        this.website = null;
        this.contactEmail = '';
        this.socialMediaLinks = null;

        this.createdBy = null;

        this.creationDate = '';

        this.responsePlayers = null;
    }
}

export default PlayerTeamStore;