import React from 'react';
import './Footer.css';
import tower_logo from '../../assets/img/tower_logo.svg';
import { Link } from 'react-router-dom';
import { Globe, Telegram } from 'react-bootstrap-icons';

const Footer: React.FC = () => {
  return (
    <footer className="text-center text-lg-start">
      <div className='footer_logo_block'>
        <img src={tower_logo} alt="towerLogo" className="footer_logo" />
        <p>ООО "ГРИН СПЭЙС"</p>
      </div>
      
      <div className='footer_links_main_block'>
        <div className='footer_links_block2'>
          <Link to="http://greenspacedev.ru/" target="_blank" rel="noopener noreferrer">
            О нас
          </Link>
          <Link to="#link">
            Контакты 
          </Link>
          <Link to="#link">
            Служба поддержки
          </Link>
        </div> 
        
        <div className='footer_links_block3'>
          <Link to="#link">
            Информация о компании
          </Link>
          <Link to="#link">
            Пользовательское соглашение
          </Link>
          <Link to="#link">
            Политика конфиденциальности
          </Link>
        </div>
        
        <div className='footer_links_socials_block'>
          <div>Социальные сети</div>
          <div className='footer_links_socials'>
          <Link to="http://greenspacedev.ru/" target="_blank" rel="noopener noreferrer">
            <Globe size={30} />
          </Link>
          <Link to="#link">
            <Telegram size={30} />
          </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
