import React, { useEffect, useState } from 'react';
import './PartnerPageTournamentsTabCurrent.css';
import { ICommonTournamentModel } from '../../../../models/dto/tournament/ICommonTournamentModel';
import MyCarousel from '../../../../components/MyCarousel/MyCarousel';
import { getTournamentsByFilters } from '../../../../http/tournament/tournamentActionsAPI';
import CustomSpinner1 from '../../../../components/CustomSpinners/CustomSpinner1';
import { TournamentSF } from '../../../../viewModels/filters/TournamentSF';


const PartnerPageTournamentsTabCurrent: React.FC = () => {
    const [currentGameTypeId, setCurrentGameTypeId] = useState<string>('f9c18834-95f0-49ee-9de9-6116bcd08161');
    const [tournamentsList, setTournamentsList] = useState<ICommonTournamentModel[]>([]);
    const [fetchingTournamentsList, setFetchingTournamentsList] = useState(true);
    const [gameTypeIdsSelected, setGameTypeIdsSelected] = useState<string[]>([]);
    const [filters, setFilters] = useState <TournamentSF>({
        gameTypeIds: gameTypeIdsSelected,
        page: 1,
        limit: 6
    });
    
    useEffect(() => {
        setFetchingTournamentsList(true);
        getTournamentsByFilters(filters)
        .then(data => {
        if (data) {
            setTournamentsList(data.tournaments || []);
        }
        })
        .catch(error => {
        console.error('Error fetching tournaments list:', error);
        })
        .finally(() => setFetchingTournamentsList(false));
    }, [currentGameTypeId]);
    
    return (
        <>
            {
                fetchingTournamentsList ? (
                    <CustomSpinner1 />
                ) : (
                    tournamentsList.length > 0 ? (
                        <MyCarousel tournaments={tournamentsList} />
                    ) : (
                        <p className='tournaments_games_container_empty'>НЕТ ДОСТУПНЫХ ТУРНИРОВ</p>
                    )
                )
            }
        </>
    );
};

export default PartnerPageTournamentsTabCurrent;