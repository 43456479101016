import React, { useEffect, useState } from 'react';
import './ChosenGame.css';
import { Container, Pagination } from 'react-bootstrap';
import TournamentCell from '../../components/TournamentCell/TournamentCell';
import { useParams } from 'react-router';
import { getTournamentsByFilters } from '../../http/tournament/tournamentActionsAPI';
import { ICommonTournamentModel } from '../../models/dto/tournament/ICommonTournamentModel';
import { Steam } from 'react-bootstrap-icons';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';
import { TournamentSF } from '../../viewModels/filters/TournamentSF';

const ChosenGame: React.FC = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [activeTabFirst, setActiveTabFirst] = useState<number>(0);
    const { gameTypeId } = useParams()
    const [fetchingTournamentsList, setFetchingTournamentsList] = useState(true);
    const [tournamentsList, setTournamentsList] = useState<ICommonTournamentModel[]>([])
    const today = new Date();
    const activeTournaments = tournamentsList.filter(tournament => {
        const endDate = tournament.endDate ? new Date(tournament.endDate) : null;
        return endDate && endDate > today;
    });
    const pastTournaments = tournamentsList.filter(tournament => {
        const endDate = tournament.endDate ? new Date(tournament.endDate) : null;
        return endDate && endDate <= today;
    });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [gameTypeIdsSelected, setGameTypeIdsSelected] = useState<string[]>([TournamentConstants.CS2.gameTypeId]);
    const [filters, setFilters] = useState<TournamentSF>({
        gameTypeIds: gameTypeIdsSelected,
        page: 1,
        limit: 12
    });

    useEffect(() => {
        if (gameTypeId) {
            setFetchingTournamentsList(true);
            const updatedFilters = { ...filters, page: currentPage, gameTypeIds: gameTypeIdsSelected };

            getTournamentsByFilters(updatedFilters)
                .then(response => {
                    const data = response.tournaments;
                    const totalCount = response.totalCount;
                    if (data) {
                        setTournamentsList(data);
                        const calculatedTotalPages = Math.ceil(totalCount / filters.limit);
                        setTotalPages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
                    }
                })
                .finally(() => setFetchingTournamentsList(false));
        }
    }, [gameTypeIdsSelected, currentPage])

    const getGameConstsByTypeId = (gameTypeId: string | undefined) => {
        if (typeof gameTypeId !== 'string') {
            return null;
        }
        const games: any = TournamentConstants;

        for (const key in games) {
            if (games[key].gameTypeId === gameTypeId) {
                return games[key];
            }
        }
        return null;
    }

    const gameInfo = getGameConstsByTypeId(gameTypeId);

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    const renderPaginationItems = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                >
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    };
    return (
        <Container>
            <div className="chosen_game_container">
                <div className='chosen_game_first_container'>
                    <div className='headercard_container'>
                        <div className='chosen_game_info'>
                            <div className='chosen_game_short_info_pic_contaner'>
                                <img className='chosen_game_short_info_pic' src={gameInfo.gameImage} alt="chosen_game" />
                            </div>
                            <div className='chosen_game_short_info'>
                                <div>
                                    <div className='chosen_game_name'>
                                        {gameInfo.gameName}
                                    </div>
                                    <div className='chosen_game_genre'>
                                        {gameInfo.gameGenre}
                                    </div>
                                    <div className='chosen_game_socials'>
                                        <div className='chosen_game_socials_container'>
                                            <a href={gameInfo.gameSteamLink} target="_blank" rel="noopener noreferrer">
                                                <Steam size={30} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='chosen_game_socials_button_container'>
                                    <button className={activeTabFirst === 0 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                                        onClick={() => setActiveTabFirst(0)}>
                                        ТУРНИРЫ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='chosen_game_short_stats'>
                            <div className='chosen_game_line'></div>
                            <div className='chosen_game_statistics'>
                                <div className='chosen_game_stat_number'>-</div>
                                <div className='chosen_game_stat_letters'>СЫГРАНО МАТЧЕЙ</div>
                            </div>
                            <div className='chosen_game_line'></div>
                            <div className='chosen_game_statistics'>
                                <div className='chosen_game_stat_number'>-</div>
                                <div className='chosen_game_stat_letters'>МАКСИМУМ ОНЛАЙН</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chosen_game_second_container'>
                    <div className='chosen_game_slogan1'>
                        ОТКРОЙ ДЛЯ СЕБЯ МИР КИБЕРСПОРТА
                    </div>
                    <div className='chosen_game_slogan2'>
                        ВЫБЕРИ ТУРНИР ПО ДУШЕ
                    </div>
                </div>
                <div className='chosen_game_socials_button_container lists'>
                    <button
                        className={activeTab === 0 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                        onClick={() => setActiveTab(0)}
                    >
                        ТЕКУЩИЕ
                    </button>
                    <button
                        className={activeTab === 1 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                        onClick={() => setActiveTab(1)}
                    >
                        ПРОШЕДШИЕ
                    </button>
                </div>
                {activeTab === 0 && (
                    <div>
                        <div className="chosen_game_third_container">
                            {fetchingTournamentsList ? <CustomSpinner1 /> : (
                                <>
                                    {activeTournaments.length === 0 ? (
                                        <div>Сейчас нет активных турниров</div>
                                    ) : (
                                        activeTournaments.map(tournament => (
                                            <TournamentCell key={tournament.tournamentId} tournament={tournament} />
                                        ))
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
                {activeTab === 1 && (
                    <div className="chosen_game_third_maincontainer">
                        <div className="chosen_game_third_container">
                            {fetchingTournamentsList ? <CustomSpinner1 /> : (
                                <>
                                    {pastTournaments.map(tournament => (
                                        <TournamentCell key={tournament.tournamentId} tournament={tournament} />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                )}
                {tournamentsList.length > 0 && (
                    <div className="pagination_container">
                        <Pagination>
                            {renderPaginationItems()}
                        </Pagination>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default ChosenGame;