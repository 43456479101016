import React, { createContext, ReactNode, useMemo } from 'react';
import PlayerStore from '../store/PlayerStore';
import PlayerTeamStore from '../store/PlayerTeamStore';
import PartnerStore from '../store/PartnerStore';

interface IAppContext {
  player?: PlayerStore;
  playerTeam?: PlayerTeamStore;
  partner?: PartnerStore;
}

interface AppContextProviderProps {
  children: ReactNode;
}

const AppContext = createContext<IAppContext>({
  player: new PlayerStore(),
  playerTeam: new PlayerTeamStore(),
  partner: new PartnerStore(),
});

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const value = useMemo(() => ({
    player: new PlayerStore(),
    playerTeam: new PlayerTeamStore(),
    partner: new PartnerStore(),
  }), []);
  
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };