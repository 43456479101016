import React from 'react';
import './TeamPlayersTab.css';
import { Discord, Steam, Telegram } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import starCaptainImg from '../../../../assets/img/LobbyIndicators/starСaptain.svg';
import { ICommonPlayerModel } from '../../../../models/dto/player/ICommonPlayerModel';


interface TeamPlayersProps {
    players: ICommonPlayerModel[];
    captainId: string | null;
}


const TeamPlayersTab: React.FC<TeamPlayersProps> = ({ players, captainId }) => {
    const navigate = useNavigate(); 
    
    let sortPlayers = players.sort((a, b) => {
        if (a.playerId === captainId && b.playerId !== captainId) {
            return -1;
        }
        
        if (a.playerId !== captainId && b.playerId === captainId) {
            return 1;
        }
        
        return Number(a.playerId) - Number(b.playerId);
    });
    
    return (
        <>
            {
                sortPlayers.map((player, index) => {
                    let steamProfileUri: string = `${process.env.REACT_APP_STEAM_PROFILE_URL}${player?.steamID}`;
                    
                    return (
                        <div className='teamPlayers_player_mainContainer' key={player.playerId}>
                            <div key={index} className='teamPlayers_player_container'>
                                <div className='teamPlayers_player_logo_container'>
                                    <div className='teamPlayers_player_logo' style={{backgroundImage: `url(${process.env.REACT_APP_IMG_MINIO_URL}${player.avatarURL})`}}></div>
                                </div>
                                <div className='teamPlayers_players_container'>
                                    <div className='teamPlayers_player_link'>
                                        <h3 className='teamPlayers_player_titles' onClick={() => { navigate(`/player/${player.playerId}`)}}>{player.login}</h3>
                                        {player.playerId === captainId && <img alt='captain' className='teamCaptainImg' src={starCaptainImg} />}
                                    </div>
                                    <div className='teamPlayers_player_socials'>
                                        <div className='teamPlayers_player_socials_container'>
                                            <a className='teamPlayers_player_socialsLink' href={steamProfileUri}>
                                                <Steam size={25} />
                                            </a>
                                        </div>
                                        <div className='teamPlayers_player_socials_container'>
                                            <a>
                                                <Discord size={25} />
                                            </a>
                                        </div>
                                        <div className='teamPlayers_player_socials_container'>
                                            <a>
                                                <Telegram size={25} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='teamPlayers_player_stats_constainer'>
                                    { /* TODO: ПЕРЕДУМАТЬ, ПОСЛЕДНЯЯ ИГРА ИГРОКА НЕ НУЖНА ПО КАЖДОМУ ИГРОКУ В КОМАНДЕ */}
                                <h3 className='teamPlayer_player_title'>ПОСЛЕДНЯЯ ИГРА:</h3> 
                                    <div className='teamPlayers_suppe_title'>
                                        <div className='teamPlayers_suppe_title_left'>
                                            <span className="teamPlayer_player_info_title">дата:</span>
                                        </div> <div className='teamPlayer_suppe_title_right'>
                                            <span className="team_header_info_value"> - </span>
                                        </div>
                                    </div>
                                    <div className='teamPlayers_suppe_title'>
                                        <div className='teamPlayers_suppe_title_left'>
                                            <span className="teamPlayer_player_info_title">формат:</span>
                                        </div>
                                        <div className='teamPlayer_suppe_title_right'><span className="team_header_info_value"> - </span>
                                        </div>
                                    </div>
                                    <div className='teamPlayers_suppe_title'>
                                        <div className='teamPlayers_suppe_title_left'>
                                            <span className="teamPlayer_player_info_title">результат:</span>
                                        </div>
                                        <div className='teamPlayer_suppe_title_right'>
                                            <span className="team_header_info_value"> - </span>
                                            </div>
                                        </div>
                                    <div className='teamPlayers_suppe_title'>
                                        <div className='teamPlayers_suppe_title_left'>
                                            <span className="teamPlayer_player_info_title">счёт:</span>
                                        </div>
                                        <div className='teamPlayer_suppe_title_right'>
                                            <span className="team_header_info_value last_games"> - </span>
                                        </div>
                                    </div>
                                    <div className='teamPlayers_suppe_title'>
                                        <div className='teamPlayers_suppe_title_left'>
                                            <span className="teamPlayer_player_info_title">карта:</span>
                                            </div>
                                        <div className='teamPlayer_suppe_title_right'><span className="team_header_info_value last_games"> - </span></div>
                                    </div>
                                </div>
                                <div className='teamPlayer_player_lastTournaments'>
                                    <h3 className='teamPlayers_team_header_titles'>ДОСТИЖЕНИЯ</h3>
                                </div>
                            </div>
                            <hr className='teamPlayers_line'></hr>
                        </div>
                    )
                })
            }
        </>
    );
};

export default TeamPlayersTab;