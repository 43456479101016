import React, { useState } from 'react';
import './ResetPassword.css';
import { Alert, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../http/userAPI';

const ResetPassword: React.FC = () => {
    const { userId, code } = useParams();
    const navigate = useNavigate();
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    const handleResetPassword = (e: React.FormEvent) => {
        e.preventDefault();
        if (formData.password === formData.confirmPassword) {
            resetPassword(userId, code, formData.password, formData.confirmPassword).then(() => {
                setPasswordChanged(true);
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            })
        } else {
            setPasswordsMatch(false);
        }
    };
    return (
        <div className='reset_password_container'>
            <h3>
                СМЕНА ПАРОЛЯ
            </h3>
            <Form className='modal_form resetpassword'>
                {!passwordsMatch && (
                    <Alert variant="danger">
                        Пароли не совпадают!
                    </Alert>
                )}
                {passwordChanged && (
                    <Alert variant="success">
                        Пароль успешно изменён
                    </Alert>
                )}
                <Form.Group className='modal_form_group'>
                    <Form.Control
                        type='password'
                        placeholder='Новый пароль'
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        required
                    />
                    <Form.Control.Feedback type='invalid'>
                        Введите пароль
                    </Form.Control.Feedback>
                    <Form.Control
                        type='password'
                        placeholder='Подтвердите пароль '
                        value={formData.confirmPassword}
                        onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                        required
                    />
                    <Form.Control.Feedback type='invalid'>
                        Введите пароль повторно
                    </Form.Control.Feedback>
                </Form.Group>
                <div className='reset_button_container'>
                    <Button
                        className='modal_login_button'
                        variant="primary"
                        type="submit"
                        onClick={handleResetPassword}
                    >
                        Сбросить пароль
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default ResetPassword;