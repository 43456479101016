import { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Discord, PencilFill, Steam, Telegram } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import teamImgZ from '../../assets/img/teamZaglushka.svg';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import { getTeamInfo } from '../../http/playerActionsAPI';
import { ITeamPageModel } from '../../models/dto/team/ITeamPageModel';
import TeamAboutTab from './Tabs/TeamAboutTab/TeamAboutTab';
import TeamPlayersTab from './Tabs/TeamPlayersTab/TeamPlayersTab';
import './Team.css';
import StaffTab from './Tabs/StaffTab/StaffTab';
import { AppContext } from '../../components/AppContext';


const Team: React.FC = () => {
    const [teamInfo, setTeamInfo] = useState<ITeamPageModel>();
    const [fetchingteamInfo, setFetchingteamInfo] = useState(true);
    const [activeTab, setActiveTab] = useState('сommand_structure');
	const { player } = useContext(AppContext);
    const navigate = useNavigate()
    
    const renderContent = () => {
        switch (activeTab) {
            case 'сommand_structure':
            
                return (
                    <>
                        {teamInfo ? <TeamPlayersTab players={teamInfo.players} captainId={teamInfo.captainId || null} /> : <CustomSpinner1 />}
                    </>
                );
            
            case 'about_team':
                return (
                    <>
                        {teamInfo ? <TeamAboutTab team={teamInfo} /> : <CustomSpinner1 />}
                    </>
                );
            
            case 'staff':
                let isTeamStaff: boolean = [
                    teamInfo?.owner,
                    teamInfo?.coaches,
                    teamInfo?.managers,
                    teamInfo?.contentMakers
                ].some(array => Array.isArray(array) && array.length > 0);
                
                return (
                    <>
                        {teamInfo && isTeamStaff
                            ? <StaffTab team={teamInfo} />
                            : <div>В КОМАНДЕ ЕЩЕ НЕТ ПЕРСОНАЛА</div>
                        }
                    </>
                );
            
            case 'tournament_history':
                return (
                    /*<TeamTournamentHistoryTab playersArr={teamInfo?.responsePlayers || []} />*/
                    <div>КОМАНДА ЕЩЕ НЕ УЧАСТВОВАЛА В ТУРНИРАХ</div>
                );
            
            case 'match_history':
                return (
                    <div>НЕТ ДОСТУПНЫХ МАТЧЕЙ</div>
                    //<TeamMatchHistoryTab playersArr={teamInfo?.responsePlayers || []} />
                );
            
            default:
                return <CustomSpinner1 />;
        }
    };
    
    const params = useParams();
    const teamId: string | undefined = params.teamId;
    
    useEffect(() => {
        setFetchingteamInfo(true);
        getTeamInfo(teamId)
            .then(data => {
                if (data) {
                    setTeamInfo(data || []);
                }
            })
            .catch(error => {
                console.error('Error fetching teams:', error);
            })
            .finally(() => setFetchingteamInfo(false));
    }, [teamId]);
    
    return (
        <Container>
            <div className='teamPage_team_container'>
                <div className='teamPage_team_header_container'>
                    <div className='teamPage_team_header_logo_container'>
                        <div className='teamPage_team_header_logo' style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_MINIO_URL}${teamInfo?.logoUrl || teamImgZ})` }}>
                        </div>
                    </div>
                    <div className='teamPage_team_header_info'>
                        <div className='teamPage_team_header_info_titles'>
                            <div className='teamPage_team_name'>
                                <div className='teamPage_team_nameTitle'>
                                    {teamInfo?.name}
                                    <span className='team_editIcon' onClick={() => navigate(`/update-team/${teamId}`)}>
                                        {teamInfo?.playerCreatorId === player?.playerId &&  (
                                            <>
                                                <PencilFill size={15} />
                                            </>
                                        )}
                                    </span>
                                </div>
                                <span>{teamInfo?.tag}</span>
                            </div>
                            <h3>статистика:</h3>
                            <h3>последние турниры:</h3>
                        </div>
                        <div className='teamPage_team_header_full_info'>
                            <div className='teamPage_team_header_full_info_aboutTeam'>
                                <div className='teamPage_team_header_full_info_socials'>
                                    <div className='teamPage_team_header_full_info_socials_container'>
                                        <Steam size={25} className='teamPage_steam_icon' />
                                    </div>
                                    <div className='teamPlayers_player_socials_container'>
                                        <Discord size={25} className='teamPage_discord_icon' />
                                    </div>
                                    <div className='teamPlayers_player_socials_container'>
                                        <Telegram size={25} className='teamPage_telegram_icon' />
                                    </div>
                                </div>
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>город:</span><br /><span>{teamInfo?.city}</span>
                                </div>
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>игра:</span><br /><span>{
                                        (TournamentConstants.CS2.gameTypeId === teamInfo?.gameTypeId
                                            ? TournamentConstants.CS2.gameName
                                            : '-')
                                    }</span>
                                </div>
                                {/*<div className='teamPage_team_header_full_info_aboutTeam_item'>*/}
                                {/*    <span>статус:</span><br /><span>{teamInfo?.name}</span>*/}
                                {/*</div>*/}
                            </div>
                            <div className='teamPage_team_header_full_info_stats'>
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>количество игр:</span><br /><span> - </span>
                                </div>
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>процент побед:</span><br /><span> - </span>
                                </div>
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>серия побед:</span><br /><span> - </span>
                                </div>
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>последние игры:</span><br /><span> - </span>
                                </div>
                            </div>
                            <div className='teamPage_team_header_full_info_lastTournaments'>
                                {/* !!! Последние 4 турира максимум */}
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>PGL</span><br /><span>1 место</span>
                                </div>
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>BLAST</span><br /><span>1 место</span>
                                </div>
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>ESL</span><br /><span>2 место</span>
                                </div>
                                <div className='teamPage_team_header_full_info_aboutTeam_item'>
                                    <span>ESL</span><br /><span>2 место</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='teamPage_tabs_container'>
                    <button className={activeTab === 'сommand_structure' ? 'teamPage_tab teamPage_active_tab' : 'teamPage_tab'} onClick={() => setActiveTab('сommand_structure')}>СОСТАВ</button>
                    <button className={activeTab === 'staff' ? 'teamPage_tab teamPage_active_tab' : 'teamPage_tab'} onClick={() => setActiveTab('staff')}>Персонал</button>
                    <button className={activeTab === 'about_team' ? 'teamPage_tab teamPage_active_tab' : 'teamPage_tab'} onClick={() => setActiveTab('about_team')}>О команде</button>
                    <button className={activeTab === 'tournament_history' ? 'teamPage_tab teamPage_active_tab' : 'teamPage_tab'} onClick={() => setActiveTab('tournament_history')}>История турниров</button>
                    <button className={activeTab === 'match_history' ? 'teamPage_tab teamPage_active_tab' : 'teamPage_tab'} onClick={() => setActiveTab('match_history')}>История матчей</button>
                </div>
                {renderContent()}
            </div>
        </Container >
    );
};

export default Team;