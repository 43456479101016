import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route} from 'react-router-dom';
import SideBar from './SideBar/SideBar';
import PlayerManage from './Tabs/Users/PlayerManage';
import TeamManage from './Tabs/Teams/TeamManage';
import TournamentManage from './Tabs/Tournaments/Manage/TournamentManage';
import Cs2Manage from './Tabs/Games/CS2/Cs2Manage';
import './AdminPanel.css';

const AdminPanel: React.FC = () => {
    return (
        <Container fluid className='admin_container'>
            <Row>
                <Col xs={2} className="sidebar-wrapper">
                    <SideBar />
                </Col>
                <Col xs={10} className="page-content-wrapper">
                    <Routes>
                        <Route path="all-players" element={<PlayerManage />} />
                        <Route path="teams" element={<TeamManage />} />
                        <Route path="/" element={<TournamentManage />} />
                        <Route path="cs2" element={<Cs2Manage />} />
                    </Routes>
                </Col>
            </Row>
        </Container>
    );
};

export default AdminPanel;