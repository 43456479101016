import React from 'react';
import './GamesList.css';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';

const GamesList: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <div className="choose_game_container">
                <div className="choose_game_list">
                    {
                        TournamentConstants.Games.map((game, index) => (
                            <div className={`choose_game_card ${!game.gameTypeId ? 'empty' : ''}`} key={index}>
                                <img 
                                    className="choose_game_card_img"
                                    src={game.gameBaner} 
                                    alt="game_pic"
                                    onClick={() => 
                                        game.gameTypeId 
                                        ? navigate(`/game/${game.gameTypeId}`) 
                                        : navigate(`/soon`)
                                    }
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </Container>
    );
};

export default GamesList;