import React, { useState } from 'react';
import './GamesItem.css';
import { IGamesModel } from '../../models/IGamesModel';
import { useNavigate } from 'react-router';

interface GamesItemProps extends IGamesModel {
    gameTypeId: string;
}

const GamesItem: React.FC<GamesItemProps> = ({ title, imgSrc, gameTypeId }) => {
    const [mouseStart, setMouseStart] = useState<{x: number, y: number} | null>(null);
    const navigate = useNavigate();
    
    const handleMouseDown = (event: React.MouseEvent) => {
        setMouseStart({ x: event.clientX, y: event.clientY });
    };
    
    const handleMouseUp = (event: React.MouseEvent) => {
        if (mouseStart) {
            const dx = event.clientX - mouseStart.x;
            const dy = event.clientY - mouseStart.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            
            if (distance < 10) {
                navigate(`/game/${gameTypeId}`);
            }
        }
    };
    
    return (
        <div 
            className='item'
            style={{ backgroundImage: `url(${imgSrc})` }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            <div className="game_title">{title}</div>
        </div>
    );
};


export default GamesItem;