import React, { useContext, useEffect, useState } from 'react';
import './PartnerPage.css';
import { Container } from 'react-bootstrap';
import News from '../../components/News/News';
import { INewsModel } from '../../models/INewsModel';
import newsPhoto1 from '../../components/News/NewsPhotos/NewsPhoto1.jpg';
import newsPhoto2 from '../../components/News/NewsPhotos/NewsPhoto2.jpg';
import PartnerPageTournamentsTabCurrent from './PartnerPageTournamentsTabs/PartnerPageTournamentsTabCurrent/PartnerPageTournamentsTabCurrent';
import PartnerPageTournamentsTabUpcoming from './PartnerPageTournamentsTabs/PartnerPageTournamentsTabUpcoming/PartnerPageTournamentsTabUpcoming';
import PartnerPageTournamentsTabPast from './PartnerPageTournamentsTabs/PartnerPageTournamentsTabPast/PartnerPageTournamentsTabPast';
import { IPartnerPageModel } from '../../models/dto/partner/IPartnerPageModel';
import { useParams } from 'react-router-dom';
import { getPartnerInfo } from '../../http/userAPI';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';

const newsArr: INewsModel[] = [
    {
        title: 'Перввый турнир на NEVA',
        description: `
            Мы рады объявить о запуске первого тестового киберспортивного турнира на нашей киберспортивной платформе NEVA. Это событие станет важной вехой в развитии нашего проекта, открывая новые горизонты для развития киберспорта и создания уникального сообщества.
            
            Турнир пройдет с участием студентов одного из ведущих университетов страны - Государственного Университета Технологий и Инноваций Дизайна (ГУТИД). Их участие не только подчеркивает активное вовлечение молодежи в киберспортивную культуру, но и демонстрирует возможности нашей платформы в организации и проведении масштабных мероприятий.
            
            На турнире студенты ГУТИД смогут помериться силами в CS 2, демонстрируя свои навыки и стратегическое мышление. Это не только отличная возможность для участников проявить себя в конкурентной среде, но и ценный опыт, который пригодится в будущем.
            
            Мы верим, что этот турнир по CS 2 станет началом долгосрочного партнерства с учебными заведениями и организациями, стремящимися развивать киберспорт и цифровую культуру. Наша платформа создана для того, чтобы предоставлять уникальные возможности для проведения киберспортивных мероприятий любого уровня сложности, обеспечивая высокое качество организации и взаимодействия участников.
            
            Приглашаем всех заинтересованных следить за ходом турнира и поддерживать студентов ГУТИД в их стремлении к победе. Участие в турнире по CS 2 - это не просто соревнование, это шаг к формированию новой эры в киберспорте, где каждый может найти свое место и стать частью большого и вдохновляющего сообщества.
            
            Присоединяйтесь к нам в этом захватывающем путешествии и станьте свидетелями рождения новой киберспортивной платформы!
        `,
        imgSrc: newsPhoto1
    },
    {
        title: 'Итоги первого турнира',
        description: `
            Мы с восторгом делимся новостями о завершении первого тестового турнира на нашей платформе NEVA. Это мероприятие позволило продемонстрировать участникам текущий функционал и  выявить новые направления для развития  платформы "NEVA".  
            
            Турнир собрал талантливых студентов из Государственного Университета Технологий и Инноваций Дизайна (ГУТИД), которые продемонстрировали выдающиеся навыки и стратегическое мышление в игре CS 2. Их победа не только подчеркнула значимость молодежного участия в киберспорте, но и показала, насколько эффективной может быть наша платформа для организации подобных событий.
            
            Этот турнир не только дал возможность студентам ГУТИД проявить себя в конкурентной борьбе, но и обеспечил им ценный опыт, который окажется полезным в их будущем. Мы убеждены, что успешное проведение этого турнира заложит основу для долгосрочного сотрудничества с учебными заведениями и организациями, заинтересованными в развитии киберспорта и цифровой культуры.
        `,
        imgSrc: newsPhoto2
    },
    {
        title: 'Разработчики платформы',
        description: `
            Наша киберспортивная платформа, находящаяся на стадии разработки, обещает стать значимым новшеством в мире киберспорта. За её создание отвечает команда профессионалов из студии разработки GreenSpaceDev. Mы хотели бы представить вам этих талантливых людей, чья работа скоро позволит нам предложить вам уникальный продукт.
            
            Команда GreenSpaceDev:
            
            •Артём Шершнёв, CEO
            •Олег Кучеренко, Технический директор
            •Роман Ефременко, Frontend-разработчик
            •Юрий Зидерер, Project Manager
            •Евгения Ильина, Ведущий Разработчик
            •Владислав Дзирко, Backend-разработчик
            •Олег Соколов, Дизайнер
            •Богдан Рудневский, DevOps
            •Павел Лукин, DevOps
            
            Каждый член команды вносит свой важный вклад в разработку платформы, и мы благодарны им за их профессионализм и труд.
        `,
        imgSrc: newsPhoto2
    },
]


const PartnerPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState('ТЕКУЩИЕ');
    const [partnerInfo, setPartnerInfo] = useState<IPartnerPageModel>();
    const [fetchingPartner, setFetchingPartner] = useState(true);
    const { partnerId } = useParams();

    useEffect(() => {
        if (partnerId) {
            setFetchingPartner(true);
            getPartnerInfo(partnerId)
                .then(data => {
                    if (data) {
                        setPartnerInfo(data);
                    }
                }).finally(() => setFetchingPartner(false));
        }
    }, []);

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    const partnerPageRenderTabContent = () => {
        switch (activeTab) {
            case 'ТЕКУЩИЕ':
                return <PartnerPageTournamentsTabCurrent />;
            case 'ПРЕДСТОЯЩИЕ':
                return <PartnerPageTournamentsTabUpcoming />;
            case 'ПРОШЕДШИЕ':
                return <PartnerPageTournamentsTabPast />;
            default:
                return <></>;
        }
    };

    return (
        <Container>
            {fetchingPartner ? (
                <CustomSpinner1 />
            ) : (
                <div className='partnerPage_container'>
                    <header className='partnerPage_header'>
                        <div className='partnerPage_headerLogo_container'>
                            <div className='partnerPage_headerLogo'>
                                <img className="partnerPage_img" src={`${process.env.REACT_APP_IMG_MINIO_URL}${partnerInfo?.logoUrl}`} alt='' />
                            </div>
                        </div>

                        <div className='partnerPage_headerInfo_container'>
                            <div className='partnerPage_headerInfo_desc'>
                                <h3>{partnerInfo?.name}</h3>
                                <div className='partnerPage_miniinfo_container'>
                                    <div className='partnerPage_miniinfo_unit'>
                                        <div className='partnerPage_miniheader'>ДАТА РЕГИСТРАЦИИ</div>
                                        <div>{partnerInfo?.creationDate}</div>
                                    </div>
                                    <div className='partnerPage_miniinfo_unit'>
                                        <div className='partnerPage_miniheader'>ПОЧТА</div>
                                        <div>{partnerInfo?.contactMail}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='partnerPage_description'>
                                <div className='partnerPage_miniheader'>ОПИСАНИЕ</div>
                                {partnerInfo?.description}
                            </div>
                        </div>
                    </header>

                    <main className='partnerPage_main'>
                        <div className='partnerPage_main_news_container'>
                            <News newsArr={newsArr} />
                        </div>

                        <div className='partnerPage_TournamentsCarousel_container'>
                            <div className='partnerPage_TournamentsCarousel_header'>
                                <h3>ТУРНИРЫ</h3>
                                <div className='partnerPage_TournamentsCarousel_tabs'>
                                    <button className={`partnerPage_TournamentsCarousel_tab ${activeTab === 'ТЕКУЩИЕ' ? 'active' : ''}`} onClick={() => handleTabClick('ТЕКУЩИЕ')}>ТЕКУЩИЕ</button>
                                    <button className={`partnerPage_TournamentsCarousel_tab ${activeTab === 'ПРЕДСТОЯЩИЕ' ? 'active' : ''}`} onClick={() => handleTabClick('ПРЕДСТОЯЩИЕ')}>ПРЕДСТОЯЩИЕ</button>
                                    <button className={`partnerPage_TournamentsCarousel_tab ${activeTab === 'ПРОШЕДШИЕ' ? 'active' : ''}`} onClick={() => handleTabClick('ПРОШЕДШИЕ')}>ПРОШЕДШИЕ</button>
                                </div>
                            </div>
                            <div className='partnerPage_tabs_content_container'>
                                <div className='partnerPage_tabs_content'>
                                    {/* TODO: Разбить табы на ТЕКУЩИЕ, ПРЕДСТОЯЩИЕ, ПРОШЕДШИЕ */}
                                    {partnerPageRenderTabContent()}
                                </div>
                            </div>
                        </div>

                        <div className='partnerPage_teams_container'>
                            <h3>КОМАНДЫ</h3>
                            <div className='partnerPage_teams'>
                                {
                                    <div className='chosen-tournament-teams-layer'>
                                        {/* TODO: Команды пртнера (компонент TournamentItem) */}
                                    </div>
                                }
                            </div>
                        </div>
                    </main>
                </div>
            )}

        </Container >
    );
};

export default PartnerPage;
