import React, { useContext, useState } from 'react';
import { Modal, Form, Alert } from 'react-bootstrap';
import './LogInModalWindow.css';
import { ILogInModalWindowProps } from './ILogInModalWindow';
import { IUserLoginViewModel } from '../../../viewModels/IUserLoginViewModel';
import { login } from '../../../http/userAPI';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { updateContext } from '../../../logic/CommonLogic';
import { IPlayerStoreModel } from '../../../models/dto/player/IPlayerStoreModel';
import { observer } from 'mobx-react-lite';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { IPartnerStoreModel } from '../../../models/dto/partner/IPartnerStoreModel';
import ButtonWithLoader from '../../Buttons/ButtonWithLoader/ButtonWithLoader';

const RegModalWindow: React.FC<ILogInModalWindowProps> = observer(({ showModal, closeModal }) => {
    const { player, partner, playerTeam } = useContext(AppContext);
    const navigate = useNavigate();
    const [formData, setFormData] = useState<IUserLoginViewModel>({
        email: '',
        password: ''
    });
    const [isError, setIsError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordMessage, setPasswordMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget as HTMLFormElement;

        const isFormValid = form.checkValidity();
        if (!isFormValid) {
            setShowAlert(true);
            return;
        }
        setValidated(true);
        setIsLoading(true);

        login(formData)
            .then((result) => {
                if (Array.isArray(result)) {
                    setPasswordMessage(result.join(', '));
                    setShowAlert(true);
                    setIsLoading(false);
                    return;
                }

                if (!result.token) {
                    setIsError(true);
                    setIsLoading(false);
                    setShowAlert(true);
                    setPasswordMessage('Ошибка аутентификации.');
                    return;
                }

                if ('playerId' in result && player) {
                    player.loginPlayer(result);
                    setIsLoading(false);
                    if (player.isAuth && player.playerId) {
                        closeModal();
                        updateContext<IPlayerStoreModel, IPartnerStoreModel>(player, playerTeam, undefined).then(() => {
                            if (player.isAdmin) {
                                navigate('/admin-panel');
                            } else {
                                navigate(`/player/${player.playerId}`);
                            }
                        });
                    }
                }

                if ('partnerId' in result && partner) {
                    partner.loginPartner(result);
                    setIsLoading(false);
                    if (partner.isAuth && partner.partnerId) {
                        closeModal();
                        updateContext<IPlayerStoreModel, IPartnerStoreModel>(undefined, undefined, partner).then(() => {
                            navigate(`/partner/${partner.partnerId}`);
                        });
                        return;
                    }
                }

                setIsError(true);
                setShowAlert(true);
                setPasswordMessage('Ошибка аутентификации.');
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                setShowAlert(true);
                const errorMessages = error?.response?.data?.errors;

                if (errorMessages) {
                    const messages = Object.values(errorMessages).flat().join(', ');
                    setPasswordMessage(messages);
                } else {
                    setPasswordMessage('Произошла ошибка при аутентификации.');
                }
            });
    };

    const handleResetPassword = async (event: React.FormEvent) => {
        setIsLoading(true);
        event.preventDefault();
        axios.post(process.env.REACT_APP_BACKEND_API_URL + `api/Auth/ForgotPassword?email=${formData.email}`)
            .then(response => {
                setIsLoading(false);
                setPasswordMessage('Проверьте электронную почту для смены пароля');
                setShowAlert(true);
            })
            .catch(error => {
                setIsLoading(false);
                setIsError(true);
                setPasswordMessage('Ошибка смены пароля');
                setShowAlert(true);
            });
    }

    return (
        <Modal className='modal_container' show={showModal} onHide={closeModal}>
            <Modal.Body className='modal_body'>
                <Modal.Title className='modal_title'>
                    {resetPassword ? 'Сброс пароля' : 'Вход'}
                </Modal.Title>
                <Form
                    noValidate
                    className='modal_form'
                    validated={validated}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleLogin(event);
                        }
                    }}
                    onSubmit={handleLogin}
                >
                    {showAlert && (
                        <Alert
                            className='resetPassword_alert'
                            variant={isError ? "danger" : "success"}
                            onClose={() => setShowAlert(false)} dismissible>
                            {passwordMessage}
                        </Alert>
                    )}
                    <Form.Group className='modal_form_group' controlId='formLogInNickname'>
                        <Form.Control
                            type='email'
                            placeholder='Электронная почта'
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Введите корректную электронную почту
                        </Form.Control.Feedback>
                    </Form.Group>

                    {!resetPassword && (
                        <Form.Group className='modal_form_group' controlId='formLogInPassword'>
                            <div className="login_pass_container">
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Пароль'
                                    value={formData.password}
                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                    required
                                />
                                <div className='login_pass_eyes' onClick={() => { setShowPassword(!showPassword) }}>
                                    {showPassword ? <EyeSlashFill /> : <EyeFill />}
                                </div>
                            </div>
                        </Form.Group>
                    )}
                    <div className='modal_form_group_forgotpassword_container'>
                        <span
                            className='modal_form_group_forgotpassword'
                            onClick={() => setResetPassword(!resetPassword)}
                        >
                            {resetPassword ? 'Вернуться к авторизации' : 'Забыли пароль?'}
                        </span>
                    </div>
                    {/*   
                    <Form.Group className='modal_form_group' controlId='formLogInCheckBox'>
                        <Form.Check type='checkbox' label='Оставаться в аккаунте' />
                    </Form.Group> */}
                </Form>
                <ButtonWithLoader
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={resetPassword ? handleResetPassword : handleLogin}
                >
                    {resetPassword ? 'Сбросить пароль' : 'ВОЙТИ'}
                </ButtonWithLoader>
            </Modal.Body>
        </Modal>
    );
});

export default RegModalWindow;