import React from 'react';
import '../TeamPlayersTab/TeamPlayersTab.css';
import { Discord, Steam, Telegram } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { ITeamPageModel } from '../../../../models/dto/team/ITeamPageModel';
import './StaffTab.css';


interface TeamPlayersProps {
    team: ITeamPageModel;
}


const StaffTab: React.FC<TeamPlayersProps> = ({ team }) => {
    const navigate = useNavigate();
    
    return (
        <div className='teamPlayers_staff_mainContainer'>
            {team.owner && ( // owner
                <div className='staffMemberCard'>
                    <div className='staffMemberCard_imgContainer'>
                        <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${team.owner.logoUrl}`} alt={team.owner.login} />
                    </div>
                    
                    <div className='staffMemberCard_desc'>
                        <div className='staffMemberCard_userName' onClick={() => navigate(`/player/${team.owner?.partnerId}`)}>
                            {team.owner.login}
                        </div>
                    </div>
                    
                    <div className='staffMemberCard_socilContainer'>
                        
                    </div>
                </div>
            )}
            
            {team.coaches.length > 0 && ( // coaches
                <div className='staffContainer'>
                    <div className='staffTitle'>{team.coaches.length === 1 ? 'Тренер' : 'Тренеры'}</div>
                    <div className='staffMembersContainer'>
                        {team.coaches.map(p => (
                            <div className='staffMemberCard'>
                                <div className='staffMemberCard_imgContainer'>
                                    <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${p.avatarURL}`} alt={p.login} />
                                </div>
                                
                                <div className='staffMemberCard_desc'>
                                    <div className='staffMemberCard_userName' onClick={() => navigate(`/player/${p.playerId}`)}>
                                        {p.login}
                                    </div>
                                </div>
                                
                                <div className='staffMemberCard_socilContainer'>
                                    
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            
            {team.managers.length > 0 && ( // managers
                <div className='staffContainer'>
                    <div className='staffTitle'>{team.managers.length === 1 ? 'Менеджер' : 'Менеджеры'}</div>
                    <div className='staffMembersContainer'>
                        {team.managers.map(p => (
                            <div className='staffMemberCard'>
                                <div className='staffMemberCard_imgContainer'>
                                    <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${p.avatarURL}`} alt={p.login} />
                                </div>
                                
                                <div className='staffMemberCard_desc'>
                                    <div className='staffMemberCard_userName' onClick={() => navigate(`/player/${p.playerId}`)}>
                                        {p.login}
                                    </div>
                                </div>
                                
                                <div className='staffMemberCard_socilContainer'>
                                    
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            
            {team.contentMakers.length > 0 && ( // contentMakers
                <div className='staffContainer'>
                    <div className='staffTitle'>{team.contentMakers.length === 1 ? 'Контент мэйкер' : 'Контент мэйкеры'}</div>
                    <div className='staffMembersContainer'>
                        {team.contentMakers.map(p => (
                            <div className='staffMemberCard'>
                                <div className='staffMemberCard_imgContainer'>
                                    <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${p.avatarURL}`} alt={p.login} />
                                </div>
                                
                                <div className='staffMemberCard_desc'>
                                    <div className='staffMemberCard_userName' onClick={() => navigate(`/player/${p.playerId}`)}>
                                        {p.login}
                                    </div>
                                </div>
                                
                                <div className='staffMemberCard_socilContainer'>
                                    
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default StaffTab;