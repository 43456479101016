import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppContext } from './components/AppContext';
import AppRouter from './components/AppRouter';
import CustomSpinner1 from './components/CustomSpinners/CustomSpinner1';
import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import ScrollToTop from './components/ScrollToTop';
import { updateContext } from './logic/CommonLogic';

const App: React.FC = observer(() => {
	const { player, playerTeam, partner } = useContext(AppContext);
	const [loading, setLoading] = useState<boolean>(true);
    
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				await updateContext(player, playerTeam, partner);
			} catch (error) {
				console.error("Ошибка при обновлении контекста:", error);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []);
    
	return (
		<BrowserRouter>
			<div className='app_inner_container'>
				<NavBar loading={loading}/>
				{loading ? (
					<CustomSpinner1 />
				) : (
					<>
						<div className='app_content_container'>
							<AppRouter />
						</div>
					</>
				)}
				<Footer />
				<ScrollToTop />
			</div>
		</BrowserRouter>
	)
});

export default App;