import React, { useContext } from 'react';
import './TeamItem.css';
import { useNavigate } from 'react-router-dom';
import { ITournamentTeam } from '../../models/dto/team/ITournamentTeam';
import { AppContext } from '../AppContext';

interface TeamItemProps {
    tournamentTeam: ITournamentTeam;
}

const TeamItem: React.FC<TeamItemProps> = ({ tournamentTeam }) => {
    const navigate = useNavigate();
    const { player } = useContext(AppContext);
    
    return (
        <>
            <div className='chosen_tournament_team'>
                <div className='chosen_tournament_team_layer1'>
                    <div className='chosen_tournament_team_layer1_part'>
                        <div className='chosen_tournament_team_pic_container'>
                            <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${tournamentTeam?.logoUrl}`} className="chosen_tournament_team_pic" alt="team_logo" />
                        </div>
                        <div className='chosen_tournament_team_infotext'>
                            <div className={`chosen_tournament_team_naming ${tournamentTeam.teamId === player?.teamId ? 'playerTeam' : ''}`} onClick={() => navigate(`/team/${tournamentTeam.teamId}`)}>
                                {tournamentTeam.name}
                            </div>
                            <div className='chosen_tournament_team_game'>
                                CS2
                            </div>
                        </div>
                    </div>
                    <div className='chosen_tournament_team_layer1_part'>
                        <div className='chosen_tournament_short_stats'>
                            <div className='chosen_tournament_team_line'></div>
                            <div className='chosen_tournament_statistics'>
                                <div className='chosen_tournament_stat_number'>-</div>
                                <div className='chosen_tournament_stat_letters'>ИГР СЫГРАНО</div>
                            </div>
                            <div className='chosen_tournament_team_line'></div>
                            <div className='chosen_tournament_statistics'>
                                <div className='chosen_tournament_stat_number'>-</div>
                                <div className='chosen_tournament_stat_letters'>ИГР ВЫИГРАНО</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chosen_tournament_team_layer2'>
                    {tournamentTeam.players?.map((player, index) => (
                        <div className='chosen_tournament_team_member' key={index}>
                        <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${player?.avatarURL}`} className="chosen_tournament_team_member_pic" alt="team_member" />
                        <div className='chosen_tournament_team_member_info'>
                                { //tournamentTeam.captainId === player.playerId
                                /*TODO: получить капитана команды*/}
                                <div className='chosen_tournament_team_member_name' onClick={() => navigate(`/player/${player.playerId}`)}> 
                                    {player.login}
                                </div>
                                {/*<div className='chosen_tournament_team_member_role'>*/}
                                {/*    {player.teamRole}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default TeamItem;
