import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import './NavBar.css';
import { Navbar } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import tower_logo from '../../assets/img/tower_logo.svg';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { observer } from 'mobx-react-lite';
import { BellFill, CollectionFill, List } from 'react-bootstrap-icons';
import RegModalWindow from '../Forms/RegModalWindow/RegModalWindow';
import LogInModalWindow from '../Forms/LogInModalWindow/LogInModalWindow';
import { logout } from '../../http/userAPI';
import MyAlert from '../MyAlert/MyAlert';
import { useModal } from '../../hooks/useModal';
import PlayerStore from '../../store/PlayerStore';
import NotificationCenter from '../NotificationCenter/NotificationCenter';
import LobbiesCenter from '../LobbiesCenter/LobbiesCenter';

const NavBar: React.FC<{ loading: boolean }> = observer(({ loading }) => {
  const { player, partner } = useContext(AppContext);
  const currentUser = player?.isAuth ? player : partner?.isAuth ? partner : null;
  const navigate = useNavigate();
  const { isVisible: showMyAlert, openModal: handleShowMyAlert, closeModal: handleCloseMyAlert } = useModal();
  const { isVisible: showRegModal, openModal: openRegModal, closeModal: closeRegModal } = useModal();
  const { isVisible: showLogInModal, openModal: openLogInModal, closeModal: closeLogInModal } = useModal();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [lobbiesVisible, setLobbiesVisible] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const bellRef = useRef<HTMLDivElement | null>(null);
  const notificationRef = useRef<HTMLDivElement | null>(null);


  const handleLogout = () => {
    logout();
    player?.logout();
    partner?.logout();
    navigate('/', { replace: true });
    handleCloseMyAlert();
  };

  const handleUnreadNotifications = useCallback((hasUnread: boolean) => {
    setHasUnreadNotifications(hasUnread);
}, []);

  const getBgNavBar = (): string => {
    return window.scrollY < 98 ? 'transparent' : '#252525';
  };
  const [bgNavBar, setBgNavBar] = useState<string>(getBgNavBar);

  useEffect(() => {
    const handleScroll = () => {
      const newBg = getBgNavBar();
      if (newBg !== bgNavBar) {
        setBgNavBar(newBg);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [bgNavBar]);

  const handleBellClick = () => {
    setNotificationsVisible(prevVisible => !prevVisible);
  };

  const handleLobbiesClick = () => {
    setLobbiesVisible(prevVisible => !prevVisible);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationsVisible &&
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node) &&
        bellRef.current &&
        !bellRef.current.contains(event.target as Node)
      ) {
        setNotificationsVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [notificationsVisible]);

  return (
    <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: bgNavBar }} className={`navbar_ ${bgNavBar}`} data-bs-theme="dark">
      <Navbar.Brand href="/">
        <img src={tower_logo} alt="towerLogo" className="navbar_logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="navbar_flexible_parts">
        <Nav className="navLinks">
          <Nav.Link className="navbar_link" href="/">
            ГЛАВНАЯ
          </Nav.Link>
          <Nav.Link className="navbar_link" href="/games">
            ТУРНИРЫ
          </Nav.Link>
          <Nav.Link className="navbar_link" href="/soon">
            МАГАЗИН
          </Nav.Link>
          {currentUser?.isAdmin && (
            <Nav.Link className="navbar_link_admin" href="/admin-panel">
              АДМИНИСТРИРОВАНИЕ
            </Nav.Link>
          )}
        </Nav>
        <MyAlert
          onHide={handleCloseMyAlert}
          show={showMyAlert}
          alertText='Вы точно хотите выйти?'
          buttons={[
            {
              text: 'Да',
              onClick: () => handleLogout()
            },
            { text: 'Нет', onClick: handleCloseMyAlert }
          ]}
        />
        <Nav>
          {loading ?
            (<div></div>)
            : (
              <>
                {currentUser?.isAuth ? (
                  <div className='navbar_acc_mainContainer'>
                    <div className='navbar_bell_container' ref={bellRef}>
                      <BellFill size={25} className='navbar_bell' onClick={handleBellClick} />
                      {hasUnreadNotifications && <div className='navbar_notif_dot' />}
                      <div ref={notificationRef} className={`notification_wrapper ${notificationsVisible ? 'visible' : 'hidden'}`}>
                        <NotificationCenter
                          isVisible={notificationsVisible}
                          onUnreadNotificationsChange={handleUnreadNotifications}
                        />
                      </div>
                    </div>
                    <div className='navbar_lobbies_container'>
                      <CollectionFill size={25} className='navbar_lobbiesBtn' onClick={handleLobbiesClick} />
                      <div className={`notification_wrapper ${lobbiesVisible ? 'visible' : 'hidden'}`}>
                        <LobbiesCenter
                          isVisible={lobbiesVisible}
                        />
                      </div>
                    </div>
                    <div className='navbar_acc_container'>
                      <div className='navbar_acc_img_container'>
                        <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${currentUser instanceof PlayerStore ? currentUser.avatarURL : currentUser.logoUrl}`} className="navbar_acc_img" alt="badge" />
                      </div>

                      <div className='navbar_acc_nickname_container'>
                        <div className='navbar_acc_nickname'>
                          {currentUser.login}
                        </div>
                      </div>
                      <List className={`navBar_dropdownIcon ${dropdownVisible ? 'active' : ''}`} onClick={toggleDropdown} />
                    </div>

                    {dropdownVisible && (
                      <div className={`dropdown_menu ${dropdownVisible ? 'visible' : ''}`}>
                        <a href={currentUser instanceof PlayerStore ? `/player/${currentUser.playerId}` : `/partner/${currentUser.partnerId}`} className="dropdown_item">Профиль</a>
                        <div className="dropdown_item disabled">Друзья</div>
                        <a href={(currentUser as PlayerStore)?.teamId ? `/team/${(currentUser as PlayerStore)?.teamId}` : `/create-team`} className="dropdown_item">Команда</a>
                        <div className="dropdown_item disabled">Мои турниры</div>
                        {partner?.partnerId ? <div className="dropdown_item disabled">Создвть турнир</div> : <></>}
                        {partner?.partnerId ? <div className="dropdown_item disabled">Создать новость</div> : <></>}
                        <div className="dropdown_item logOut" onClick={handleShowMyAlert}>Выйти</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="auth_button_container">
                    <button className="navbar_reg_btn" onClick={openRegModal}>
                      РЕГИСТРАЦИЯ
                    </button>
                    <button className="navbar_login_btn" onClick={openLogInModal}>
                      ВОЙТИ
                    </button>
                  </div>
                )}
              </>
            )}
        </Nav>
      </Navbar.Collapse>
      <RegModalWindow showModal={showRegModal} closeModal={closeRegModal} />
      <LogInModalWindow showModal={showLogInModal} closeModal={closeLogInModal} />
    </Navbar>
  );
});

export default NavBar;