import React, { useEffect, useState } from 'react';
import { Form, Pagination } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';
import { getAllPlayers } from '../../../../http/adminActionsAPI';
import CustomSpinner1 from '../../../../components/CustomSpinners/CustomSpinner1';
import { IPlayerStoreModel } from '../../../../models/dto/player/IPlayerStoreModel';
import { IPlayerPageModel } from '../../../../models/dto/player/IPlayerPageModel';

const PlayerManage: React.FC = () => {
    const [sortType, setSortType] = useState('nearest');
    const [fetchingPlayerInfo, setFetchingPlayerInfo] = useState(true);
    const [playerList, setPlayerList] = useState<IPlayerPageModel[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const playersPerPage = 6;

    useEffect(() => {
        setFetchingPlayerInfo(true);
        getAllPlayers()
            .then(data => {
                if (data) {
                    setPlayerList(data);
                }
            })
            .finally(() => setFetchingPlayerInfo(false));
    }, [])

    const handleSortTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortType(event.target.value);
    };


    const sortPlayers = (players: IPlayerPageModel[]) => {
        switch (sortType) {
            case 'nearest':
                return players.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            case 'no-steam':
                return players.filter(player => !player.steamID);
            default:
                return players;
        }
    };

    const playersToDisplay = sortPlayers(playerList);

    const indexOfLastPlayer = currentPage * playersPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
    const currentPlayers = playersToDisplay.slice(indexOfFirstPlayer, indexOfLastPlayer);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    return (
        <div className='tournaments_manage_tab_container'>
            <h4>Управление игроками</h4>
            <div className='current_tournaments_unit'>
                <div className='current_tournaments_unit_table'>
                    <div className='all_tourn_header'>Все игроки</div>
                    <div className='tournaments_manage_filter_container'>
                        <Form.Select
                            className="tournaments_manage_filter"
                            name="sortType"
                            value={sortType}
                            required
                            onChange={handleSortTypeChange}
                        >
                            <option value="nearest">Недавно созданные</option>
                            <option value="no-steam">Нет SteamId</option>
                        </Form.Select>
                    </div>
                    {fetchingPlayerInfo ? (
                        <CustomSpinner1 />
                    ) : (
                        <table className='current_tournaments_table'>
                            <thead>
                                <tr>
                                    <th className='current_tournaments_table_header'>Аватар</th>
                                    <th className='current_tournaments_table_header'>Никнейм</th>
                                    <th className='current_tournaments_table_header'>Команда</th>
                                    <th className='current_tournaments_table_header'>Дата регистрации</th>
                                    <th className='current_tournaments_table_header'>ФИО</th>
                                    <th className='current_tournaments_table_header'>Steam ID</th>
                                    <th className='current_tournaments_table_header'>Удалить</th>
                                </tr>
                            </thead>
                            <tbody className='current_tournaments_table_body'>
                                {currentPlayers.map(player => (
                                    <tr key={player.playerId}>
                                        <td className='current_tournaments_table_td'>
                                            <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${player.avatarURL}`} className='user_stat_table_td_img' alt='' />
                                        </td>
                                        <td className='current_tournaments_table_td name' onClick={() => { window.open(`/player/${player.playerId}`, '_blank') }}>
                                            {player.login}
                                        </td>
                                        <td className='current_tournaments_table_td name' onClick={() => { window.open(`/team/${player.teamId}`, '_blank') }}>
                                            {player.teamId}
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            {new Date(player.createdAt).toLocaleDateString()}
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            {player.name} {player.surname}
                                        </td>
                                        <td className={`current_tournaments_table_td ${player.steamID ? 'approved' : 'not-approved'}`}>
                                            {player.steamID !== '' ? player.steamID : 'Не привязан'}
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            <TrashFill className='general_icon' />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className='current_tournaments_pagination'>
                    <Pagination>
                        {Array.from({ length: Math.ceil(playersToDisplay.length / playersPerPage) }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </div>
            </div >
        </div >
    );
};

export default PlayerManage;