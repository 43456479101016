import React, { useState, useEffect } from 'react';
import './User.css';
import { Container } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { getPLayerInfo } from '../../http/userAPI';
import { useParams } from 'react-router-dom';
import { IPlayerPageModel } from '../../models/dto/player/IPlayerPageModel';
import PlayerStatTable from '../../components/PlayerStatTable/PlayerStatTable';
import UserHeader from '../../components/UserHeader/UserHeader';
import CS2LastActivityStatistics from '../../components/CS2LastActivityStatistics/CS2LastActivityStatistics';
import { ITableStats } from '../../models/dto/stats/cs2/ITableStats';
import CS2PlayerStats from '../../components/CS2PlayerStats/CS2PlayerStats';
import InDevelop from '../../components/InDevelop/InDevelop';

const tableData: ITableStats[] = [
  {
    date: "2024-08-07",
    subject: ["TeamA", "TeamB"],
    logoUrl: "/images/match1.png",
    kills: 85,
    deaths: 45,
    assists: 33,
    headshots: 46,
    mvp: 15,
    total: 200
  },
  {
    date: "2024-08-08",
    subject: ["TeamC", "TeamD"],
    logoUrl: "/images/match2.png",
    kills: 90,
    deaths: 50,
    assists: 37,
    headshots: 48,
    mvp: 17,
    total: 210
  },
  {
    date: "2024-08-09",
    subject: ["TeamE", "TeamF"],
    logoUrl: "/images/match3.png",
    kills: 75,
    deaths: 40,
    assists: 30,
    headshots: 42,
    mvp: 14,
    total: 185
  }
];

const User: React.FC = observer(() => {
  const [playerData, setPlayerData] = useState<IPlayerPageModel>();
  const { playerId } = useParams();
  const [activeTab, setActiveTab] = useState<'defaultStat' | 'extendedStat'>('defaultStat');
  const [lastMtchesStatData, setLastMtchesStatData] = useState<ITableStats[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchPlayerData = async () => {
      if (playerId) {
        try {
          const data = await getPLayerInfo(playerId);
          if (data) {
            setPlayerData(data);
          }
        } catch (error) {
          console.error('Fetch Player Data error:', error);
        }
      }
    };
  
    fetchPlayerData();
  }, [playerId]);
  
  useEffect(() => {
    setIsLoading(true);
    
    // TODO: let mapStatData = getPlayerMapStat(playerId: string, mapId: string);
    
    setTimeout(() => {
      setLastMtchesStatData(tableData);
      setIsLoading(false);
    }, 2000);
  }, [playerId]);
  
  return (
    <Container>
      <div className='user_general_container'>
        <div className='user_first_container'>
          <div className='userCard_mainContainer'>
            <UserHeader playerId={playerData?.playerId || null} editMode={true} />
          </div>
          
          <div className='userCard_secondContainer'>
            <InDevelop>
              <div className='userCard_secondContainer_header'>
                <div className='userCard_secondContainer_headerLevl'>ТЕКУЩИЙ УРОВЕНЬ: <span>20</span></div>
              </div>
              
              <div className='userCard_secondContainer_center'>
                <div className='userCard_secondContainer_centerCircleContainer'>
                  <div className='userCard_secondContainer_centerCircle'>
                    100%
                  </div>
                </div>
                <div className='userCard_secondContainer_playerTasksContainer'>
                  <div className='userCard_secondContainer_playerTasksTitle'>ЗАДАНИЯ ДНЯ:</div>
                  <div className='userCard_secondContainer_playerTasks'>
                    <div className='userCard_secondContainer_playerTask'>
                      <span>ПОБЕДИТЬ 2 ИГРЫ 2X2</span> <div className='userCard_secondContainer_playerTaskCircle done'></div>
                    </div>
                    
                    <div className='userCard_secondContainer_playerTask'>
                      <span>СТАТЬ MVP 5 РАЗ ПОДРЯД</span> <div className='userCard_secondContainer_playerTaskCircle done'></div>
                    </div>
                    
                    <div className='userCard_secondContainer_playerTask'>
                      <span>ЗАЛОЖИТЬ БОМБУ 3 РАЗА</span> <div className='userCard_secondContainer_playerTaskCircle done'></div>
                    </div>
                  </div>
                </div>
              </div>
            </InDevelop>
          </div>
        </div>
        
        <div className='userPage_tabs'>
          <button className={`userPage_tab ${activeTab === 'defaultStat' ? 'active' : ''}`} onClick={() => setActiveTab('defaultStat')}>Общая статистика</button>
          <button className={`userPage_tab ${activeTab === 'extendedStat' ? 'active' : ''}`} onClick={() => setActiveTab('extendedStat')}>Подробная статистика</button>
        </div>
        
        {activeTab === 'defaultStat' ? (
          <>
            <div className='user_second_container'>
              <div className='user_second_upperBlock'>
                <h5>ПОСЛЕДНИЙ МАТЧ</h5>
                <hr />
                <InDevelop>
                  <CS2LastActivityStatistics playerId={playerId || null} statType='match' />
                </InDevelop>
                <hr />
              </div>
            
              <div className='user_second_lowerBlock'>
                <h5>ПОСЛЕДНИЙ ТУРНИР</h5>
                <hr />
                <InDevelop>
                  <CS2LastActivityStatistics playerId={playerId || null} statType='tournament' />
                </InDevelop>
                <hr />
              </div>
            </div>
            
            <div className='user_third_container'>
              <h5>ПОСЛЕДНИЕ МАТЧИ</h5>
              <hr />
              <InDevelop>
                <div className='user_game_units_container'>
                  <button className='user_game_unit_stat active'>COUNTER STRIKE 2</button>
                  <button className='user_game_unit_stat disabled'>DOTA 2</button>
                </div>
                <div className='user_stat_second_levels'>
                  <div className='user_stat_second_level1'>
                    <div className='user_detailedstat_container'>
                      <PlayerStatTable data={lastMtchesStatData || []} isMatchPage={false} />
                      <div className='user_detailedstat'>
                      </div>
                    </div>
                  </div>
                </div>
              </InDevelop>
            </div>
          </>
        ) : (
          <CS2PlayerStats />
        )}
      </div>
    </Container >
  );
});

export default User;