import { useState } from 'react';
import { IMapKillsStat } from '../../models/dto/stats/cs2/playerStats/IMapKillsStat';
import './CS2Chart.css';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { ITotalShootingStats } from '../../models/dto/stats/cs2/playerStats/ITotalShootingStats';
import { useNavigate } from 'react-router-dom';
import { CurveType } from 'recharts/types/shape/Curve';

type TooltipTriggerType = 'hover' | 'click';
type DataType = ITotalShootingStats | IMapKillsStat;

interface ICS2ChartProps {
    chartName: string;
    
    chartData: ITotalShootingStats[] | IMapKillsStat[];
    chartDataKey: string;
    
    chartMainLineType?: CurveType;
    chartMainLineColor?: string;
    
    chartXLineColor?: string;
    chartXLineDataType?: string;
    chartXLineDataTick?: boolean;
    
    chartYLineColor?: string;
    chartYLineDataType?: string;
    chartYLineDataTick?: boolean;
    
    isLoading?: boolean;
}

const CS2Chart: React.FC<ICS2ChartProps> = ({ 
    chartName, 
    chartData, 
    chartDataKey, 
    chartMainLineType, 
    chartMainLineColor, 
    chartXLineColor, 
    chartXLineDataType, 
    chartXLineDataTick, 
    chartYLineColor, 
    chartYLineDataType, 
    chartYLineDataTick ,
    isLoading
}) => {
    const [tooltipTrigger, setTooltipTrigger] = useState<TooltipTriggerType>('hover');
    const navigate = useNavigate();
    
    const toggleTrigger = () => {
        setTooltipTrigger(tooltipTrigger === 'hover' ? 'click' : 'hover');
    };
    
    const CustomTooltip = ({ label, active, dataType }: any) => {
        if (active && label) {
            let currentData: DataType | undefined;
            
            currentData = (chartData as DataType[]).find((item) => item.matchId === label);
            
            if (!currentData) return null;
            
            const isTotalShootingStats = (data: DataType): data is ITotalShootingStats => {
                return (data as ITotalShootingStats).HS !== undefined;
            };
            
            return (
                <div className="custom-chart-tooltip" onClick={() => { navigate(`/match/${currentData?.matchId}`) }}>
                    <p>МАТЧ: {currentData.matchTeams.teamATag} vs {currentData.matchTeams.teamBTag}</p>
                    <>
                        <p>{currentData.tournamentName}</p>
                        <p>{currentData.matchDate}</p>
                        {isTotalShootingStats(currentData) && dataType === 'HS' && <p className="desc">% HS: {currentData.HS}</p>}
                        {isTotalShootingStats(currentData) && dataType === 'accuracy' && <p className="desc">% A: {currentData.accuracy}</p>}
                        {dataType === 'kills' && 'kills' in currentData && <p className="desc">KILLS: {currentData.kills}</p>}
                    </>
                </div>
            );
        }
        return null;
    };
    
    return (
        <div className='cs2KillChart_continer'>
            <div className='cs2_linechart_container'>
                <LineChart width={550} height={250} data={chartData} onClick={toggleTrigger}>
                    {isLoading ? (
                        <></>
                    ) : (
                        <>
                            <Line type={chartMainLineType ?? 'monotone'} dataKey={chartDataKey} stroke={chartMainLineColor ?? '#fff'} strokeWidth={2} />
                            <XAxis dataKey={chartXLineDataType} stroke={chartXLineColor ?? '#fff'} tick={chartXLineDataTick ?? false} />
                            <YAxis dataKey={chartYLineDataType} stroke={chartYLineColor ?? '#fff'} tick={chartYLineDataTick ?? false} />
                            <Tooltip content={<CustomTooltip dataType={chartDataKey} />} trigger={tooltipTrigger} />
                        </>
                    )}
                </LineChart>
            </div>
            <h4>{chartName}</h4>
        </div>
    );
};

export default CS2Chart;