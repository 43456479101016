import React from 'react';
import './TeamAboutTab.css';
import { ITeamStoreModel } from '../../../../models/dto/team/ITeamStoreModel';


interface TeamAboutProps {
    team: ITeamStoreModel; 
}

const TeamAboutTab: React.FC<TeamAboutProps> = ({ team }) => {
    const date = new Date(team.creationDate);

    const formattedDate = date.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: 'long',    
        year: 'numeric'
    });

    return (
        <div className='team_about_container'>
            <div className='team_about_inner_container'>
                <div className='team_about_inner_container_item'>
                    <div className='team_about_player_title'>Дата создания:</div>
                        <div className='team_about_partners date'>
                            {formattedDate}
                        </div>
                    <div className='team_about_player_title'>О команде:</div>
                        <div className='team_about_desc'>
                            {team.description}
                        </div>
                </div>
                <div className='team_about_inner_container_item'>
                    <div className='team_about_player_title'>Website:</div>
                        <div className='team_about_desc'>
                            {team.website}
                        </div>
                    <div className='team_about_player_title'>Контакт для связи:</div>
                        <div className='team_about_desc'>
                            {team.contactEmail}
                        </div>
                    <div className='team_about_player_title'>Социальный сети:</div>
                        <div className='team_about_desc'>
                            {team.socialMediaLinks}
                        </div>
                </div>
            </div>
        </div>
    );
};

export default TeamAboutTab;