import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useParams, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Main from '../pages/Main/Main';
import User from '../pages/User/User';
import { AppContext } from './AppContext';
import NotFound from './NotFound/NotFound';
import Registration from '../pages/Registration/Registration';
import CreateTournament from '../pages/CreateTournament/CreateTournament';
import GamesList from '../pages/GamesList/GamesList';
import MapSelection from '../pages/MapSelection/MapSelection';
import ChosenGame from '../pages/ChosenGame/ChosenGame';
import ChosenTournament from '../pages/ChosenTournament/ChosenTournament';
import SoonEmpty from './SoonEmpty/SoonEmpty';
import Team from '../pages/TeamPage/Team';
import ResetPassword from './ResetPassword/ResetPassword';
import PartnerPage from '../pages/PartnerPage/PartnerPage';
import CreateTeam from '../pages/CreateTeam/CreateTeam';
import UpdateTeam from '../pages/UpdateTeam/UpdateTeam';
import MatchPage from '../pages/MatchPage/MatchPage';
import AdminPanel from '../pages/AdminPanel/AdminPanel';
import WarningPage from './WarningPage/WarningPage';

type RouteConfig = {
  path: string;
  Component: React.ComponentType<any>;
};

const publicRoutes: RouteConfig[] = [
  { path: '/', Component: Main },
  { path: '*', Component: NotFound },
  { path: '/player/:playerId', Component: User },
  { path: '/team/:teamId', Component: Team },
  { path: '/game/:gameTypeId', Component: ChosenGame },
  { path: '/confirm-email/:userId/:code', Component: Registration },
  { path: '/reset-password/:userId/:code', Component: ResetPassword },
  { path: '/tournament/:tournamentId', Component: ChosenTournament },
  { path: '/match/:matchId', Component: MatchPage },
  { path: '/games', Component: GamesList },
  { path: '/matchlobby/:matchLobbyId', Component: MapSelection },
  { path: '/soon', Component: SoonEmpty },
  { path: '/partner/:partnerId', Component: PartnerPage },
  { path: '/warning', Component: WarningPage },
];

const authRoutes: RouteConfig[] = [
  { path: '/create-tournament', Component: CreateTournament },
  { path: '/create-team', Component: CreateTeam },
];

const adminRoutes: RouteConfig[] = [
  { path: '/admin-panel/*', Component: AdminPanel }
];

const AppRouter: React.FC = observer(() => {
  const { player, playerTeam } = useContext(AppContext);
  const location = useLocation(); 
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1000);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() => {
    const isExceptionPath = location.pathname.includes('/confirm-email') || location.pathname.includes('/reset-password');
    
    if (isMobile && !isExceptionPath && location.pathname !== '/warning') {
      navigate('/warning');
    }
  }, [location.pathname, navigate]);
  
  const RouteWrapper: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
    const { teamId } = useParams();
    if ((player?.teamId === teamId && player?.playerId === playerTeam?.playerCreatorId) || player?.isAdmin) {
      return <>{children}</>;
    }
    return <Navigate to="*" replace />;
  });
  
  return (
    <Routes>
      {publicRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
      
      {player?.isAuth && authRoutes.map(({ path, Component }) => {
        if (path === '/create-team') {
          return playerTeam?.teamId
            ? <Route key={path} path={path} element={<Navigate to="/" replace />} />
            : <Route key={path} path={path} element={<Component />} />;
        }
        
        return <Route key={path} path={path} element={<Component />} />;
      })}
      
      {player?.isAuth && (
        <Route
          path="/update-team/:teamId"
          element={
            <RouteWrapper>
              <UpdateTeam />
            </RouteWrapper>
          }
        />
      )}
      
      {player?.isAdmin &&
        adminRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
    </Routes>
  );
});

export default AppRouter;
