import React from 'react';
import './News.css';
import { INewsModel } from '../../models/INewsModel';
import NewsItem from './NewsItem';

interface NewsProps {
    newsArr: INewsModel[]; 
}

const News: React.FC<NewsProps> = ({ newsArr }) => {
    let news_div;
    
    switch (newsArr.length) {
        case 6:
            news_div = newsArr.map((newsItem, index) => (
                index === 2 || index === 3  
                ? <div className={`news_item_medium`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div> 
                : <div className={`news_item_min`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div>
            ));
            break;
        
        case 5:
            news_div = newsArr.map((newsItem, index) => (
                index === 0 || index === 1 
                ? <div className={`news_item_medium`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div> 
                : <div className={`news_item_small`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div>
            ));
            break;
        
        case 4:
            news_div = newsArr.map((newsItem, index) => (
                <div className={`news_item_medium`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div> 
            ));
            break;
        
        case 3:
            news_div = newsArr.map((newsItem, index) => (
                <div className={`news_item_small`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div>
            ));
            break;
        
        case 2:
            news_div = newsArr.map((newsItem, index) => (
                <div className={`news_item_big`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div>
            ));
            break;
        
        case 1:
            news_div = newsArr.map((newsItem, index) => (
                <div className={`news_item_ultra`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div> 
            ));
            break;
        
        default:
            if (newsArr.length > 6) {
                news_div = newsArr.slice(-6).map((newsItem, index) => (
                    index === 2 || index === 3  
                    ? <div className={`news_item_medium`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div> 
                    : <div className={`news_item_min`} key={index}><NewsItem title={newsItem.title} description={newsItem.description} imgSrc={newsItem.imgSrc} /></div>
                ));
                break;
            } else {
                news_div = <div className={'default'}>Нет новостей</div>;
                break;
            }
    }
    
    return (
        <div className='news_container'>
            <div className='news_title'>
                НОВОСТИ
            </div>
            <div className='news_content'>
                {news_div}
            </div>
        </div>
    );
};

export default News;