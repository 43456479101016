import './MapSelection.css';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import starCaptain from '../../assets/img/LobbyIndicators/starСaptain.svg'
import { observer } from 'mobx-react-lite';
import { AppContext } from '../../components/AppContext';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { ICS2LobbySettings } from '../../models/dto/lobby/CS2/ICS2LobbySettings';
import { ICS2LobbyState } from '../../models/dto/lobby/CS2/ICS2LobbyState';
import { ICS2LobbyMember } from '../../models/dto/lobby/CS2/ICS2LobbyMember';
import { useModal } from '../../hooks/useModal';
import { CheckLg, Clipboard, Globe2 } from 'react-bootstrap-icons';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';
import { MemberTypeEnum } from '../../models/enums/MemberTypeEnum';


const CardSelection: React.FC = observer(() => {
    const { matchLobbyId } = useParams<{ matchLobbyId: string }>();
    const { player, playerTeam } = useContext(AppContext);
    const { isVisible: showServerLoadingModal, openModal: openServerLoadingModal, closeModal: closeServerLoadingModal } = useModal();

    const [connection, setConnection] = useState<HubConnection | null>(null);
    const [lobbySettings, setLobbySettings] = useState<ICS2LobbySettings>();
    const [lobbyState, setLobbyState] = useState<ICS2LobbyState>();
    const [banTimer, setBanTimer] = useState(0);
    const [showServerModal, setShowServerModal] = useState<boolean>(false);

    const connectionRef = useRef<HubConnection | null>(null);
    const lobbySettingsRef = useRef<ICS2LobbySettings | undefined>(undefined);
    const lobbyStateRef = useRef<ICS2LobbyState | undefined>(undefined);
    const [lobbyAlive, setLobbyAlive] = useState(true);
    const [notBeginningBans, setNotBeginningBans] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const navigate = useNavigate();
    const playerOrCaptain = player?.playerId === playerTeam?.captainId ? 'captain' : 'player';

    const isTeamABanning = lobbyState?.teamA.players.some(player => player.memberId === lobbyStateRef.current?.baningCaptainId);
    const banningTeamName = isTeamABanning ? lobbyState?.teamA.teamName : lobbyState?.teamB.teamName;

    let sortTeamA: ICS2LobbyMember[] = (lobbyState?.teamA?.players || []).sort((a, b) => {
        if (a.isCaptain === b.isCaptain) {
            return 0;
        }
        return a.isCaptain ? -1 : 1;
    });

    let sortTeamB: ICS2LobbyMember[] = (lobbyState?.teamB?.players || []).sort((a, b) => {
        if (a.isCaptain === b.isCaptain) {
            return 0;
        }
        return a.isCaptain ? -1 : 1;
    });

    useEffect(() => {
        connectionRef.current = connection;
        lobbySettingsRef.current = lobbySettings;
        lobbyStateRef.current = lobbyState;
    }, [connection, lobbySettings, lobbyState]);

    useEffect(() => {
        if (lobbyState?.serverIp && lobbyState?.serverPort && lobbyState?.serverPassword && showServerModal) {
            openServerLoadingModal();
        }
    }, [lobbyState, showServerModal])


    useEffect(() => {
        const connect: HubConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_BACKEND_API_URL}matchlobby/${matchLobbyId}`)
            .withAutomaticReconnect([500, 500, 500])
            .build();

        setConnection(connect);

        if (connect) {
            connect.onclose(() => {
                setLobbyAlive(false);
            });

            connect.on("CloseConnection", (matchId: string) => {
                navigate("/");
            });

            connect.on("SetLobbySettings", (lobbySettings: ICS2LobbySettings) => {
                setLobbySettings(lobbySettings);
                lobbySettingsRef.current = lobbySettings;
            });

            connect.on("SetLobbyState", (lobbyState: ICS2LobbyState) => {
                setLobbyState(lobbyState);
                lobbyStateRef.current = lobbyState;
            });

            connect.on("UpdateTimer", (timeLeft: number) => {
                setBanTimer(timeLeft);
            });

            connect.on("TimerStageExpired", async (lobbyId: string) => {

            });

            connect.on("AllTimerStagesExpired", async (lobbyId: string) => {
                setBanTimer(0);
                openServerLoadingModal();
                setShowServerModal(true);
            });


            connect.start()
                .then(async () => {
                    let lobbyStateResult: ICS2LobbyState = await connect.invoke("GetLobbyState", matchLobbyId) as ICS2LobbyState;
                    if (lobbyStateResult) {
                        let playerTeamA = lobbyStateResult.teamA?.players?.find(p => p.memberId === player?.playerId);
                        let playerTeamB = lobbyStateResult.teamB?.players?.find(p => p.memberId === player?.playerId);
                        let playerInOthers = lobbyStateResult.others.find(o => o.memberId === player?.playerId);
                        if (playerTeamA || playerTeamB) {
                            await connect.invoke("PlayerConnected", matchLobbyId, player?.playerId, player?.teamId);
                        } else if (playerInOthers) {
                            await connect.invoke("OtherMemberConnected", matchLobbyId, player?.playerId);
                        } else {
                            redirect("/");
                        }
                        window.addEventListener("beforeunload", () => { unloadHandler(connect) });
                    } else {
                        console.log("Cannot get lobby state!");
                    }
                })
                .catch(err => {
                    console.error(err);
                    setLobbyAlive(false);
                });
        } else {
            ///TODO: редирект на AccessDenied
            navigate("/");
        }

        return () => {
            let playerTeamA = lobbyStateRef.current?.teamA?.players?.find(p => p.memberId === player?.playerId);
            let playerTeamB = lobbyStateRef.current?.teamB?.players?.find(p => p.memberId === player?.playerId);
            let playerInOthers = lobbyStateRef.current?.others.find(o => o.memberId === player?.playerId);
            if (playerTeamA || playerTeamB) {
                connect.invoke("PlayerDisconnected", matchLobbyId, player?.playerId, player?.teamId)
            } else if (playerInOthers) {
                connect.invoke("OtherMemberDisconnected", matchLobbyId, player?.playerId)
            } else {
                window.removeEventListener("beforeunload", () => { unloadHandler(connect) });
                redirect("/");
            }
            window.removeEventListener("beforeunload", () => { unloadHandler(connect) });
        };
    }, []);

    useEffect(() => {
        return () => {
            window.removeEventListener("beforeunload", () => { unloadHandler(connection) });
        };
    }, [matchLobbyId, player?.playerId]);

    const closeServerLoading = () => {
        setShowServerModal(false);
        closeServerLoadingModal();
    }

    const unloadHandler = async (connect: HubConnection | null) => {
        let playerTeamA = lobbyStateRef.current?.teamA?.players?.find(p => p.memberId === player?.playerId);
        let playerTeamB = lobbyStateRef.current?.teamB?.players?.find(p => p.memberId === player?.playerId);
        let playerInOthers = lobbyStateRef.current?.others.find(o => o.memberId === player?.playerId);
        if (playerTeamA || playerTeamB) {
            await connect?.invoke("PlayerDisconnected", matchLobbyId, player?.playerId, player?.teamId)
        } else if (playerInOthers) {
            await connect?.invoke("OtherMemberConnected", matchLobbyId, player?.playerId)
        } else {
            console.log("Blocked!");
            window.removeEventListener("beforeunload", () => { unloadHandler(connect) });
            redirect("/");
        }

        window.removeEventListener("beforeunload", () => { unloadHandler(connect) });
    };

    const handleMapSelection = async (mapId: string) => {
        if (player?.playerId === lobbyStateRef.current?.baningCaptainId
            && connectionRef.current
            && (lobbyStateRef.current?.selectedMapNames?.length || 0) === 0) {
            await connectionRef.current.invoke("BanMap", matchLobbyId, mapId);
        }

        if (player?.playerId === lobbyStateRef.current?.baningCaptainId
            && connectionRef.current
            && (lobbyStateRef.current?.selectedMapNames?.length || 0) <= 1) {
            setNotBeginningBans(true);
        }
    };

    const handleReadyClick = async (isReady: boolean) => {
        if (connection != null) {
            let playerTeamA = lobbyState?.teamA?.players?.find(p => p.memberId === player?.playerId);
            let playerTeamB = lobbyState?.teamB?.players?.find(p => p.memberId === player?.playerId);
            let playerInOthers = lobbyState?.others.find(o => o.memberId === player?.playerId);
            if (isReady) {
                if (playerTeamA || playerTeamB) {
                    let allReadyResponse: boolean = await connection?.invoke("SetPlayerReady", matchLobbyId, player?.playerId, player?.teamId) ?? false;
                    if (allReadyResponse) {
                        await startBanTimer();
                    }
                } else if (playerInOthers) {
                    let allReadyResponse: boolean = await connection?.invoke("SetOtherMemberReady", matchLobbyId, player?.playerId) ?? false;
                    if (allReadyResponse) {
                        await startBanTimer();
                    }
                }
            } else {
                if (playerTeamA || playerTeamB) {
                    connection?.invoke("SetPlayerNotReady", matchLobbyId, player?.playerId, player?.teamId)
                        .catch((error) => { console.log(error); });
                } else if (playerInOthers) {
                    connection?.invoke("SetOtherMemberNotReady", matchLobbyId, player?.playerId)
                        .catch((error) => { console.log(error); });
                }
            }
        }
    };

    const handleTestReloadLobbyClick = () => {
        if (connection != null) {
            connection.invoke("RecycleLobby", matchLobbyId);
        } else {
            console.log("Connection is null");
        }
    }
    const handleCopy = async () => {
        const copyText = `connect ${lobbyState?.serverIp}:${lobbyState?.serverPort}; password ${lobbyState?.serverPassword}`;
        if (navigator?.clipboard) {
            await navigator.clipboard.writeText(copyText);
        } else {

        }
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 5000);
    };

    function getReadyStateForCurrentMember(): boolean {
        let playerTeamA = lobbyState?.teamA?.players?.find(p => p.memberId === player?.playerId);
        let playerTeamB = lobbyState?.teamB?.players?.find(p => p.memberId === player?.playerId);
        let playerInOthers = lobbyState?.others.find(o => o.memberId === player?.playerId);

        if (playerTeamA) {
            return playerTeamA.isReady;
        } else if (playerTeamB) {
            return playerTeamB.isReady;
        } else if (playerInOthers) {
            return playerInOthers.isReady;
        }

        return false;
    }

    async function startBanTimer() {
        let countOfTimerStages = lobbySettingsRef.current!.cs2Maps.length - lobbySettingsRef.current!.countOfMapsForPlaying;
        await connectionRef.current?.invoke("StartTimer", matchLobbyId, countOfTimerStages);
        if (!lobbyStateRef.current?.banTimerVisible) {
            await connectionRef.current?.invoke("SetBanTimerVisibility", matchLobbyId, true);
        }
    }

    return (
        <div className='mapSelection_container'>
            {lobbyAlive ? (
                <>
                    <div className='mapSelection_mainContant'>
                        <div className='teams_container'>
                            <h3 className='teams_name' onClick={() => window.open(`/team/${lobbyState?.teamA?.teamId}`, '_blank')}>
                                {lobbyState?.teamA?.teamName}
                            </h3>
                            <div className='teams_players'>
                                {
                                    sortTeamA.map((playerMember) => (
                                        <div className='teams_team'>
                                            <div className={`isConnected_container ${playerMember?.isConnected ? 'connected' : ''}`}>
                                                <Globe2 size={27} />
                                            </div>

                                            <div className={`teams_player_container ${playerMember?.isReady ? 'ready' : ''} firstTeam`} key={`${playerMember.memberId}_container`}>
                                                <div className='teams_player_avatarContainer firstTeam'>
                                                    <img className='teams_player_avatar' src={`${process.env.REACT_APP_IMG_MINIO_URL}${playerMember.avatarUrl}`} alt='palyerAvatar' />
                                                </div>

                                                <div className='teams_player_nameContainer' onClick={() => window.open(`/player/${playerMember.memberId}`, '_blank')}>
                                                    <h3 className='teams_player_name'>{playerMember.userName}</h3>
                                                </div>

                                                <div className='teams_player_isCaptainContainer'>
                                                    {
                                                        playerMember.isCaptain && (<img src={starCaptain} alt='isCaptain' />)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className='maps_container'>
                            {
                                lobbyState?.banTimerVisible ? (
                                    <>
                                        <div className='maps_title_container'>
                                            <h3>СТАДИЯ БАНОВ</h3>
                                            {
                                                banTimer !== 0 &&
                                                <>
                                                    <div className='maps_title_chooser'>
                                                        Банит капитан команды <br /><span>{banningTeamName}</span>
                                                    </div>
                                                    <div className="maps_ban_banTimer">
                                                        Оставшееся время для выбора: <span>{banTimer}</span> сек.
                                                    </div>
                                                </>
                                            }

                                            {!showServerLoadingModal && (
                                                <button className='serverModalButton' onClick={() => openServerLoadingModal()}>Строка подключения</button>
                                            )}
                                        </div>
                                        <div className='maps'>
                                            {
                                                lobbySettings?.cs2Maps.map((map) => (
                                                    <div
                                                        key={map.mapId}
                                                        className={`map ${lobbyState?.bannedMapIds?.includes(map.mapId) ? 'pick' : (banTimer <= 0 && notBeginningBans) ? 'game' : playerOrCaptain}`}
                                                        style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_MINIO_URL}${map.mapImgUrl})` }}
                                                        onClick={() => (player?.playerId === playerTeam?.captainId || false) && !lobbyState?.bannedMapIds?.includes(map.mapId) && handleMapSelection(map.mapId)}
                                                    >
                                                        <h3>{map.name}</h3>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <div className='mapselection_ready_buttonContainer'>
                                        <button className={`mapselection_ready_button ${getReadyStateForCurrentMember() ? '' : 'ready'}`} onClick={() => handleReadyClick(!getReadyStateForCurrentMember())}>
                                            {getReadyStateForCurrentMember() ? 'НЕ ГОТОВ' : 'ГОТОВ'}
                                        </button>
                                    </div>
                                )
                            }
                        </div>

                        <div className='teams_container'>
                            <h3 className='teams_name secondTeam' onClick={() => window.open(`/team/${lobbyState?.teamB?.teamId}`, '_blank')}>
                                {lobbyState?.teamB?.teamName}
                            </h3>
                            <div className='teams_players'>
                                {
                                    sortTeamB.map((playerMember) => (
                                        <div className='teams_team'>
                                            <div className={`teams_player_container ${playerMember?.isReady ? 'ready' : ''}`} key={`${playerMember.memberId}_container`}>
                                                <div className='teams_player_avatarContainer'>
                                                    <img className='teams_player_avatar' src={`${process.env.REACT_APP_IMG_MINIO_URL}${playerMember.avatarUrl}`} alt='palyerAvatar' />
                                                </div>

                                                <div className='teams_player_nameContainer' onClick={() => window.open(`/player/${playerMember.memberId}`, '_blank')}>
                                                    <h3 className='teams_player_name'>{playerMember.userName}</h3>
                                                </div>

                                                <div className='teams_player_isCaptainContainer'>
                                                    {
                                                        playerMember.isCaptain && (<img src={starCaptain} alt='isCaptain' />)
                                                    }
                                                </div>
                                            </div>

                                            <div className={`isConnected_container ${playerMember?.isConnected ? 'connected' : ''} secondTeam`}>
                                                <Globe2 size={27} />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <Modal className='serverReady_modal' show={showServerLoadingModal} size='lg' onHide={closeServerLoading}>
                            <Modal.Body className='serverReady_modal_body'>
                                <Modal.Title className='serverReady_modal_title'>
                                    {lobbyStateRef.current?.serverIp && lobbyStateRef.current?.serverPort && lobbyStateRef.current?.serverPassword ? 'Информация о подключении к серверу' : 'Ожидание ответа от сервера'}
                                </Modal.Title>
                                <hr></hr>
                                <div className='serverReady_modal_mainInfo'>
                                    {
                                        lobbyState?.serverIp && lobbyState?.serverPort && lobbyState?.serverPassword ? (
                                            <div className='server_ready_container'>
                                                <div className='server_info'>
                                                    <div>СТРОКА ПОДКЛЮЧЕНИЯ</div>
                                                    <div className='server_connect_address'>
                                                        connect {lobbyState?.serverIp}:{lobbyState?.serverPort}; password {lobbyState?.serverPassword}
                                                        {isCopied ? <CheckLg /> : <Clipboard onClick={handleCopy} className='clipboard_icon' />}
                                                    </div>
                                                </div>
                                                <a href={`steam://connect/${lobbyState?.serverIp}:${lobbyState?.serverPort}/${lobbyState?.serverPassword}`} className='lobby_connect_button'>Подключиться к игре</a>
                                            </div>
                                        ) : (
                                            <div className='server_not_ready_container'>
                                                <div>Запустите Counter-Strike 2, если вы этого еще не сделали</div>
                                                <CustomSpinner1 size={`100`} sizeSpinnerContainer='120px' />
                                            </div>
                                        )
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>
                        <button className='recylcleButton' onClick={() => handleTestReloadLobbyClick()}>
                            RECYCLE LOBBY
                        </button>
                    </div>

                    <div className='additional_lobby_members'>
                        {lobbyStateRef.current?.others.some(o => o.memberType === MemberTypeEnum.Streamer) && (
                            <div className='lobby_members'>
                                <h2 className='lobby_members_title'>Streamers</h2>

                                <div className='lobby_members_map'>
                                    {lobbyStateRef.current?.others.filter(m => m.memberType === MemberTypeEnum.Streamer).map(m => (
                                        <div className='lobby_memderContainer'>
                                            <div className={`lobby_memderImgContainer ${m.isReady ? 'ready' : ''}`}>
                                                <img alt={`${m.userName}`} className='lobby_memderImg' src={`${process.env.REACT_APP_IMG_MINIO_URL}${m.avatarUrl}`} />
                                            </div>
                                            <div className='lobby_memderName'>{m.userName}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {lobbyStateRef.current?.others.some(m => m.memberType === MemberTypeEnum.Observer) && (
                            <div className='lobby_members'>
                                <h2 className='lobby_members_title'>Observers</h2>
                                <div className='lobby_members_map'>
                                    {lobbyStateRef.current?.others.filter(m => m.memberType === MemberTypeEnum.Observer).map(m => (
                                        <div className='lobby_memderContainer' key={m.memberId}>
                                            <div className={`lobby_memderImgContainer ${m.isReady ? 'ready' : ''}`}>
                                                <img alt={`${m.userName}`} className='lobby_memderImg' src={`${process.env.REACT_APP_IMG_MINIO_URL}${m.avatarUrl}`} />
                                            </div>
                                            <div className='lobby_memderName'>{m.userName}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {lobbyStateRef.current?.others.some(m => m.memberType === MemberTypeEnum.Admin) && (
                            <div className='lobby_members'>
                                <h2 className='lobby_members_title'>Admins</h2>
                                <div className='lobby_members_map'>
                                    {lobbyStateRef.current?.others.filter(m => m.memberType === MemberTypeEnum.Admin).map(m => (
                                        <div className='lobby_memderContainer' key={m.memberId}>
                                            <div className={`lobby_memderImgContainer ${m.isReady ? 'ready' : ''}`}>
                                                <img alt={`${m.userName}`} className='lobby_memderImg' src={`${process.env.REACT_APP_IMG_MINIO_URL}${m.avatarUrl}`} />
                                            </div>
                                            <div className='lobby_memderName'>{m.userName}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className='old_lobby'>Данное лобби устарело</div>
                </>
            )}
        </div>
    );
});

export default CardSelection;