import { Container } from 'react-bootstrap';
import './MatchPage.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PlayerStatTable from '../../components/PlayerStatTable/PlayerStatTable';
import { IMatchStats } from '../../models/dto/stats/cs2/IMatchStats';
import InDevelop from '../../components/InDevelop/InDevelop';

const statsData: IMatchStats = {
    matchId: "match1",
    teams: {
        teamAId: "teamA1",
        teamATag: "Alpha",
        teamBId: "teamB1",
        teamBTag: "Bravo"
    },
    tournamentName: "CS2 Championship",
    tournamentType: "BO3",
    matchDate: "2024-08-07",
    teamALogo: "team.png",
    teamBLogo: "team.png",
    scoreA: 2,
    scoreB: 1,
    maps: [
        {
            mapName: "Mirage",
            matchDuration: "35:27",
            streamUrl: "https://example.com/stream1",
            mapScoreA: 16,
            mapScoreB: 14,
            teamAStats: [
                {
                    subject: "Player1",
                    logoUrl: "https://example.com/player1.png",
                    kills: 20,
                    deaths: 5,
                    assists: 7,
                    headshots: 10,
                    mvp: 3,
                    total: 40
                },
                {
                    subject: "Player2",
                    logoUrl: "https://example.com/player2.png",
                    kills: 18,
                    deaths: 7,
                    assists: 6,
                    headshots: 9,
                    mvp: 2,
                    total: 35
                },
                {
                    subject: "Player3",
                    logoUrl: "https://example.com/player3.png",
                    kills: 15,
                    deaths: 10,
                    assists: 5,
                    headshots: 7,
                    mvp: 1,
                    total: 30
                },
                {
                    subject: "Player4",
                    logoUrl: "https://example.com/player4.png",
                    kills: 17,
                    deaths: 8,
                    assists: 7,
                    headshots: 8,
                    mvp: 2,
                    total: 34
                },
                {
                    subject: "Player5",
                    logoUrl: "https://example.com/player5.png",
                    kills: 22,
                    deaths: 9,
                    assists: 8,
                    headshots: 12,
                    mvp: 4,
                    total: 44
                }
            ],
            teamBStats: [
                {
                    subject: "Player6",
                    logoUrl: "https://example.com/player6.png",
                    kills: 20,
                    deaths: 6,
                    assists: 8,
                    headshots: 11,
                    mvp: 3,
                    total: 40
                },
                {
                    subject: "Player7",
                    logoUrl: "https://example.com/player7.png",
                    kills: 18,
                    deaths: 7,
                    assists: 6,
                    headshots: 9,
                    mvp: 2,
                    total: 35
                },
                {
                    subject: "Player8",
                    logoUrl: "https://example.com/player8.png",
                    kills: 15,
                    deaths: 10,
                    assists: 5,
                    headshots: 7,
                    mvp: 1,
                    total: 30
                },
                {
                    subject: "Player9",
                    logoUrl: "https://example.com/player9.png",
                    kills: 17,
                    deaths: 8,
                    assists: 7,
                    headshots: 8,
                    mvp: 2,
                    total: 34
                },
                {
                    subject: "Player10",
                    logoUrl: "https://example.com/player10.png",
                    kills: 22,
                    deaths: 9,
                    assists: 8,
                    headshots: 12,
                    mvp: 4,
                    total: 44
                }
            ]
        },
        {
            mapName: "Inferno",
            matchDuration: "40:15",
            streamUrl: "https://example.com/stream2",
            mapScoreA: 10,
            mapScoreB: 16,
            teamAStats: [
                {
                    subject: "Player1",
                    logoUrl: "https://example.com/player1.png",
                    kills: 12,
                    deaths: 8,
                    assists: 5,
                    headshots: 6,
                    mvp: 1,
                    total: 22
                },
                {
                    subject: "Player2",
                    logoUrl: "https://example.com/player2.png",
                    kills: 14,
                    deaths: 9,
                    assists: 7,
                    headshots: 8,
                    mvp: 2,
                    total: 28
                },
                {
                    subject: "Player3",
                    logoUrl: "https://example.com/player3.png",
                    kills: 13,
                    deaths: 7,
                    assists: 6,
                    headshots: 7,
                    mvp: 1,
                    total: 27
                },
                {
                    subject: "Player4",
                    logoUrl: "https://example.com/player4.png",
                    kills: 16,
                    deaths: 10,
                    assists: 5,
                    headshots: 8,
                    mvp: 2,
                    total: 31
                },
                {
                    subject: "Player5",
                    logoUrl: "https://example.com/player5.png",
                    kills: 15,
                    deaths: 9,
                    assists: 4,
                    headshots: 6,
                    mvp: 1,
                    total: 25
                }
            ],
            teamBStats: [
                {
                    subject: "Player6",
                    logoUrl: "https://example.com/player6.png",
                    kills: 20,
                    deaths: 6,
                    assists: 8,
                    headshots: 11,
                    mvp: 3,
                    total: 40
                },
                {
                    subject: "Player7",
                    logoUrl: "https://example.com/player7.png",
                    kills: 18,
                    deaths: 7,
                    assists: 6,
                    headshots: 9,
                    mvp: 2,
                    total: 35
                },
                {
                    subject: "Player8",
                    logoUrl: "https://example.com/player8.png",
                    kills: 15,
                    deaths: 10,
                    assists: 5,
                    headshots: 7,
                    mvp: 1,
                    total: 30
                },
                {
                    subject: "Player9",
                    logoUrl: "https://example.com/player9.png",
                    kills: 17,
                    deaths: 8,
                    assists: 7,
                    headshots: 8,
                    mvp: 2,
                    total: 34
                },
                {
                    subject: "Player10",
                    logoUrl: "https://example.com/player10.png",
                    kills: 22,
                    deaths: 9,
                    assists: 8,
                    headshots: 12,
                    mvp: 4,
                    total: 44
                }
            ]
        },
        {
            mapName: "Dust2",
            matchDuration: "45:10",
            streamUrl: "https://example.com/stream3",
            mapScoreA: 16,
            mapScoreB: 12,
            teamAStats: [
                {
                    subject: "Player1",
                    logoUrl: "https://example.com/player1.png",
                    kills: 22,
                    deaths: 10,
                    assists: 7,
                    headshots: 12,
                    mvp: 4,
                    total: 45
                },
                {
                    subject: "Player2",
                    logoUrl: "https://example.com/player2.png",
                    kills: 18,
                    deaths: 8,
                    assists: 6,
                    headshots: 9,
                    mvp: 3,
                    total: 36
                },
                {
                    subject: "Player3",
                    logoUrl: "https://example.com/player3.png",
                    kills: 17,
                    deaths: 7,
                    assists: 6,
                    headshots: 8,
                    mvp: 2,
                    total: 33
                },
                {
                    subject: "Player4",
                    logoUrl: "https://example.com/player4.png",
                    kills: 19,
                    deaths: 9,
                    assists: 7,
                    headshots: 10,
                    mvp: 3,
                    total: 38
                },
                {
                    subject: "Player5",
                    logoUrl: "https://example.com/player5.png",
                    kills: 20,
                    deaths: 10,
                    assists: 5,
                    headshots: 11,
                    mvp: 3,
                    total: 39
                }
            ],
            teamBStats: [
                {
                    subject: "Player6",
                    logoUrl: "https://example.com/player6.png",
                    kills: 16,
                    deaths: 10,
                    assists: 5,
                    headshots: 8,
                    mvp: 2,
                    total: 32
                },
                {
                    subject: "Player7",
                    logoUrl: "https://example.com/player7.png",
                    kills: 14,
                    deaths: 9,
                    assists: 4,
                    headshots: 7,
                    mvp: 1,
                    total: 28
                },
                {
                    subject: "Player8",
                    logoUrl: "https://example.com/player8.png",
                    kills: 15,
                    deaths: 8,
                    assists: 6,
                    headshots: 8,
                    mvp: 2,
                    total: 31
                },
                {
                    subject: "Player9",
                    logoUrl: "https://example.com/player9.png",
                    kills: 18,
                    deaths: 7,
                    assists: 5,
                    headshots: 9,
                    mvp: 3,
                    total: 35
                },
                {
                    subject: "Player10",
                    logoUrl: "https://example.com/player10.png",
                    kills: 22,
                    deaths: 9,
                    assists: 8,
                    headshots: 12,
                    mvp: 4,
                    total: 44
                }
            ]
        }
    ]
};


const MatchPage: React.FC = () => {
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState<number>(0);
    const activeMapStats = statsData.maps[activeTab];
    return (
        <Container>
            <InDevelop>
                <div className='match_header'>{statsData.tournamentName}</div>
                <div className='match_layer1'>
                    <div className='match_layer1_team'>
                        <div className='match_layer1_team_header'>
                            КОМАНДА 1
                        </div>
                        <div className='match_layer1_team_name' onClick={() => navigate(`/team/${statsData.teams.teamAId}`)}>
                            {statsData.teams.teamATag}
                        </div>
                    </div>
                    <div className='match_layer1_vs'>
                        <div className='match_layer1_unit'>
                            <img className="tournament_versus_pic" src={`${process.env.REACT_APP_IMG_MINIO_URL}${statsData?.teamALogo}`} alt='' />
                            <div className='match_layer1_count'>{statsData.scoreA}</div>
                        </div>
                        <div className='match_layer1_unit vs'>
                            <div className='tournament_versus_part2 match'>
                                VS
                            </div>
                            <div className='match_layer1_bo'>{statsData.tournamentType}</div>
                        </div>
                        <div className='match_layer1_unit'>
                            <img className="tournament_versus_pic" src={`${process.env.REACT_APP_IMG_MINIO_URL}${statsData?.teamBLogo}`} alt='' />
                            <div className='match_layer1_count'>{statsData.scoreB}</div>
                        </div>
                    </div>
                    <div className='match_layer1_team'>
                        <div className='match_layer1_team_header'>
                            КОМАНДА 2
                        </div>
                        <div className='match_layer1_team_name' onClick={() => navigate(`/team/${statsData.teams.teamBId}`)}>
                            {statsData.teams.teamBTag}
                        </div>
                    </div>
                </div>
                <div className='match_layer2'>
                    <div className='match_tabs'>
                        {statsData.maps.map((mapStats, index) => (
                            <button
                                key={index}
                                className={`matchtab ${activeTab === index ? 'active' : ''}`}
                                onClick={() => setActiveTab(index)}
                            >
                                КАРТА {index + 1}
                            </button>
                        ))}
                    </div>
                    <div className='matchtab_content'>
                        <div className='matchtab_content_header'>
                            <div className='matchtab_content_header_unit'>КАРТА: <span>{activeMapStats.mapName}</span></div>
                            <div className='matchtab_content_header_unit two'><span>{activeMapStats.matchDuration}</span></div>
                        </div>
                        <div className='matchtab_content_layer'>
                            <div className='matchtab_content_layer_team'>
                                <div className='matchtab_content_team'>
                                    <div className='matchtab_content_team_name' onClick={() => navigate(`/team/${statsData.teams.teamAId}`)}>
                                        {statsData.teams.teamATag}
                                    </div>
                                    <div className={`matchtab_content_team_status ${activeMapStats.mapScoreA > activeMapStats.mapScoreB ? '' : 'loss'}`}>
                                        {activeMapStats.mapScoreA > activeMapStats.mapScoreB ? 'ПОБЕДА' : 'ПОРАЖЕНИЕ'}
                                    </div>
                                    <div className='matchtab_content_team_count'>
                                        {activeMapStats.mapScoreA}
                                    </div>
                                </div>
                                <div className='matchtab_table'>
                                    <PlayerStatTable data={activeMapStats.teamAStats} isMatchPage={true} />
                                </div>
                            </div>
                            <div className='matchtab_content_layer_team'>
                                <div className='matchtab_content_team'>
                                    <div className='matchtab_content_team_name' onClick={() => navigate(`/team/${statsData.teams.teamBId}`)}>
                                        {statsData.teams.teamBTag}
                                    </div>
                                    <div className={`matchtab_content_team_status ${activeMapStats.mapScoreA < activeMapStats.mapScoreB ? '' : 'loss'}`}>
                                        {activeMapStats.mapScoreA < activeMapStats.mapScoreB ? 'ПОБЕДА' : 'ПОРАЖЕНИЕ'}
                                    </div>
                                    <div className='matchtab_content_team_count'>
                                        {activeMapStats.mapScoreB}
                                    </div>
                                </div>
                                <div className='matchtab_table'>
                                    <PlayerStatTable data={activeMapStats.teamBStats} isMatchPage={true} />
                                </div>
                            </div>
                            {activeMapStats.streamUrl && (
                                <div className='matchstream_button' >
                                    <button className='matchtab' onClick={() => window.open(activeMapStats.streamUrl, "_blank")}>
                                        СМОТРЕТЬ ТРАНСЛЯЦИЮ
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </InDevelop>
        </Container>
    );
};

export default MatchPage;