import React from 'react';
import './InDevelop.css';

interface InDevelopProps {
    children: React.ReactNode;
}

const InDevelop: React.FC<InDevelopProps> = ({ children }) => {
    return (
        <div className="inDevelop_container">
            <div className="inDevelop_blur">
                {children}
            </div>
            <div className="inDevelop_title">В разработке</div>
        </div>
    );
};

export default InDevelop;
