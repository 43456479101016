import React, { useEffect, useState } from 'react';
import './CS2LastActivityStatistics.css';

import defaultImg from '../../assets/img/mapsWithLogo/cs2_vertigoWithLogo.jpg';
import defaultImg2 from '../../assets/img/tournaments_avatars/tournament_avatar1.jpg';
import { MatchResultEnum } from '../../models/enums/MatchResultEnum';
import { ICS2LastTournamentStatistics } from '../../models/dto/stats/cs2/playerStats/ICS2LastTournamentStatistics';
import { ICS2LastMatchStatistics } from '../../models/dto/stats/cs2/playerStats/ICS2LastMatchStatistics';

interface ICS2LastActivityStatisticsProps {
    playerId: string | null;
    statType: 'match' | 'tournament';
}

const CS2LastActivityStatistics: React.FC<ICS2LastActivityStatisticsProps> = ({ playerId, statType }) => {
    const [playerStatData, setPlayerStatData] = useState<ICS2LastTournamentStatistics | ICS2LastMatchStatistics>()
    
    useEffect(() => {
        // TODO:
        // if (statType === 'Match') {
        //     data = getLastPlayerMatchStat(playerId);
        // } else {
        //     data = getLastPlayerTournamentStatistic(playerId);
        // }
        
        if (statType === 'match') {
            setPlayerStatData({
                img: defaultImg,
                date: '01.09.2024',
                result: MatchResultEnum.Victory,
                kda: '4,25',
                adr: '219',
                mvp: '9',
                kills: '23',
                death: '10',
                assists: '11',
                teamOneName: 'SyberChesse',
                teamTwoName: 'QWERTY',
            });
        } else if (statType === 'tournament') {
            setPlayerStatData({
                img: defaultImg2,
                date: '25.08.2024',
                result: 3,
                kda: '4,25',
                adr: '219',
                mvp: '9',
                kills: '23',
                death: '10',
                assists: '11',
                tournamentTitle: 'Газпром First Cup',
            });
        }
    }, [playerId, statType]);
    
    const formatDate = (dateStr: string | null) => {
        if (dateStr) {
            const date = new Date(dateStr);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        }
    }
    useEffect(() => {
        formatDate(playerStatData?.date || null);
    }, [playerId, playerStatData?.date]);
    
    return (
        <div className="lastActivityStatistics_container">
            <div className='lastActivityStatistics_firstBlock'>
                <div className='lastActivityStatistics_firstBlock_imgContainer'>
                    <img className='lastActivityStatistics_firstBlock_img' src={playerStatData?.img} alt={playerStatData?.img} />
                    
                    <div className='lastActivityStatistics_firstBlock_resultBlock'>
                        <div className='lastActivityStatistics_firstBlock_result date'>{playerStatData?.date}</div>
                        <div
                            className={`lastActivityStatistics_firstBlock_result result ${playerStatData?.result === MatchResultEnum.Victory ? 'win' : playerStatData?.result === MatchResultEnum.Defeat ? 'loss' : ''}`}
                        >
                            {statType === 'match' ? (
                                <>
                                    {playerStatData?.result === MatchResultEnum.Victory ? (
                                        <>ПОБЕДА</>
                                    ) : playerStatData?.result === MatchResultEnum.Defeat ? (
                                        <>ПОРАЖЕНИЕ</>
                                    ) : (
                                        <>НИЧЬЯ</>
                                    )}
                                </>
                            ) : (
                                <>{playerStatData?.result} МЕСТО</>
                            )}
                        </div>
                    </div>
                </div>
                
                <div className='lastActivityStatistics_table'>
                    <div className='lastActivityStatistics_table_matchOrTournamentTitle'>
                        {statType === 'tournament' && playerStatData && 'tournamentTitle' in playerStatData ? (
                            <div className='lastActivityStatistics_table_tournamemtTitle'>
                                {playerStatData.tournamentTitle}
                            </div>
                        ) : (
                            playerStatData && 'teamOneName' in playerStatData && 'teamTwoName' in playerStatData && (
                                <div className='lastActivityStatistics_table_matchTitleContainer'>
                                    <div className='lastActivityStatistics_table_matchTitle'>
                                        {playerStatData.teamOneName}
                                    </div>
                                    <span>VS</span>
                                    <div className='lastActivityStatistics_table_matchTitle'>
                                        {playerStatData.teamTwoName}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                    
                    <table>
                        <thead>
                            <tr>
                                <th className='lastActivityStatistics_table_th'>KDA</th>
                                <th className='lastActivityStatistics_table_th center'>ADR</th>
                                <th className='lastActivityStatistics_table_th'>MVP</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='lastActivityStatistics_table_td'>{playerStatData?.kda}</td>
                                <td className='lastActivityStatistics_table_td center'>{playerStatData?.adr}</td>
                                <td className='lastActivityStatistics_table_td'>{playerStatData?.mvp}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div className='lastActivityStatistics_secondBlock'>                <div className='lastActivityStatistics_secondBlock_title'>СТАТИСТИКА ПОСЛЕДНЕГО {statType === 'match' ? 'МАТЧА' : 'ТУРНИРА'}</div>
                <div className='lastActivityStatistics_secondBlock_circles'>
                    <div className='lastActivityStatistics_secondBlock_circleContainer'>
                        <div className='lastActivityStatistics_secondBlock_circle'>
                            {playerStatData?.kills}
                        </div>
                        <div className='lastActivityStatistics_secondBlock_circleTitle kills'>K</div>
                    </div>
                    
                    <div className='lastActivityStatistics_secondBlock_circleContainer'>
                        <div className='lastActivityStatistics_secondBlock_circle death'>
                            {playerStatData?.death}
                        </div>
                        <div className='lastActivityStatistics_secondBlock_circleTitle'>D</div>
                    </div>
                    
                    <div className='lastActivityStatistics_secondBlock_circleContainer'>
                        <div className='lastActivityStatistics_secondBlock_circle sapport'>
                            {playerStatData?.assists}
                        </div>
                        <div className='lastActivityStatistics_secondBlock_circleTitle'>A</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CS2LastActivityStatistics;