import React, { Component, PureComponent } from 'react';
import '../../Styles/Bracket.css';

const defaultAnchor = { x: 0.5, y: 0.5 };
const defaultBorderColor = '#7a7a80';
const defaultBorderStyle = 'solid';
const defaultBorderWidth = 1;

export interface SteppedLineToProps extends LineToProps {
    orientation?: 'h' | 'v';
}

export interface BaseProps {
    isActive?: boolean;
    borderColor?: string;
    borderStyle?: 'solid' | 'dashed' | 'dotted';
    borderWidth?: number;
    className?: string;
    zIndex?: number;
    within?: string;
}

export interface LineToProps extends BaseProps {
    from: string;
    to: string;
    fromAnchor?: string;
    toAnchor?: string;
    delay?: number | boolean;
}

export interface LineProps extends BaseProps {
    x0: number;
    y0: number;
    x1: number;
    y1: number;
}

export interface SteppedLineProps extends LineProps {
    orientation?: 'h' | 'v';
    isActive?: boolean;
}

export class Line extends PureComponent<LineProps> {
    private within: HTMLElement | null = null;
    private el: HTMLDivElement | null = null;
    
    componentDidMount() {
        if (this.within && this.el) {
            this.within.appendChild(this.el);
        }
    }
    
    componentWillUnmount() {
        if (this.within && this.el) {
            this.within.removeChild(this.el);
        }
    }
    
    findElement(className: string) {
        return document.getElementsByClassName(className)[0];
    }
    
    render() {
        const { x0, y0, x1, y1, within = 'elimination_round' } = this.props;
        
        this.within = this.findElement(within) as HTMLElement;
        
        const dy = y1 - y0;
        const dx = x1 - x0;
        
        const angle = Math.atan2(dy, dx) * 180 / Math.PI;
        const length = Math.sqrt(dx * dx + dy * dy);
        
        const positionStyle: React.CSSProperties = {
            position: 'absolute',
            top: `${y0}px`,
            left: `${x0}px`,
            width: `${length}px`,
            zIndex: Number.isFinite(this.props.zIndex) ? String(this.props.zIndex) : '1',
            transform: `rotate(${angle}deg)`,
            transformOrigin: '0 0',
        };
        
        const defaultStyle: React.CSSProperties = {
            borderTopColor: this.props.borderColor || defaultBorderColor,
            borderTopStyle: this.props.borderStyle || defaultBorderStyle,
            borderTopWidth: this.props.borderWidth || defaultBorderWidth,
        };
        
        const props = {
            className: this.props.className,
            style: { ...defaultStyle, ...positionStyle },
        };
        
        return (
            <div className="react-lineto-placeholder">
                <div
                    ref={(el) => { this.el = el; }}
                    {...props}
                />
            </div>
        );
    }
}

export default class SteppedLineTo extends Component<SteppedLineToProps> {
    private fromAnchor = defaultAnchor;
    private toAnchor = defaultAnchor;
    private delay?: number;
    private t?: number;
    
    parseAnchor(value?: string) {
        if (!value) {
            return defaultAnchor;
        }
        const parts = value.split(' ');
        if (parts.length > 2) {
            throw new Error('LinkTo anchor format is "<x> <y>"');
        }
        const [x, y] = parts;
        return {
            ...defaultAnchor,
            x: x ? (this.parseAnchorText(x)?.x ?? this.parseAnchorPercent(x)) : defaultAnchor.x,
            y: y ? (this.parseAnchorText(y)?.y ?? this.parseAnchorPercent(y)) : defaultAnchor.y,
        };
    }
    
    parseAnchorText(value: string) {
        switch (value) {
            case 'top': return { y: 0 };
            case 'left': return { x: 0 };
            case 'middle': return { y: 0.5 };
            case 'center': return { x: 0.5 };
            case 'bottom': return { y: 1 };
            case 'right': return { x: 1 };
            default: return null;
        }
    }
    
    parseAnchorPercent(value: string) {
        const percent = parseFloat(value) / 100;
        if (isNaN(percent) || !isFinite(percent)) {
            throw new Error(`LinkTo could not parse percent value "${value}"`);
        }
        return percent;
    }
    
    parseDelay(value?: number | boolean) {
        if (value === undefined) {
            return undefined;
        } else if (typeof value === 'boolean' && value) {
            return 0;
        }
        const delay = parseInt(value as unknown as string, 10);
        if (isNaN(delay) || !isFinite(delay)) {
            throw new Error(`LinkTo could not parse delay attribute "${value}"`);
        }
        return delay;
    }
    
    findElement(className: string) {
        return document.getElementsByClassName(className)[0];
    }
    
    detect() {
        const { from, to, within = 'elimination_round' } = this.props;
        
        const a = this.findElement(from);
        const b = this.findElement(to);
        
        if (!a || !b) {
            return null;
        }
        
        const anchor0 = this.fromAnchor;
        const anchor1 = this.toAnchor;
        
        const box0 = a.getBoundingClientRect();
        const box1 = b.getBoundingClientRect();
        
        let offsetX = window.pageXOffset;
        let offsetY = window.pageYOffset;
        
        if (within) {
            const p = this.findElement(within);
            const boxp = p.getBoundingClientRect();
            
            offsetX -= boxp.left + (window.pageXOffset || document.documentElement.scrollLeft) - p.scrollLeft;
            offsetY -= boxp.top + (window.pageYOffset || document.documentElement.scrollTop) - p.scrollTop;
        }
        
        const x0 = box0.left + box0.width * anchor0.x + offsetX;
        const x1 = box1.left + box1.width * anchor1.x + offsetX;
        const y0 = box0.top + box0.height * anchor0.y + offsetY;
        const y1 = box1.top + box1.height * anchor1.y + offsetY;
        
        return { x0, y0, x1, y1 };
    }
    
    deferUpdate(delay: number) {
        if (this.t) {
            clearTimeout(this.t);
        }
        this.t = window.setTimeout(() => this.forceUpdate(), delay);
    }
    
    constructor(props: SteppedLineToProps) {
        super(props);
        this.fromAnchor = this.parseAnchor(props.fromAnchor);
        this.toAnchor = this.parseAnchor(props.toAnchor);
        this.delay = this.parseDelay(props.delay);
    }
    
    componentDidMount() {
        this.delay = this.parseDelay(this.props.delay);
        if (typeof this.delay !== 'undefined') {
            this.deferUpdate(this.delay);
        }
    }
    
    componentDidUpdate(prevProps: SteppedLineToProps) {
        if (prevProps.fromAnchor !== this.props.fromAnchor) {
            this.fromAnchor = this.parseAnchor(this.props.fromAnchor);
        }
        if (prevProps.toAnchor !== this.props.toAnchor) {
            this.toAnchor = this.parseAnchor(this.props.toAnchor);
        }
        this.delay = this.parseDelay(this.props.delay);
        if (typeof this.delay !== 'undefined') {
            this.deferUpdate(this.delay);
        }
    }
    
    componentWillUnmount() {
        if (this.t) {
            clearTimeout(this.t);
            this.t = null!;
        }
    }
    
    shouldComponentUpdate() {
        return true;
    }
    
    render() {
        const points = this.detect();
        return points ? (
            <SteppedLine {...points} {...this.props} />
        ) : null;
    }
}

export class SteppedLine extends PureComponent<SteppedLineProps> {
    render() {
        if (this.props.orientation === 'h') {
            return this.renderHorizontal();
        }
        return this.renderVertical();
    }
    
    renderVertical() {
        const x0 = Math.round(this.props.x0);
        const y0 = Math.round(this.props.y0);
        const x1 = Math.round(this.props.x1);
        const y1 = Math.round(this.props.y1);
        
        const dx = x1 - x0;
        if (Math.abs(dx) <= 1) {
            return <Line {...this.props} {...{ x0, y0, x1: x0, y1 }} />;
        }
        if (dx === 0) {
            return <Line {...this.props} />
        }
        
        const borderWidth = this.props.borderWidth || defaultBorderWidth;
        const y2 = Math.round((y0 + y1) / 2);
        
        const xOffset = dx > 0 ? borderWidth : 0;
        const minX = Math.min(x0, x1) - xOffset;
        const maxX = Math.max(x0, x1);
        
        return (
            <div className="react-steppedlineto">
                <Line className='steppedline' {...this.props} x0={x0} y0={y0} x1={x0} y1={y2} />
                <Line className='steppedline' {...this.props} x0={x1} y0={y1} x1={x1} y1={y2} />
                <Line className='steppedline' {...this.props} x0={minX} y0={y2} x1={maxX} y1={y2} />
            </div>
        );
    }
    
    renderHorizontal() {
        const x0 = Math.round(this.props.x0);
        const y0 = Math.round(this.props.y0);
        const x1 = Math.round(this.props.x1);
        const y1 = Math.round(this.props.y1);
        
        const dy = y1 - y0;
        if (Math.abs(dy) <= 1) {
            return <Line {...this.props} {...{ x0, y0, x1, y1: y0 }} />;
        }
        
        if (dy === 0) {
            return <Line {...this.props} />;
        }
        
        const borderWidth = this.props.borderWidth || defaultBorderWidth;
        const x2 = Math.round((x0 + x1) / 2);
        
        const yOffset = dy < 0 ? borderWidth : 0;
        const minY = Math.min(y0, y1) - yOffset;
        const maxY = Math.max(y0, y1);
        
        return (
            <div className="react-steppedlineto">
                <Line className='steppedline' {...this.props} x0={x0} y0={y0} x1={x2} y1={y0} />
                <Line className='steppedline' {...this.props} x0={x1} y0={y1} x1={x2} y1={y1} />
                <Line className='steppedline' {...this.props} x0={x2} y0={minY} x1={x2} y1={maxY} />
            </div>
        );
    }
}