import { action, makeObservable, observable } from 'mobx'; 
import { IPartnerStoreModel } from '../models/dto/partner/IPartnerStoreModel'; 
import { userRoles } from '../helpers/constants/userRoles';
import User from './User';

class PartnerStore extends User {
    
    partnerId: string | null = null;
    statusId: string | null = null;
    
    name: string | null = null;
    description: string | null = null;
    inn: string | null = null;
    ogrn: string | null = null;
    orgName: string | null = null;
    legalEntity: string | null = null;
    contactPerson: string | null = null;
    contactPhone: string | null = null;
    contactMail: string | null = null;
    logoUrl: string | null = null;
    socialMediaLinks: string | null = null;
    isPlatformPartner: boolean | null = null;

    constructor() {
        super();
        makeObservable(this, {
            partnerId: observable,
            statusId: observable,

            name: observable,
            description: observable,
            inn: observable,
            ogrn: observable,
            orgName: observable,
            legalEntity: observable,
            contactPerson: observable,
            contactPhone: observable,
            contactMail: observable,
            logoUrl: observable,
            socialMediaLinks: observable,
            isPlatformPartner: observable,

            loginPartner: action,
            logout: action
        });
    }
    
    loginPartner(partnerData: IPartnerStoreModel): void {
        this.userId = partnerData.userId;
        this.partnerId = partnerData.partnerId;
        this.statusId = partnerData.statusId;

        this.isAuth = true;
        this.isAdmin = partnerData.roles?.includes(userRoles.admin) ?? false;
        this.login = partnerData.login; 
        this.email = partnerData.email;
        
        this.name = partnerData.name;
        this.description = partnerData.description;
        this.inn = partnerData.inn;
        this.ogrn = partnerData.ogrn;
        this.orgName = partnerData.orgName;
        this.legalEntity = partnerData.legalEntity;
        this.contactPerson = partnerData.contactPerson;
        this.contactPhone = partnerData.contactPhone;
        this.contactMail = partnerData.contactMail;
        this.logoUrl = partnerData.logoUrl;
        this.socialMediaLinks = partnerData.socialMediaLinks;
        this.isPlatformPartner = partnerData.isPlatformPartner;
    }

    logout(): void {
        this.userId = null;
        this.partnerId = null;
        this.statusId = null;

        this.isAuth = false;
        this.isAdmin = false;
        this.login = null;
        this.email = null;

        this.name = null;
        this.description = null;
        this.inn = null;
        this.ogrn = null;
        this.orgName = null;
        this.legalEntity = null;
        this.contactPerson = null;
        this.contactPhone = null;
        this.contactMail = null;
        this.logoUrl = null;
        this.socialMediaLinks = null;
        this.isPlatformPartner = false;
    }

}

export default PartnerStore;