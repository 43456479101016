import React, { useContext, useEffect, useState } from 'react';
import { getActivePlayerLobbies } from '../../http/playerActionsAPI';
import { IMatchLobbyModel } from '../../models/dto/lobby/IMatchLobbyModel';
import { AppContext } from '../AppContext';
import CustomSpinner1 from '../CustomSpinners/CustomSpinner1';
import LobbyUnit from './LobbyUnit/LobbyUnit';
import './LobbiesCenter.css';

interface LobbiesCenterProps {
    isVisible: boolean;
}

const LobbiesCenter: React.FC<LobbiesCenterProps> = ({ isVisible }) => {
    const [lobbies, setLobbies] = useState<IMatchLobbyModel[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const { player } = useContext(AppContext);
    
    useEffect(() => {
        if (isVisible) {
            setIsFetching(true);
            getActivePlayerLobbies(player?.playerId ?? '')
                .then((fetchedLobbies) => {
                    if (fetchedLobbies) {
                        setLobbies(fetchedLobbies);
                    }
                })
                .finally(() => {
                    setIsFetching(false);
                });
        }
    }, [isVisible]);
    
    return (
        <div className={`notification_center ${isVisible ? 'visible' : 'hidden'}`}>
            {isFetching
                ? (<CustomSpinner1 size='100px' sizeSpinnerContainer='170px' />) 
                : (<>
                        <div className='notification_center_header'>Активные лобби</div>
                        <hr />
                        <div className='notification_units_container lobby'>
                            {lobbies.length > 0 ? (
                                lobbies.map((lobby) => (
                                    <LobbyUnit
                                        key={lobby.lobbyId}
                                        lobbyModel={lobby}
                                    />
                                    ))
                            ) : (
                                <p className='notification_units_empty'>Пока нет активных лобби.</p>
                            )}
                        </div>
                    </>
                )
            }
            
        </div>
    );
};

export default LobbiesCenter;