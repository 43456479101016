// В неве уже есть

const CommonConstants = class {
    static GuidEmpty: string = '00000000-0000-0000-0000-000000000000';
    
    static MatchLocked: string = 'a76e8b6e-ebbb-4741-9d24-947bc6cb3fbd';
    
    static MatchWaiting: string = '7f85d3f7-e0c2-48d4-9f27-cf5975d057e2';
    
    static MatchReady: string = '51f9b129-6edb-4c1e-bcaa-d18c18ee2db8';
    
    static MatchRunning: string = '0280edee-aa7f-4eab-8394-f88bce4049e0';
    
    static MatchCompleted: string = '6f78c7ac-9a2a-494e-99a8-d7eea9dad326';
}

export { CommonConstants }; 