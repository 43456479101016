import React, { useState, useEffect } from 'react';

interface TimeLeft {
    days?: number;
    hours?: number;
    minutes?: number;
}

type TimerProps = {
    regDateEnd: string | undefined;
    regDateStart: string | undefined; 
};

const Timer: React.FC<TimerProps> = ({ regDateEnd, regDateStart }) => {
    const end = regDateEnd ? new Date(regDateEnd).getTime() : null;
    const start = regDateStart ? new Date(regDateStart).getTime() : null;

    const calculateTimeLeft = (targetTime: number | null) => {
        if (!targetTime) return {};

        const difference = targetTime - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<TimeLeft>({ days: 0, hours: 0, minutes: 0 });
    const [status, setStatus] = useState<string>('');

    useEffect(() => {
        const timer = setInterval(() => {
            const now = +new Date();

            if (start && now < start) {
                setStatus('regBefore');
                setTimeLeft(calculateTimeLeft(start));
            } else if (end && now < end) {
                setStatus('regOngoing');
                setTimeLeft(calculateTimeLeft(end));
            } else {
                setStatus('regFinished');
                setTimeLeft({});
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [start, end]);

    const getPluralForm = (number: number, titles: [string, string, string]) => {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    };

    if (status === 'regFinished') {
        return <div className="tournament_cell_time_left">Завершена</div>;
    }

    return (
        <div className="tournament_cell_time_left">
            {status === 'regBefore' && (
                <div>
                    До начала: {timeLeft.days} {getPluralForm(timeLeft.days ?? 0, ['день', 'дня', 'дней'])}{' '}
                    {timeLeft.hours} {getPluralForm(timeLeft.hours ?? 0, ['час', 'часа', 'часов'])}{' '}
                    {timeLeft.minutes} {getPluralForm(timeLeft.minutes ?? 0, ['минута', 'минуты', 'минут'])}
                </div>
            )}
            {status === 'regOngoing' && (
                <div>
                    До конца: {timeLeft.days} {getPluralForm(timeLeft.days ?? 0, ['день', 'дня', 'дней'])}{' '}
                    {timeLeft.hours} {getPluralForm(timeLeft.hours ?? 0, ['час', 'часа', 'часов'])}{' '}
                    {timeLeft.minutes} {getPluralForm(timeLeft.minutes ?? 0, ['минута', 'минуты', 'минут'])}
                </div>
            )}
        </div>
    );
};

export default Timer;
