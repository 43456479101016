export enum CS2WeaponsEnum {
    // Пистолеты
    DesertEagle = 0,                // Desert Eagle
    R8Revolver = 1,                 // R8 Revolver
    TEC9 = 2,                       // TEC-9
    P250 = 3,                       // P250
    Glock18 = 4,                    // Glock-18
    P2000 = 5,                      // P2000 (P2K)
    DualBerettas = 6,               // Dual Berettas
    FiveSeven = 7,                  // Five-SeveN
    CZ75Auto = 8,                   // CZ75-Auto
    USPS = 9,                       // USP-S
    
    // Пистолеты-пулеметы
    MAC10 = 10,                     // MAC-10
    MP5SD = 11,                     // MP5-SD
    PPBizon = 12,                   // PP-Bizon
    MP9 = 13,                       // MP9
    UMP45 = 14,                     // UMP-45
    P90 = 15,                       // P90
    MP7 = 16,                       // MP7
    
    // Дробовики
    XM1014 = 17,                    // XM1014
    MAG7 = 18,                      // MAG-7
    SawedOffShotgun = 19,           // Sawed-Off Shotgun
    Nova = 20,                      // Nova
    
    // Тяжелое оружие
    M249 = 21,                      // M249
    Negev = 22,                     // Negev
    
    // Винтовки
    AK47 = 23,                      // AK-47
    FAMAS = 24,                     // FAMAS
    AUG = 25,                       // AUG
    GalilAR = 26,                   // Galil AR
    M4A1S = 27,                     // Silenced M4
    M4A4 = 28,                      // Silenced M4A4
    SG553 = 29,                     // SG 553
    
    // Снайперские винтовки
    SCAR20 = 30,                    // CT Auto Sniper
    SSG08 = 31,                     // Scout Sniper
    AWP = 32,                       // AWP
    G3SG1 = 33,                     // Terrorist Auto Sniper
    
    // Разное
    KnifeT = 34,                    // T Knife (Default)
    Knife = 35,                     // Knife
    Zeus = 36,                      // Taser
    Bomb = 37,                      // C4
    
    // Гранаты
    HighExplosiveGrenade = 38,      // High Explosive Grenade
    MolotovGrenade = 39,            // Molotov Grenade
    IncendiaryGrenade = 40,         // Incendiary Grenade
    SmokeGrenade = 41,              // Smoke Grenade
    FlashbangGrenade = 42,          // Flashbang Grenade
    FakeGrenade = 43,               // Fake Grenade
}