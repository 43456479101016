import React, { useContext, useEffect, useRef, useState } from 'react';
import './Registration.css';
import { Container, FloatingLabel, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IPlayerRegistrationViewModel } from '../../viewModels/registration/IPlayerRegistrationViewModel';
import { createOrg, createPlayer } from '../../http/userAPI';
import { IPlayerStoreModel } from '../../models/dto/player/IPlayerStoreModel';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../components/AppContext';
import moment from 'moment-timezone';
import { IOrgRegistrationViewModel } from '../../viewModels/registration/IOrgRegistrationViewModel';
import axios from 'axios';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { DatePicker, DateRangePicker } from 'rsuite';
import { IPartnerStoreModel } from '../../models/dto/partner/IPartnerStoreModel';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';
import ButtonWithLoader from '../../components/Buttons/ButtonWithLoader/ButtonWithLoader';


const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Token ${process.env.REACT_APP_INN_API_KEY}`,
};
const avatars = [
    'UserAvatar1.png', 'UserAvatar2.png', 'UserAvatar3.png', 'UserAvatar4.png', 'UserAvatar5.png', 'UserAvatar6.png', 'UserAvatar7.png', 'UserAvatar8.png',
    'UserAvatar10.png', 'UserAvatar11.png', 'UserAvatar12.png', 'UserAvatar13.png', 'UserAvatar14.png', 'UserAvatar15.png'
];

const avatarsOrg = [
    'PartnerAvatar1.png', 'PartnerAvatar2.png', 'PartnerAvatar3.png'
];

const Registration: React.FC = () => {
    const { userId, code } = useParams();
    const navigate = useNavigate();
    const { player, partner } = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
    const [registrationType, setRegistrationType] = useState<'player' | 'organizer' | null>(null);
    const [validated, setValidated] = useState(false);
    const [randomAvatarIdx, setRandomAvatarIdx] = useState(0);
    const [currentAvatar, setCurrentAvatar] = useState<string>(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [formDataOrg, setFormDataOrg] = useState<IOrgRegistrationViewModel>({
        userId: userId ?? '',
        name: '',
        description: '',
        inn: '',
        ogrn: '',
        orgName: '',
        creationDate: moment().format('YYYY-MM-DD'),
        legalEntity: '',
        contactPerson: '',
        contactPhone: '',
        contactMail: '',
        logoFile: undefined,
        logoStringURL: undefined,
    });

    const [formDataPlayer, setFormDataPlayer] = useState<IPlayerRegistrationViewModel>({
        userId: userId ?? '',
        name: '',
        surname: '',
        middlename: '',
        birthDate: '',
        avatarFile: undefined,
        avatarStringURL: undefined,
        gender: undefined,
        city: ''
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}api/Auth/ConfirmEmail?userId=${userId}&code=${code}`)
            .then(response => {
                setLoading(false);
                setMessage(response.data.message);
                if (response.data.success) {
                    setMessage("Электронная почта подтверждена. Выберите тип регистрации");
                    setIsEmailConfirmed(true);
                    localStorage.setItem('currentUserId', userId ?? "");
                }
            })
            .catch(error => {
                setLoading(false);
                setMessage('ОШИБКА ПОДТВЕРЖДЕНИЯ ЭЛЕКТРОННОЙ ПОЧТЫ');
            });
    }, [userId, code]);

    const handleRegistrationTypeChange = (type: 'player' | 'organizer') => {
        setRegistrationType(type);
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        formDataObject: any,
        setFormDataFunction: React.Dispatch<React.SetStateAction<any>>
    ) => {
        const { name, value } = e.target;
        setFormDataFunction({ ...formDataObject, [name]: value });

        if (name === "inn" && value.length >= 10) {
            axios
                .post(process.env.REACT_APP_DADATA_URL, { query: value }, { headers })
                .then((response) => {
                    if (response.data.suggestions && response.data.suggestions.length > 0) {
                        const suggestionData = response.data.suggestions[0].data;
                        const { name, inn, ogrn, phones, emails, opf } = suggestionData;
                        setFormDataOrg({
                            ...formDataOrg,
                            orgName: name.full_with_opf,
                            inn: inn,
                            ogrn: ogrn,
                            legalEntity: opf.full,
                            contactPerson: '',
                            contactPhone: phones,
                            contactMail: emails,
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error fetching by inn:", error);
                });
        }
    };

    const handleDateChange = (date: Date | null) => {
        if (date && !isNaN(date.getTime())) {
            const value = date.toISOString();
            setFormDataPlayer(prev => ({
                ...prev,
                birthDate: value,
            }));
        } else {
            setFormDataPlayer(prev => ({
                ...prev,
                birthDate: '',
            }));
        }
    };

    const handleFileChangePlayer = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (file) {
                setFormDataPlayer(prev => ({ ...prev, avatarFile: file }));
                const imageUrl = URL.createObjectURL(file);
                setCurrentAvatar(imageUrl);
            }
        }
    };

    const handleFileChangeOrg = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setFormDataOrg(prev => ({ ...prev, logoFile: file }));
        }
    };

    const handleCreatePlayer = async (e: React.FormEvent) => {
        e.preventDefault();
        setValidated(true);
        setIsButtonLoading(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            return;
        }

        const formData = new FormData();
        if (formDataPlayer.avatarFile) {
            formData.append('avatarFile', formDataPlayer.avatarFile);
        } else {
            formData.append('avatarStringURL', avatars[randomAvatarIdx]);
        }

        formData.append('userId', formDataPlayer.userId || '');
        formData.append('name', formDataPlayer.name);
        formData.append('surname', formDataPlayer.surname || '');
        formData.append('middlename', formDataPlayer.middlename || '');
        formData.append('birthDate', formDataPlayer.birthDate);
        formData.append('city', formDataPlayer.city);
        if (formDataPlayer.gender !== undefined) {
            formData.append('gender', formDataPlayer.gender.toString());
        }
        const result = await createPlayer(formData);

        if (result && result != null) {
            setIsButtonLoading(false);
            const playerData: IPlayerStoreModel = result;
            player?.loginPlayer(playerData);

            if (player?.isAuth) {
                navigate(`/player/${player?.playerId}`);
            }
        }
    };

    const handleCreateOrg = async (e: React.FormEvent) => {
        e.preventDefault();
        setValidated(true);
        setIsButtonLoading(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            return;
        }
        const formData = new FormData();
        if (formDataOrg.logoFile) {
            formData.append('logoFile', formDataOrg.logoFile);
        } else {
            formData.append('logoStringURL', avatarsOrg[randomAvatarIdx]);
        }
        formData.append('userId', formDataOrg.userId || '');
        formData.append('name', formDataOrg.name);
        formData.append('description', formDataOrg.description || '');
        formData.append('inn', formDataOrg.inn);
        formData.append('ogrn', formDataOrg.ogrn);
        formData.append('orgName', formDataOrg.orgName);
        formData.append('creationDate', formDataOrg.creationDate || '');
        formData.append('legalEntity', formDataOrg.legalEntity);
        formData.append('contactPerson', formDataOrg.contactPerson);
        formData.append('contactPhone', formDataOrg.contactPhone);
        formData.append('contactMail', formDataOrg.contactMail);

        const result = await createOrg(formData);

        if (result && result != null) {
            setIsButtonLoading(false);
            const orgData: IPartnerStoreModel = result;
            partner?.loginPartner(orgData);
            if (partner?.isAuth) {
                navigate(`/partner/${partner?.partnerId}`);
            }
        }
    };

    const rotateRight = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setRandomAvatarIdx((prev) => (prev + 1) % avatars.length);
        setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            setFormDataPlayer(prev => ({ ...prev, avatarFile: undefined }));
        }
    };

    const rotateRightOrg = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setRandomAvatarIdx((prev) => (prev + 1) % avatarsOrg.length);
        setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatarsOrg[randomAvatarIdx]}`);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            setFormDataOrg(prev => ({ ...prev, logoFile: undefined }));
        }
    };

    if (loading) {
        return (
            <div className='confirm_mail_container'>
                <div className='confirm-mail-letters'>ЗАГРУЗКА...</div>
                <CustomSpinner1 />
            </div>
        );
    }

    if (!isEmailConfirmed) {
        return (
            <div className='confirm_mail_container'>
                <p className='confirm-mail-letters'>{message}</p>
            </div>
        );
    }

    return (
        <Container>
            <div>
                <div className='confirm-mail-letters'>{message}</div>
            </div>
            {registrationType === null && (
                <div className="registration_container">
                    <div className="registration_choose">ЗАРЕГИСТРИРОВАТЬСЯ КАК</div>
                    <div className="registration_boxes_container">
                        <div className="registration_box" onClick={() => handleRegistrationTypeChange('player')}>ИГРОК</div>
                        <div className="registration_box" onClick={() => handleRegistrationTypeChange('organizer')}>ПАРТНЁР</div>
                    </div>
                </div>
            )}
            {registrationType === 'player' && (
                <div className='registration_form_container'>
                    <div className="registration_header">Регистрация Игрока</div>
                    <Form className="registration_form" noValidate validated={validated}>
                        <FloatingLabel label="Имя" className='reg_floatlabel'>
                            <Form.Control
                                required
                                name="name"
                                value={formDataPlayer.name}
                                placeholder="Имя..."
                                onChange={(e) => handleChange(e, formDataPlayer, setFormDataPlayer)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Фамилия" className='reg_floatlabel'>
                            <Form.Control
                                required
                                name="surname"
                                value={formDataPlayer.surname}
                                placeholder="Фамилия..."
                                onChange={(e) => handleChange(e, formDataPlayer, setFormDataPlayer)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Отчество" className='reg_floatlabel'>
                            <Form.Control
                                name="middlename"
                                value={formDataPlayer.middlename}
                                placeholder="Отчество..."
                                onChange={(e) => handleChange(e, formDataPlayer, setFormDataPlayer)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Город" className='reg_floatlabel'>
                            <Form.Control
                                name="city"
                                value={formDataPlayer.city}
                                placeholder="Город..."
                                onChange={(e) => handleChange(e, formDataPlayer, setFormDataPlayer)}
                            />
                        </FloatingLabel>
                        <DatePicker
                            className="reg_datepicker"
                            format="dd/MM/yyyy"
                            placeholder="Дата Рождения"
                            name="birthDate"
                            value={formDataPlayer.birthDate ? new Date(formDataPlayer.birthDate) : null}
                            onChange={handleDateChange}
                            shouldDisableDate={DateRangePicker.afterToday()}
                        />
                        <Form.Select className="select_reg" onChange={(e) => { setFormDataPlayer({ ...formDataPlayer, gender: e.target.value === "true" }) }}>
                            <option value="">Пол</option>
                            <option value="true">Мужчина</option>
                            <option value="false">Женщина</option>
                        </Form.Select>
                        <div className='download_avatar_container'>
                            <h3 className='download_avatar_title'>Загрузите или выберите аватар</h3>
                            <div className='download_avatar'>
                                <Form.Control
                                    ref={fileInputRef}
                                    name="avatarFile"
                                    type='file'
                                    placeholder="Аватар..."
                                    onChange={handleFileChangePlayer}
                                />
                                <div className='avatar_container'>
                                    <div className='avatar'>
                                        <img src={currentAvatar} alt="User Avatar" />
                                        <button className='avatar_button' onClick={rotateRight}><ArrowClockwise className='avatar_button_arrowIcon' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sign_in_button_container">
                            <ButtonWithLoader
                                isLoading={isButtonLoading}
                                disabled={isButtonLoading}
                                onClick={handleCreatePlayer}
                            >
                                ЗАРЕГИСТРИРОВАТЬСЯ
                            </ButtonWithLoader>
                        </div>
                    </Form>
                </div>
            )}
            {registrationType === 'organizer' && (
                <div className='registration_form_container'>
                    <div className="registration_header">Регистрация Партнёра</div>
                    <Form className="registration_form" noValidate validated={validated}>
                        <FloatingLabel label="ОТОБРАЖАЕМОЕ ИМЯ">
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip">Это имя будет отображаться на странице Партнёра</Tooltip>}
                            >
                                <Form.Control
                                    required
                                    name="name"
                                    value={formDataOrg.name}
                                    onChange={(e) => handleChange(e, formDataOrg, setFormDataOrg)}
                                />
                            </OverlayTrigger>
                        </FloatingLabel>
                        <FloatingLabel label="ОПИСАНИЕ ПАРТНЁРА">
                            <Form.Control
                                className="registration-description"
                                required
                                as="textarea"
                                name="description"
                                value={formDataOrg.description}
                                onChange={(e) => handleChange(e, formDataOrg, setFormDataOrg)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="ИНН ">
                            <Form.Control
                                required
                                type="number"
                                name="inn"
                                value={formDataOrg.inn}
                                onChange={(e) => handleChange(e, formDataOrg, setFormDataOrg)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="ОГРН">
                            <Form.Control
                                name="ogrn"
                                type="number"
                                value={formDataOrg.ogrn}
                                onChange={(e) => handleChange(e, formDataOrg, setFormDataOrg)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Полное Наименование Организации">
                            <Form.Control
                                required
                                name="orgName"
                                value={formDataOrg.orgName}
                                onChange={(e) => handleChange(e, formDataOrg, setFormDataOrg)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Вид Юридического Лица">
                            <Form.Control
                                name="legalEntity"
                                value={formDataOrg.legalEntity}
                                onChange={(e) => handleChange(e, formDataOrg, setFormDataOrg)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="ФИО Контактного Лица">
                            <Form.Control
                                required
                                name="contactPerson"
                                value={formDataOrg.contactPerson}
                                onChange={(e) => handleChange(e, formDataOrg, setFormDataOrg)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Номер телефона Контактного лица">
                            <Form.Control
                                required
                                type="number"
                                name="contactPhone"
                                value={formDataOrg.contactPhone}
                                onChange={(e) => handleChange(e, formDataOrg, setFormDataOrg)}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Электронная почта Контактного лица">
                            <Form.Control
                                required
                                name="contactMail"
                                value={formDataOrg.contactMail}
                                onChange={(e) => handleChange(e, formDataOrg, setFormDataOrg)}
                            />
                        </FloatingLabel>
                        <div className='download_avatar_container'>
                            <h3 className='download_avatar_title'>Загрузите или выберите аватар</h3>
                            <div className='download_avatar'>
                                <Form.Control
                                    ref={fileInputRef}
                                    name="logoFile"
                                    type='file'
                                    placeholder="Фото профиля..."
                                    onChange={handleFileChangeOrg}
                                />
                                <div className='avatar_container'>
                                    <div className='avatar'>
                                        <img src={`${process.env.REACT_APP_IMG_MINIO_URL}${avatarsOrg[randomAvatarIdx]}`} alt="Avatar" />
                                        <button className='avatar_button' onClick={rotateRightOrg}><ArrowClockwise className='avatar_button_arrowIcon' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sign_in_button_container">
                            <ButtonWithLoader
                                isLoading={isButtonLoading}
                                disabled={isButtonLoading}
                                onClick={handleCreateOrg}
                            >
                                ОТПРАВИТЬ ЗАЯВКУ НА РЕГИСТРАЦИЮ
                            </ButtonWithLoader>
                        </div>
                    </Form>
                </div>
            )}
        </Container>
    );
};

export default Registration;