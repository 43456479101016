import { CS2WeaponsEnum } from '../../models/enums/CS2WeaponsEnum';

class CS2WeaponConstants {
    public static DesertEagle = class {
        static weaponName: string = 'Desert Eagle';
        static weaponIcon: string = 'cs2_weapon_desertEagle.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.DesertEagle;
    };
    
    public static R8Revolver = class {
        static weaponName: string = 'R8 Revolver';
        static weaponIcon: string = 'cs2_weapon_r8Revolver.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.R8Revolver;
    };
    
    public static TEC9 = class {
        static weaponName: string = 'TEC-9';
        static weaponIcon: string = 'cs2_weapon_tec9.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.TEC9;
    };
    
    public static P250 = class {
        static weaponName: string = 'P250';
        static weaponIcon: string = 'cs2_weapon_p250.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.P250;
    };
    
    public static Glock18 = class {
        static weaponName: string = 'Glock-18';
        static weaponIcon: string = 'cs2_weapon_glock18.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.Glock18;
    };
    
    public static P2000 = class {
        static weaponName: string = 'P2000';
        static weaponIcon: string = 'cs2_weapon_p2000.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.P2000;
    };
    
    public static DualBerettas = class {
        static weaponName: string = 'Dual Berettas';
        static weaponIcon: string = 'cs2_weapon_dualBerettas.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.DualBerettas;
    };
    
    public static FiveSeven = class {
        static weaponName: string = 'Five-SeveN';
        static weaponIcon: string = 'cs2_weapon_fiveSeven.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.FiveSeven;
    };
    
    public static CZ75Auto = class { 
        static weaponName: string = 'CZ75-Auto';
        static weaponIcon: string = 'cs2_weapon_cz75Auto.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.CZ75Auto;
    };
    
    public static USPS = class {
        static weaponName: string = 'USP-S';
        static weaponIcon: string = 'cs2_weapon_usps.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.USPS;
    };
    
    public static MAC10 = class {
        static weaponName: string = 'MAC-10';
        static weaponIcon: string = 'cs2_weapon_mac10.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.MAC10;
    };
    
    public static MP5SD = class {
        static weaponName: string = 'MP5-SD';
        static weaponIcon: string = 'cs2_weapon_mp5sd.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.MP5SD;
    };
    
    public static PPBizon = class {
        static weaponName: string = 'PP-Bizon';
        static weaponIcon: string = 'cs2_weapon_ppBizon.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.PPBizon;
    };
    
    public static MP9 = class {
        static weaponName: string = 'MP9';
        static weaponIcon: string = 'cs2_weapon_mp9.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.MP9;
    };
    
    public static UMP45 = class {
        static weaponName: string = 'UMP-45';
        static weaponIcon: string = 'cs2_weapon_ump45.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.UMP45;
    };
    
    public static P90 = class {
        static weaponName: string = 'P90';
        static weaponIcon: string = 'cs2_weapon_p90.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.P90;
    };
    
    public static MP7 = class {
        static weaponName: string = 'MP7';
        static weaponIcon: string = 'cs2_weapon_mp7.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.MP7;
    };
    
    public static XM1014 = class {
        static weaponName: string = 'XM1014';
        static weaponIcon: string = 'cs2_weapon_xm1014.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.XM1014;
    };
    
    public static MAG7 = class {
        static weaponName: string = 'MAG-7';
        static weaponIcon: string = 'cs2_weapon_mag7.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.MAG7;
    };
    
    public static SawedOffShotgun = class {
        static weaponName: string = 'Sawed-Off';
        static weaponIcon: string = 'cs2_weapon_sawedOffShotgun.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.SawedOffShotgun;
    };
    
    public static Nova = class {
        static weaponName: string = 'Nova';
        static weaponIcon: string = 'cs2_weapon_nova.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.Nova;
    };
    
    public static M249 = class {
        static weaponName: string = 'M249';
        static weaponIcon: string = 'cs2_weapon_m249.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.M249;
    };
    
    public static Negev = class {
        static weaponName: string = 'Negev';
        static weaponIcon: string = 'cs2_weapon_negev.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.Negev;
    };
    
    public static AK47 = class {
        static weaponName: string = 'AK-47';
        static weaponIcon: string = 'cs2_weapon_ak47.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.AK47;
    };
    
    public static FAMAS = class {
        static weaponName: string = 'FAMAS';
        static weaponIcon: string = 'cs2_weapon_famas.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.FAMAS;
    };
    
    public static AUG = class {
        static weaponName: string = 'AUG';
        static weaponIcon: string = 'cs2_weapon_aug.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.AUG;
    };
    
    public static GalilAR = class {
        static weaponName: string = 'Galil AR';
        static weaponIcon: string = 'cs2_weapon_galilAR.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.GalilAR;
    };
    
    public static M4A1S = class {
        static weaponName: string = 'M4A1-S';
        static weaponIcon: string = 'cs2_weapon_m4a1s.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.M4A1S;
    };
    
    public static M4A4 = class {
        static weaponName: string = 'M4A4';
        static weaponIcon: string = 'cs2_weapon_m4a4.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.M4A4;
    };
    
    public static SG553 = class {
        static weaponName: string = 'SG 553';
        static weaponIcon: string = 'cs2_weapon_sg553.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.SG553;
    };
    
    public static SCAR20 = class {
        static weaponName: string = 'SCAR-20';
        static weaponIcon: string = 'cs2_weapon_scar20.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.SCAR20;
    };
    
    public static SSG08 = class {
        static weaponName: string = 'SSG 08';
        static weaponIcon: string = 'cs2_weapon_ssg08.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.SSG08;
    };
    
    public static AWP = class {
        static weaponName: string = 'AWP';
        static weaponIcon: string = 'cs2_weapon_awp.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.AWP;
    };
    
    public static G3SG1 = class {
        static weaponName: string = 'G3SG1';
        static weaponIcon: string = 'cs2_weapon_g3sg1.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.G3SG1;
    };
    
    public static KnifeT = class {
        static weaponName: string = 'T Knife';
        static weaponIcon: string = 'cs2_weapon_knifeT.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.KnifeT;
    };
    
    public static Knife = class {
        static weaponName: string = 'CT Knife';
        static weaponIcon: string = 'cs2_weapon_knife.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.Knife;
    };
    
    public static Bomb = class {
        static weaponName: string = 'C4';
        static weaponIcon: string = 'cs2_weapon_bomb.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.Bomb;
    };
    
    public static Zeus = class {
        static weaponName: string = 'Zeus';
        static weaponIcon: string = 'cs2_weapon_zeus.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.Zeus;
    };
    
    public static HighExplosiveGrenade = class {
        static weaponName: string = 'High Explosive Grenade';
        static weaponIcon: string = 'cs2_weapon_heGrenade.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.HighExplosiveGrenade;
    };
    
    public static MolotovGrenade = class {
        static weaponName: string = 'Molotov Grenade';
        static weaponIcon: string = 'cs2_weapon_molotovGrenade.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.MolotovGrenade;
    };
    
    public static IncendiaryGrenade = class {
        static weaponName: string = 'Incendiary Grenade';
        static weaponIcon: string = 'cs2_weapon_incendiaryGrenade.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.IncendiaryGrenade;
    };
    
    public static SmokeGrenade = class {
        static weaponName: string = 'Smoke Grenade';
        static weaponIcon: string = 'cs2_weapon_smokeGrenade.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.SmokeGrenade;
    };
    
    public static FlashbangGrenade = class {
        static weaponName: string = 'Flashbang Grenade';
        static weaponIcon: string = 'cs2_weapon_flashbangGrenade.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.FlashbangGrenade;
    };
    
    public static FakeGrenade = class {
        static weaponName: string = 'Fake Grenade';
        static weaponIcon: string = 'cs2_weapon_fakeGrenade.svg';
        static weaponEnumType: CS2WeaponsEnum = CS2WeaponsEnum.FakeGrenade;
    };
    
    public static Weapons = [
        this.DesertEagle,
        this.R8Revolver,
        this.TEC9,
        this.P250,
        this.Glock18,
        this.P2000,
        this.DualBerettas,
        this.FiveSeven,
        this.CZ75Auto,
        this.USPS,
        this.MAC10,
        this.MP5SD,
        this.PPBizon,
        this.MP9,
        this.UMP45,
        this.P90,
        this.MP7,
        this.XM1014,
        this.MAG7,
        this.SawedOffShotgun,
        this.Nova,
        this.M249,
        this.Negev,
        this.AK47,
        this.FAMAS,
        this.AUG,
        this.GalilAR,
        this.M4A1S,
        this.M4A4,
        this.SG553,
        this.SCAR20,
        this.SSG08,
        this.AWP,
        this.G3SG1,
        this.KnifeT,
        this.Knife,
        this.Zeus,
        // this.Bomb,
        // this.HighExplosiveGrenade,
        // this.MolotovGrenade,
        // this.IncendiaryGrenade,
        // this.SmokeGrenade,
        // this.FlashbangGrenade,
        // this.FakeGrenade
    ].map(w => ({
        weaponName: w.weaponName,
        weaponIcon: w.weaponIcon,
        weaponEnumType: w.weaponEnumType,
    }));
}

export default CS2WeaponConstants;
