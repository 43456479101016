import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { INewsModel } from '../../models/INewsModel';
import './NewsModalWindow.css';

interface NewsItemProps extends INewsModel {
    showModal: boolean;
    closeModal: () => void;
}

const NewsModalWindow: React.FC<NewsItemProps> = ({ title, description, imgSrc, showModal, closeModal }) => {
    const formattedDescription = description.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
    
    return (
        <Modal className='news_modal_container' show={showModal} onHide={closeModal}>
            <Modal.Body className='news_modal_body'>
                <div className='modal_window_title'>{title}</div>
                <div className='news_modal_description'>{formattedDescription}</div>
                <img src={imgSrc} alt='news_img' className='news_modal_imgSrc'/>
            </Modal.Body>
        </Modal>
    );
};

export default NewsModalWindow;