import { ICS2MapModel } from '../../models/dto/cs2/ICS2MapModel';

import ancientWithLogo from '../../assets/img/mapsWithLogo/cs2_ancientWithLogo.jpg';
import anubisWithLogo from '../../assets/img/mapsWithLogo/cs2_anubisWithLogo.jpg';
import infernoWithLogo from '../../assets/img/mapsWithLogo/cs2_infernoWithLogo.jpg';
import mirageWithLogo from '../../assets/img/mapsWithLogo/cs2_mirageWithLogo.jpg';
import nukeWithLogo from '../../assets/img/mapsWithLogo/cs2_nukeWithLogo.jpg';
import overpassWithLogo from '../../assets/img/mapsWithLogo/cs2_overpassWithLogo.jpg';
import vertigoWithLogo from '../../assets/img/mapsWithLogo/cs2_vertigoWithLogo.jpg';
import dust2WithLogo from '../../assets/img/mapsWithLogo/cs2_dust2WithLogo.jpg';

import ancient from '../../assets/img/maps/cs2_ancient.jpg';
import anubis from '../../assets/img/maps/cs2_anubis.jpg';
import inferno from '../../assets/img/maps/cs2_inferno.jpg';
import mirage from '../../assets/img/maps/cs2_mirage.jpg';
import nuke from '../../assets/img/maps/cs2_nuke.jpg';
import overpass from '../../assets/img/maps/cs2_overpass.jpg';
import vertigo from '../../assets/img/maps/cs2_vertigo.jpg';
import dust2 from '../../assets/img/maps/cs2_dust2.jpg';

class MapsConstants {
  public static Ancient: ICS2MapModel = {
    mapId: '0f333da5-0ea3-46b1-93ac-4955c6f21ffe',
    name: 'Ancient',
    mapImgUrl: ancient,
    isCompetitive: true,
    mapImgUrl_withLogo: ancientWithLogo
  };
  
  public static Anubis: ICS2MapModel = {
    mapId: 'a78c4871-ec79-4c06-a0f1-ef85bceb5d99',
    name: 'Anubis',
    mapImgUrl: anubis,
    isCompetitive: true,
    mapImgUrl_withLogo: anubisWithLogo
  };
  
  public static Inferno: ICS2MapModel = {
    mapId: 'aa194e72-84a0-4a1e-9b1e-7434c653074a',
    name: 'Inferno',
    mapImgUrl: inferno,
    isCompetitive: true,
    mapImgUrl_withLogo: infernoWithLogo
  };
  
  public static Mirage: ICS2MapModel = {
    mapId: '84817bf4-195a-4ef7-9912-6b353a4fe481',
    name: 'Mirage',
    mapImgUrl: mirage,
    isCompetitive: true,
    mapImgUrl_withLogo: mirageWithLogo
  };
  
  public static Nuke: ICS2MapModel = {
    mapId: 'f6420262-091a-43d3-be61-eb7644e9ff14',
    name: 'Nuke',
    mapImgUrl: nuke,
    isCompetitive: true,
    mapImgUrl_withLogo: nukeWithLogo
  };
  
  public static Vertigo: ICS2MapModel = {
    mapId: 'a51a7fd2-e33f-4a6d-84d9-088d395ed6e7',
    name: 'Vertigo',
    mapImgUrl: vertigo,
    isCompetitive: true,
    mapImgUrl_withLogo: vertigoWithLogo
  };
  
  public static Overpass: ICS2MapModel = {
    mapId: 'cf73f979-d345-4add-89a0-f1b883de7d81',
    name: 'Overpass',
    mapImgUrl: overpass,
    isCompetitive: false,
    mapImgUrl_withLogo: overpassWithLogo
  };
  
  public static Dust2: ICS2MapModel = {
    mapId: 'f946b578-3536-4d86-bea0-fb2f33921142',
    name: 'Dust 2',
    mapImgUrl: dust2,
    isCompetitive: true,
    mapImgUrl_withLogo: dust2WithLogo
  };
  
  public static Maps: ICS2MapModel[] = [
    this.Ancient,
    this.Anubis,
    this.Inferno,
    this.Mirage,
    this.Nuke,
    this.Vertigo,
    this.Overpass,
    this.Dust2
  ];
}

export default MapsConstants;