import React from 'react';
import './PartnerPageTournamentsTabPast.css';
import CustomSpinner1 from '../../../../components/CustomSpinners/CustomSpinner1';

const PartnerPageTournamentsTabPast: React.FC = () => {
    return (
        <>
            <CustomSpinner1 />
        </>
    );
};

export default PartnerPageTournamentsTabPast;