import './TournamentCell.css';
import { useNavigate } from 'react-router';
import { ICommonTournamentModel } from '../../models/dto/tournament/ICommonTournamentModel';
import Timer from './Timer';
import moment from 'moment';
import { MatchesFormatEnum } from '../../models/enums/tournament/MatchesFormatEnum';


interface TournamentCellProps {
    tournament: ICommonTournamentModel;
}

const TournamentCell: React.FC<TournamentCellProps> = ({ tournament }) => {
    const navigate = useNavigate()

    function formatEnumValue(enumValue: MatchesFormatEnum | keyof typeof MatchesFormatEnum | undefined): string {
        if (typeof enumValue === 'string') {
            enumValue = MatchesFormatEnum[enumValue as keyof typeof MatchesFormatEnum];
        }
        switch (enumValue) {
            case MatchesFormatEnum.VS1X1:
                return '1X1';
            case MatchesFormatEnum.VS2X2:
                return '2X2';
            case MatchesFormatEnum.VS3X3:
                return '3X3';
            case MatchesFormatEnum.VS5X5:
                return '5X5';
            default:
                return '';
        }
    }
    return (
        <div
            className="tournament_cell_container"
            onClick={() => navigate(`/tournament/${tournament.tournamentId}`)}
            style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_MINIO_URL}${tournament.logoUrl})` }}>
            <div className="tournament_cell_first_layer">
                <div className="tournament_cell_info_conatiner">
                    <div className="tournament_cell_img_container">
                        <img src={process.env.REACT_APP_IMG_MINIO_URL + "cs2_small_icon.jpg"} className="tournament_cell_img" alt="img" />
                    </div>
                    <div className="tournament_cell_tab_container">
                        <div className="tournament_cell_name">
                            {tournament.name}
                        </div>
                        <div className="tournament_cell_date">
                            {moment(tournament.startDate).format('DD.MM.YYYY')}
                        </div>
                    </div>
                </div>
                <div className="tournament_status_indicator_container">
                    <div className="tournament_status_indicator">
                        {tournament.statusName}
                    </div>
                </div>
            </div>
            <hr className="tournament_cell_horizontal" />

            <div className="tournament_cell_second_layer">
                {tournament.prizePool} ₽
            </div>
            <hr className="tournament_cell_horizontal" />
            <div className="tournament_cell_third_layer">
                <div className="tournament_cell_third_layer_part1">
                    <div>
                        {tournament.maxSlots - tournament.activeSlots} Мест
                    </div>
                    <div className="tournament_cell_date">
                    {formatEnumValue(tournament.matchesFormatEnum)}
                    </div>
                </div>

                <div className="tournament_cell_time_conatiner">
                    <div className="tournament_cell_time_header">
                        РЕГИСТРАЦИЯ
                    </div>
                    <div className="tournament_cell_time_left">
                        <Timer regDateEnd={tournament.regDateEnd} regDateStart={tournament.regDateStart} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TournamentCell;