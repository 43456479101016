import React from 'react';
import "./GooeyButton.css";
import GooeyFilter from './GooeyFilter';
import { Spinner } from 'react-bootstrap';


type GooeyButtonProps = {
    disabled?: boolean;
    isLoading: boolean;
    children: React.ReactNode;
    onClick: React.EventHandler<React.SyntheticEvent>;
}

const GooeyButton: React.FC<GooeyButtonProps> = ({
    isLoading,
    onClick,
    disabled = false,
    children, }) => {
    return (
        <button className="gooey_button"
            onClick={onClick}
            disabled={isLoading || disabled}
            type="submit">
            {isLoading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
                children
            )}
            <span className="bubbles">
                {Array.from({ length: 10 }).map((_, index) => (
                    <span key={index} className="bubble"></span>
                ))}
            </span>
            <GooeyFilter />
        </button>
    );
};

export default GooeyButton;