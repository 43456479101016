import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Match, Participant } from '../../../Inerfaces';
import { Id } from '../../../Types';
import './EditMatchModal.css';
import { CommonConstants } from '../../Constants';

interface EditMatchModalProps {
    show: boolean;
    onHide: () => void;
    match: Match | null;
    participants: Map<Id, Participant>;
    handleScoreChange: (matchId: number, opponentPosition: number, score: string) => void;
    handleStatusChange: (matchId: number, status: string | number) => void;
    onSave: (updatedMatch: Match) => void; // Новый пропс для сохранения
}

const EditMatchModal: React.FC<EditMatchModalProps> = ({
    show,
    onHide,
    match,
    participants,
    handleScoreChange,
    handleStatusChange,
    onSave,
}) => {
    const [localMatch, setLocalMatch] = useState<Match | null>(match);
    
    useEffect(() => {
        setLocalMatch(match);
    }, [match]);
    
    if (!localMatch) return null;
    
    const opponent1 = participants.get(localMatch.opponent1.id as number);
    const opponent2 = participants.get(localMatch.opponent2.id as number);
    
    const handleLocalScoreChange = (opponentPosition: number, score: string) => {
        const updatedMatch = { ...localMatch };
        const parsedScore = parseInt(score, 10);
        
        if (opponentPosition === 1 && updatedMatch.opponent1) {
            updatedMatch.opponent1.score = parsedScore;
        } else if (opponentPosition === 2 && updatedMatch.opponent2) {
            updatedMatch.opponent2.score = parsedScore;
        }
        
        setLocalMatch(updatedMatch);
    };
    
    const handleLocalStatusChange = (status: string | number) => {
        const updatedMatch = { ...localMatch, status };
        setLocalMatch(updatedMatch);
    };
    
    const handleSave = () => {
        if (localMatch) {
            onSave(localMatch);
        }
        onHide();
    };
    
    return (
        <Modal className='bracket_editMatchModal_container' show={show} onHide={onHide}>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="opponent1Score">
                        <Form.Label>{opponent1?.name || 'TBD'}</Form.Label>
                        <Form.Control
                            type="number"
                            value={localMatch.opponent1?.score !== undefined ? localMatch.opponent1.score.toString() : ''}
                            onChange={(e) => handleLocalScoreChange(1, e.target.value)}
                            placeholder='Счет'
                        />
                    </Form.Group>
                    
                    <Form.Group controlId="opponent2Score">
                        <Form.Label>{opponent2?.name || 'TBD'}</Form.Label>
                        <Form.Control
                            type="number"
                            value={localMatch.opponent2?.score !== undefined ? localMatch.opponent2.score.toString() : ''}
                            onChange={(e) => handleLocalScoreChange(2, e.target.value)}
                            placeholder='Счет'
                        />
                    </Form.Group>
                    
                    <Form.Group controlId="matchStatus">
                        <Form.Label>Статус</Form.Label>
                        <Form.Control
                            as="select"
                            value={localMatch.status}
                            onChange={(e) => handleLocalStatusChange(e.target.value)}
                        >
                            <option value={CommonConstants.MatchLocked}>Участники не определены</option>
                            <option value={CommonConstants.MatchWaiting}>Один участник определен</option>
                            <option value={CommonConstants.MatchReady}>Ожидание</option>
                            <option value={CommonConstants.MatchRunning}>Продолжается</option>
                            <option value={CommonConstants.MatchCompleted}>Завершен</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
                
                <div className='editMatchModal_buttonContainer'>
                    <button onClick={handleSave}>
                        Ок
                    </button>
                    
                    <button onClick={onHide}>
                        Отмена
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EditMatchModal;
