import { authInstance } from "..";
import { ICalculateFinalMatchDateModel } from "../../models/ICalculateFinalMatchDateModel";
import { ICS2MapModel } from "../../models/dto/cs2/ICS2MapModel";


export const createCS2Tournament = async (
    tournamentData: FormData
): Promise<string | false> => {
    try { 
        const response = await authInstance.post('CS2Tournaments/CreateTournament',  tournamentData);
        return response.data;
    } catch (e: any) {
        console.log(e.response?.data?.message || 'Error creating tournament');
        return false;
    }
};
export const calculateThirdPlaceDate = async (
    calculateData: ICalculateFinalMatchDateModel
): Promise<string | false> => {
    try { 
        const response = await authInstance.post('TournamentsCommon/CalculateMinDateForFinalMatch',  calculateData);
        return response.data;
    } catch (e: any) {
        console.log(e.response?.data?.message || 'Error calculating date');
        return false;
    }
};

export const getAllMaps = async (): Promise<ICS2MapModel[] | false> => {
    try {
        const response = await authInstance.get(`CS2Tournaments/GetAllMaps`);
        return response.data as ICS2MapModel[];
    } catch (e: any) {
        alert(e.response?.data?.message || 'Error when getting maps!');
        return false;
    }
};

export const getTournamentMaps = async (
    tournamentId: string
): Promise<ICS2MapModel[] | false> => {
    try {
        const response = await authInstance.get(`CS2Tournaments/GetTournamentMaps?tournamentId=${tournamentId}`);
        return response.data as ICS2MapModel[];
    } catch (e: any) {
        alert(e.response?.data?.message || 'Error when getting maps!');
        return false;
    }
};