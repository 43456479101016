import React, { useEffect, useState } from 'react';
import './CS2MapStatistics.css';
import { List } from 'react-bootstrap-icons';
import MapsConstants from '../../helpers/constants/mapsConstants';
import { ICS2MapModel } from '../../models/dto/cs2/ICS2MapModel';
import { ICS2MapStatistics } from '../../models/dto/stats/cs2/playerStats/ICS2MapStatistics';
import CustomSpinner1 from '../CustomSpinners/CustomSpinner1';
import CS2Chart from '../CS2Chart/CS2Chart';
import { IMapKillsStat } from '../../models/dto/stats/cs2/playerStats/IMapKillsStat';

const mapKillsStats: IMapKillsStat[] = [
    {
        matchId: "match1",
        matchTeams: {
            teamAId: "team1",
            teamATag: "Alpha",
            teamBId: "team2",
            teamBTag: "Bravo"
        },
        tournamentName: "Championship Series",
        matchDate: "2023-06-15",
        kills: 32
    },
    {
        matchId: "match2",
        matchTeams: {
            teamAId: "team3",
            teamATag: "Charlie",
            teamBId: "team4",
            teamBTag: "Delta"
        },
        tournamentName: "Pro League",
        matchDate: "2023-07-20",
        kills: 2
    },
    {
        matchId: "match3",
        matchTeams: {
            teamAId: "team5",
            teamATag: "Echo",
            teamBId: "team6",
            teamBTag: "Foxtrot"
        },
        tournamentName: "World Cup",
        matchDate: "2023-08-10",
        kills: 35
    },
    {
        matchId: "match4",
        matchTeams: {
            teamAId: "team7",
            teamATag: "Golf",
            teamBId: "team8",
            teamBTag: "Hotel"
        },
        tournamentName: "National Tournament",
        matchDate: "2023-09-05",
        kills: 15
    }
];

const mapFullStat: ICS2MapStatistics = {
    wins: '50',
    gamesWon: '25',
    totalGames: '50',
    kda: '4,25',
    kills: '23',
    death: '12',
    assists: '8',
    matches: mapKillsStats
}

interface ICS2MapStatisticsProps {
    playerId: string | null;
} 

const CS2MapStatistics: React.FC<ICS2MapStatisticsProps> = ({ playerId }) => {
    const [isMapListVisible, setIsMapListVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [playerMapStat, setPlayerMapStat] = useState<ICS2MapStatistics>();
    const [currentMap, setCurrentMap] = useState<ICS2MapModel>({
        mapId: MapsConstants.Ancient.mapId,
        name: MapsConstants.Ancient.name,
        mapImgUrl: MapsConstants.Ancient.mapImgUrl,
        isCompetitive: MapsConstants.Ancient.isCompetitive
    });
    
    const handleVisibleMapList = () => {
        setIsMapListVisible(!isMapListVisible);
    }
    
    const handleSelectMap = (currentMap: ICS2MapModel) => {
        setCurrentMap(currentMap);
        handleVisibleMapList();
    }
    
    useEffect(() => {
        setIsLoading(true);
        
        // TODO: let mapStatData = getPlayerMapStat(playerId: string, mapId: string);
        
        setTimeout(() => {
            setPlayerMapStat(mapFullStat);
            setIsLoading(false);
        }, 2000);
    }, [currentMap]);
    
    const winsIndicator = (): string => {
        return (
            Number(playerMapStat?.wins) < 40 ? 'mapStatistics_colorIndicator red' :
            Number(playerMapStat?.wins) >= 70 ? 'mapStatistics_colorIndicator green' :
            'mapStatistics_colorIndicator'
        )
    }
    
    return (
        <div className="mapStatistics_container">
            <div className='mapStatistics_containerMap' style={{backgroundImage: `url(${currentMap.mapImgUrl})`}}>
                <div className='mapStatistics_listIconContainer'>
                    <List className={`mapStatistics_listIcon ${isMapListVisible ? 'active' : ''}`} onClick={handleVisibleMapList} size={40} />
                    
                    {isLoading ? (
                        <div className='mapStatistics_loadingSpinner'>
                            <CustomSpinner1 sizeSpinnerContainer='100px' size='100px' />
                        </div>
                    ) : (
                        <div>
                            <h2 className='mapStatistics_mapName'>{currentMap.name}</h2>
                            
                            <div className='mapStatistics_statInfoContiner'>
                                <div className='mapStatistics_statValue'>
                                    <div className='mapStatistics_statValue valueItem'>
                                        <p>процент побед:</p>
                                        <h2><span className={winsIndicator()}>{playerMapStat?.wins}</span>%</h2>
                                    </div>
                                    
                                    <div className='mapStatistics_statValue valueItem'>
                                        <p>игр выиграно</p>
                                        <h2><span className={winsIndicator()}>{playerMapStat?.gamesWon}</span>/{playerMapStat?.totalGames}</h2>
                                    </div>
                                </div>
                                
                                <div className='mapStatistics_statValue kda'>
                                    <p>средний kda: {playerMapStat?.kda}</p>
                                    <div className='mapStatistics_statValueKda'>
                                        <div className='mapStatistics_statValue valueItemKda'>
                                            <p>kills:</p>
                                            <h2>{playerMapStat?.kills}</h2>
                                        </div>
                                        
                                        <div className='mapStatistics_statValue valueItemKda'>
                                            <p>deaths:</p>
                                            <h2>{playerMapStat?.death}</h2>
                                        </div>
                                        
                                        <div className='mapStatistics_statValue valueItemKda'>
                                            <p>assists:</p>
                                            <h2>{playerMapStat?.assists}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    
                    {
                        isMapListVisible && (
                            <div className='mapStatistics_listContainer'>
                                {
                                    MapsConstants.Maps.filter(m => m.isCompetitive).map((m, idx) => (
                                        <div className='mapStatistics_listMap' key={idx}>
                                            <h5 className='mapStatistics_listMapName' onClick={() => handleSelectMap(m)}>{m.name}</h5>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>  
            
            <div className='cs2_statunit_container'>
                <CS2Chart 
                    chartName='КОЛИЧЕСТВО УБИЙСТВ НА КАРТЕ (KILLS)' 
                    chartData={playerMapStat?.matches || []} 
                    chartDataKey='kills' 
                    chartXLineDataType={'matchId'}  
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};

export default CS2MapStatistics;