import React, { useContext, useEffect, useRef, useState } from 'react';
import './CreateTeam.css';
import { Container, Row, Tab, Col, Nav, Form, FloatingLabel, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import { ICreateTeamViewModel } from '../../viewModels/ICreateTeamViewModel';
import { AppContext } from '../../components/AppContext';
import { CommonConstants } from '../../helpers/constants/commonConstants';
import { createTeam } from '../../http/playerActionsAPI';
import { ArrowClockwise, InfoCircleFill, X } from 'react-bootstrap-icons';
import { IPlayerSelectorPlayerModel } from '../../components/PlayerSelector/Models/IPlayerSelectorPlayerModel';
import { useNavigate } from 'react-router-dom';
import { CustomProvider, DatePicker, DateRangePicker } from 'rsuite';
import { ruRU } from 'rsuite/esm/locales';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import { SystemRolesEnum } from '../../models/enums/SystemRolesEnum';
import { ITeamParticipantVM } from '../../viewModels/ITeamParticipantVM';
import { TeamParticipantEnum } from '../../models/enums/TeamParticipantEnum';
import { updateContext } from '../../logic/CommonLogic';
import PlayerSelector from '../../components/PlayerSelector/PlayerSelector';
import GooeyButton from '../../components/Buttons/GooeyButton/GooeyButton';

const avatars = [
    'team.png',
    'team2.png',
    'team3.png'
];

const CreateTeam: React.FC = () => {
    const { player, playerTeam, partner } = useContext(AppContext);
    const navigate = useNavigate();
    const [randomAvatarIdx, setRandomAvatarIdx] = useState(0);
    const [currentAvatar, setCurrentAvatar] = useState<string>(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { afterToday } = DateRangePicker;
    const [isLoading, setIsLoading] = useState(false);
    const userSystemRole = player ? SystemRolesEnum.Player : SystemRolesEnum.Partner; // TODO: +Admin
    
    const creator = player ? {
        creatorId: player.playerId,
        creatorUserName: player.login,
        creatorAvatarURL: player.avatarURL,
    } : partner ? {
        creatorId: partner.partnerId,
        creatorUserName: partner.login,
        creatorAvatarURL: partner.logoUrl,
    } : { // TODO: Admin
        creatorId: null,
        creatorUserName: null,
        creatorAvatarURL: null,
    };
    
    const [teamPlayers, setTeamPlayers] = useState<IPlayerSelectorPlayerModel[]>(userSystemRole === SystemRolesEnum.Player ? [{
        playerId: creator.creatorId ?? null,
        playerName: creator.creatorUserName ?? null,
        avatarUrl: creator.creatorAvatarURL ?? null
    }] : []);
    const [teamStaff, setTeamStaff] = useState<IPlayerSelectorPlayerModel[]>(player || partner ? [{
        playerId: creator.creatorId ?? null,
        playerName: creator.creatorUserName ?? null,
        avatarUrl: creator.creatorAvatarURL ?? null
    }] : []);
    const [teamParticipants, setTeamParticipants] = useState<ITeamParticipantVM[]>(creator && creator.creatorId ? [
        {
            participantId: creator.creatorId,
            participantTypes:
                userSystemRole === SystemRolesEnum.Player ? [
                    TeamParticipantEnum.Player,
                    TeamParticipantEnum.Creator,
                ] : [
                    TeamParticipantEnum.Owner,
                    TeamParticipantEnum.Creator,
                ]
        }
    ] : []);
    
    const [visibleRolesMemberId, setVisibleRolesMemberId] = useState<string | null>(null);
    const [participantRoles, setParticipantRoles] = useState<{ [key: string]: TeamParticipantEnum[] }>({});
    let filteredTeamStaff = userSystemRole === SystemRolesEnum.Partner ? teamStaff.filter(s => s.playerId !== creator.creatorId) : teamStaff;
    
    const [validated, setValidated] = useState(false);
    const [validatedSecond, setValidatedSecond] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const formRef1 = useRef<HTMLFormElement>(null);
    const formRef2 = useRef<HTMLFormElement>(null);
    const [teamData, setTeamData] = useState<ICreateTeamViewModel>({
        initiatorId: player?.playerId ? player?.playerId : partner?.partnerId,
        creatorId: creator?.creatorId ?? CommonConstants.GuidEmpty,
        createdBy: userSystemRole,
        gameTypeId: '',
        city: '',
        name: '',
        tag: '',
        description: '',
        creationDate: '',
        score: 0,
        logoUrl: null,
        logoStringUrl: null,
        website: null,
        contactEmail: '',
        participants: []
    });
    
    const popoverPersonal = (
        <Popover >
            <Popover.Body className='infoDescRole'>
                <b>Капитан</b> - Участвует в выборе карт <br />
                <b>Тренер команды</b> - Спектатор матча <br />
                <b>Менеджер</b> - Управлялет командой <br />
                <b>Контент мэйкер</b> - Редактирует новости команды 
                <br /><br />
                <strong>Капитан может быть только один!</strong>
                <strong>Тренер не может быть игроком</strong>
            </Popover.Body>
        </Popover>
    );
    
    const rotateRight = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setRandomAvatarIdx((prev) => (prev + 1) % avatars.length);
        setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
        
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            setTeamData(prev => ({ ...prev, logoFile: undefined }))
        }
    };
    
    useEffect(() => {
        setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
    }, [randomAvatarIdx]);
    
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | Date | null,
        name?: string
    ) => {
        if (event instanceof Date || event === null) {
            const value = event ? event.toISOString() : '';
            if (!name) return;
            
            setTeamData(prevTeamData => ({
                ...prevTeamData,
                [name]: value
            }));
        } else {
            const { name, value, type, checked } = event.target as HTMLInputElement;
            if (type === 'checkbox') {
                setTeamData(prevTeamData => ({
                    ...prevTeamData,
                    [name]: checked
                }));
            } else {
                setTeamData(prevTeamData => ({
                    ...prevTeamData,
                    [name]: value
                }));
            }
        }
    };
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setTeamData(prevTeam => ({ ...prevTeam, logoUrl: file }));
            const imageUrl = URL.createObjectURL(e.target.files[0]);
            setCurrentAvatar(imageUrl);
        }
    };
    
    const handleDateChange = (date: Date | null) => {
        if (date && !isNaN(date.getTime())) {
            const value = date.toISOString();
            setTeamData(prev => ({
                ...prev,
                creationDate: value,
            }));
        } else {
            setTeamData(prev => ({
                ...prev,
                creationDate: '',
            }));
        }
    };
    
    const handleRemoveTeamPlayer = (selectedPlayer: IPlayerSelectorPlayerModel) => {
        if (teamStaff.some(s => s.playerId === selectedPlayer.playerId)) {
            if (selectedPlayer.playerId) {
                handleAddOrRemoveParticipantType(selectedPlayer.playerId, TeamParticipantEnum.Player, false);
                setTeamPlayers(teamPlayers.filter(s => s.playerId !== selectedPlayer.playerId));
            }
        } else {
            setTeamParticipants(teamParticipants.filter(p => p.participantId !== selectedPlayer.playerId));
            setTeamPlayers(teamPlayers.filter(s => s.playerId !== selectedPlayer.playerId));
        }
    };
    
    const isVisibleCoachChecbox = (member: IPlayerSelectorPlayerModel) => {
        if (teamParticipants.some(p => p.participantId === member.playerId)) {
            if (teamParticipants.find(p => p.participantId === member.playerId)?.participantTypes.includes(TeamParticipantEnum.Player)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
    
    const handleRemoveTeamStaff = (selectedStaff: IPlayerSelectorPlayerModel) => {
        if (selectedStaff.playerId) {
            const isPlayer = teamPlayers.some(s => s.playerId === selectedStaff.playerId);
            
            if (isPlayer) {
                handleAddOrRemoveParticipantType(selectedStaff.playerId, TeamParticipantEnum.Player, false);
                
                setTeamParticipants(prevParticipants =>
                    prevParticipants.map(p => {
                        if (p.participantId === selectedStaff.playerId) {
                            return {
                                ...p,
                                participantTypes: [TeamParticipantEnum.Player]
                            };
                        }
                        return p;
                    })
                );
            } else {
                setTeamParticipants(teamParticipants.filter(p => p.participantId !== selectedStaff.playerId));
            }
            
            setTeamStaff(teamStaff.filter(s => s.playerId !== selectedStaff.playerId));
        }
    };
    
    const handleAddOrRemoveParticipantType = (participantId: string, participantType: TeamParticipantEnum, isChecked: boolean) => {
        setTeamParticipants(prevParticipants => {
            const participantIndex = prevParticipants.findIndex(p => p.participantId === participantId);
            
            if (participantIndex !== -1) {
                const existingParticipant = prevParticipants[participantIndex];
                let updatedParticipantTypes = existingParticipant.participantTypes;
                if (isChecked && !existingParticipant.participantTypes.includes(participantType)) {
                    updatedParticipantTypes = [...existingParticipant.participantTypes, participantType];
                } else if (!isChecked && existingParticipant.participantTypes.includes(participantType)) {
                    updatedParticipantTypes = existingParticipant.participantTypes.filter(type => type !== participantType);
                }
                const updatedParticipant = {
                    ...existingParticipant,
                    participantTypes: updatedParticipantTypes
                };
                setParticipantRoles(prevRoles => ({
                    ...prevRoles,
                    [participantId]: updatedParticipantTypes
                }));
                return [
                    ...prevParticipants.slice(0, participantIndex),
                    updatedParticipant,
                    ...prevParticipants.slice(participantIndex + 1)
                ];
            } else if (isChecked) {
                const newParticipant = { participantId, participantTypes: [participantType] };
                setParticipantRoles(prevRoles => ({
                    ...prevRoles,
                    [participantId]: [participantType]
                }));
                return [
                    ...prevParticipants,
                    newParticipant
                ];
            }
            
            return prevParticipants;
        });
    };
    
    const handleAddParticipant = (selectedPlayer: IPlayerSelectorPlayerModel, selectedPlayerRoleType?: TeamParticipantEnum) => {
        const playerId = selectedPlayer.playerId;
        
        if (selectedPlayerRoleType === undefined) {
            if (!teamStaff.some(s => s.playerId === playerId)) {
                if (playerId && playerId === player?.playerId) {
                    handleAddOrRemoveParticipantType(playerId, TeamParticipantEnum.Creator, true);
                }
                
                setTeamStaff(prev => [
                    ...prev,
                    {
                        playerId: selectedPlayer.playerId,
                        playerName: selectedPlayer.playerName,
                        avatarUrl: selectedPlayer.avatarUrl
                    }
                ]);
                
                return;
            } else {
                return;
            }
        }
        
        if (selectedPlayerRoleType === TeamParticipantEnum.Player) {
            if (playerId !== null && playerId !== undefined) {
                
                if(teamPlayers.length >= 5) {
                    alert('В команде уже 5 игроков!')
                    return;
                }
                
                if (teamPlayers.some(p => p.playerId === playerId)) {
                    return;
                }
                
                if (teamParticipants.some(p => p.participantId === playerId)) {
                    if (teamParticipants.find(p => p.participantId === playerId)?.participantTypes.includes(TeamParticipantEnum.Coach)) {
                        alert('Тренер не может быть игроком!');
                        return;
                    } else {
                        handleAddOrRemoveParticipantType(playerId, TeamParticipantEnum.Player, true);
                    }
                } else {
                    if (playerId && playerId === player?.playerId) {
                        setTeamParticipants(prev => [
                            ...prev,
                            {
                                participantId: playerId,
                                participantTypes: [selectedPlayerRoleType, TeamParticipantEnum.Creator]
                            }
                        ]);
                    } else {
                        setTeamParticipants(prev => [
                            ...prev,
                            {
                                participantId: playerId,
                                participantTypes: [selectedPlayerRoleType]
                            }
                        ]);
                    }
                }
                
                setTeamPlayers(prev => [
                    ...prev,
                    selectedPlayer
                ]);
            } else {
                return;
            }
        } else {
            if (playerId !== null && playerId !== undefined) {
                if (teamParticipants.some(p => p.participantId === playerId)) {
                    if (selectedPlayerRoleType) {
                        handleAddOrRemoveParticipantType(playerId, selectedPlayerRoleType, true);
                    } else {
                        return
                    }
                } else {
                    if (selectedPlayerRoleType) {
                        setTeamParticipants(prev => [
                            ...prev,
                            {
                                participantId: playerId,
                                participantTypes: [selectedPlayerRoleType]
                            }
                        ]);
                    }
                }
            } else {
                return;
            }
        }
    };
    
    const hasRoleByRoleType = (roleType: TeamParticipantEnum) => {
        return teamParticipants.some(participant =>
            participant.participantTypes.includes(roleType)
        );
    };
    
    const isMemberCaptain = (currentMember: IPlayerSelectorPlayerModel) => {
        return teamParticipants.some(participant =>
            participant.participantId === currentMember.playerId &&
            participant.participantTypes.includes(TeamParticipantEnum.Captain)
        );
    };
    
    const handleVisibleMemberRoles = (memberId: string) => {
        setVisibleRolesMemberId(prev => (prev === memberId ? null : memberId));
    };
    
    const handleCreateTeam = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        const form1 = formRef1.current;
        const form2 = formRef2.current;
        
        const isForm1Valid = form1?.checkValidity() ?? false;
        const isForm2Valid = form2?.checkValidity() ?? false;
        
        if (!isForm1Valid || !isForm2Valid) {
            if (!isForm1Valid) {
                setValidated(true);
            }
            if (!isForm2Valid) {
                setValidatedSecond(true);
            }
            setShowAlert(true);
            return;
        }
        setValidated(true);
        setValidatedSecond(true)
        try {
            const form = event.currentTarget as HTMLFormElement;
            
            if (form.checkValidity() === false) {
                event.stopPropagation();
                return;
            }
            
            if (!hasRoleByRoleType(TeamParticipantEnum.Captain)) {
                alert('В команде должен быть капитан!');
                return;
            }
            
            const insufficientRoles = teamStaff.some(staff => {
                const participant = teamParticipants.find(part => part.participantId === staff.playerId);
                if (!participant || participant.participantTypes.length === 0) {
                    return true;
                } else if (participant.participantTypes.length === 1) {
                    return participant.participantTypes[0] === TeamParticipantEnum.Player;
                }
                return false;
            });
            
            if (insufficientRoles) {
                alert('Не у всех есть роль!');
                return;
            }
            
            if (!teamParticipants.some(p => p.participantId === creator.creatorId)) {
                alert('Вас нет в команде!');
                return;
            }
            
            const captainWithoutPlayerRole = teamParticipants.some(p =>
                p.participantTypes.includes(TeamParticipantEnum.Captain) &&
                !p.participantTypes.includes(TeamParticipantEnum.Player)
            );
            
            if (captainWithoutPlayerRole) {
                alert('Капитан должен быть игроком!');
                return;
            }
            
            const isPlaerWithCoach = teamParticipants.some(p => 
                p.participantTypes.includes(TeamParticipantEnum.Coach) && 
                p.participantTypes.includes(TeamParticipantEnum.Player)
            );
            
            if (isPlaerWithCoach) {
                alert('Тренер не может быть игром!');
                return;
            }
            
            const formData = new FormData();
            
            formData.append('participantsJSON', JSON.stringify(teamParticipants));
            
            if (teamData.logoUrl) {
                formData.append('logoUrl', teamData.logoUrl);
            } else {
                formData.append('logoStringUrl', avatars[randomAvatarIdx]);
            }

            formData.append('initiatorId', teamData.initiatorId ?? '');
            formData.append('creatorId', teamData.creatorId ?? '');
            formData.append('createdBy', String(teamData.createdBy));
            formData.append('name', teamData.name);
            formData.append('description', teamData.description);
            formData.append('creationDate', teamData.creationDate);
            formData.append('score', teamData.score.toString());
            formData.append('website', teamData.website ?? '');
            formData.append('contactEmail', teamData.contactEmail ?? '');
            formData.append('city', teamData.city);
            formData.append('gameTypeId', teamData.gameTypeId || '');
            formData.append('tag', teamData.tag || '');
            
            const teamCreatedResponse = await createTeam(formData);
            
            if (teamCreatedResponse && teamCreatedResponse != null && teamCreatedResponse.teamId) {
                setIsLoading(false);
                playerTeam?.update(teamCreatedResponse);
                await updateContext(player, playerTeam, undefined);
                navigate(`/team/${playerTeam?.teamId}`)
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Create Team error:', error);
        }
    };
    
    const handleSelectfPlayer = (selectedPlayer: IPlayerSelectorPlayerModel) => {
        handleAddParticipant(selectedPlayer, TeamParticipantEnum.Player);
    };
    
    
    const handleSelectfStaff = (selectedPlayer: IPlayerSelectorPlayerModel) => {
        handleAddParticipant(selectedPlayer);
    };

    useEffect(() => {
        const roles = teamParticipants.reduce((acc, participant) => {
            acc[participant.participantId] = participant.participantTypes;
            return acc;
        }, {} as { [key: string]: TeamParticipantEnum[] });
        setParticipantRoles(roles);
    }, [teamParticipants]);

    
    return (
        <Container>
            <div className="create_tournament_container">
                <div className="create_tournament_header">
                    СОЗДАНИЕ КОМАНДЫ
                    <GooeyButton onClick={handleCreateTeam} isLoading={isLoading} disabled={isLoading}>
                        СОЗДАТЬ КОМАНДУ
                    </GooeyButton>
                </div>
                <div className="create_tournament_main_container">
                    <Tab.Container defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav className="create-tournament-navs" variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" className="create-tournament-navlink">ОПИСАНИЕ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">СОЦИАЛЬНЫЕ СЕТИ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">ИГРОКИ КОМАНДЫ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">ПЕРСОНАЛ КОМАНДЫ</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form ref={formRef1} className="create_tournament_form" noValidate validated={validated}>
                                            {showAlert && (
                                                <Alert variant='danger'>
                                                    Заполните все обязательные поля
                                                </Alert>
                                            )}
                                            <FloatingLabel label="НАЗВАНИЕ КОМАНДЫ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="name"
                                                    placeholder=""
                                                    value={teamData.name}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={32}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ТЭГ КОМАНДЫ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="tag"
                                                    placeholder=""
                                                    value={teamData.tag}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={8}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ОПИСАНИЕ КОМАНДЫ" className='create_tourn_label'>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={6}
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="description"
                                                    placeholder=""
                                                    value={teamData.description}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={250}
                                                />
                                            </FloatingLabel>
                                            <CustomProvider theme="dark" locale={ruRU}>
                                                <DatePicker
                                                    className="create_tournament_form_control"
                                                    format="dd/MM/yyyy"
                                                    placeholder="ДАТА СОЗДАНИЯ КОМАНДЫ"
                                                    name="creationDate"
                                                    value={teamData.creationDate ? new Date(teamData.creationDate) : null}
                                                    onChange={handleDateChange}
                                                    shouldDisableDate={afterToday()}
                                                />
                                            </CustomProvider>
                                            <FloatingLabel label="ГОРОД" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="city"
                                                    placeholder=""
                                                    value={teamData.city}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={32}
                                                />
                                            </FloatingLabel>
                                            <Form.Select
                                                className="create_tournament_form_control option"
                                                name="gameTypeId"
                                                value={teamData.gameTypeId || ''}
                                                required
                                                onChange={handleInputChange}
                                            >
                                                <option disabled value="">ИГРОВАЯ ДИСЦИПЛИНА</option>
                                                <option value={TournamentConstants.CS2.gameTypeId}>CS2</option>
                                                <option value={TournamentConstants.DOTA2.gameTypeId} disabled >DOTA2</option>
                                                <option value="3" disabled >Fortnite</option>
                                            </Form.Select>
                                            <Form.Label>
                                                ЛОГОТИП КОМАНДЫ
                                            </Form.Label>
                                            <div className='createTournament_download_avatar_container'>
                                                <h3 className='createTournament_download_avatar_title'>Загрузите или выберите аватар</h3>
                                                <div className='createTournament_download_avatar'>
                                                    <Form.Group controlId="formFile" className="create_tournament_form_control date">
                                                        <Form.Control
                                                            ref={fileInputRef}
                                                            type="file"
                                                            name="logoUrl"
                                                            onChange={handleFileChange}
                                                        />
                                                    </Form.Group>
                                                    <div className='createTournament_avatar_container'>
                                                        <div className='createTournament_avatar'>
                                                            <img src={currentAvatar} alt="User Avatar" />
                                                            <button className='createTournament_avatar_button' onClick={rotateRight}>
                                                                <ArrowClockwise className='avatar_button_arrowIcon' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Form ref={formRef2} className="create_tournament_form" noValidate validated={validatedSecond}>
                                            {showAlert && (
                                                <Alert variant='danger'>
                                                    Заполните все обязательные поля
                                                </Alert>
                                            )}
                                            <FloatingLabel label="ВЕБСАЙТ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    name="website"
                                                    placeholder=""
                                                    value={teamData.website || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ПОЧТА ДЛЯ СВЯЗИ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    name="contactEmail"
                                                    placeholder=""
                                                    value={teamData.contactEmail || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Form className="create_tournament_form" noValidate validated={validated}>
                                            {showAlert && (
                                                <Alert variant='danger'>
                                                    Заполните все обязательные поля
                                                </Alert>
                                            )}
                                            <div className='teammates_block'>
                                                <div className='teammates_block_header'>ИГРОКИ КОМАНДЫ</div>
                                                {teamPlayers.length !== 0 ? (
                                                    <div className='teammates__teamcreate_container'>
                                                        {teamPlayers.map((member) => (
                                                            <div key={member.playerId} className='teammates__teamcreate_unit'>
                                                                <X className='team_member_unit_name_x' onClick={() => handleRemoveTeamPlayer(member)} />
                                                                <img className='teammates__teamcreate_avatar' src={`${process.env.REACT_APP_IMG_MINIO_URL}${member.avatarUrl}`} alt="" />
                                                                <a className='teammates__teamcreate_name' target='_blank' href={`/player/${member.playerId}`}>{member.playerName}</a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className='infoText'>В команде еще нет игроков</div>
                                                )}
                                                
                                                <PlayerSelector onPlayerSelect={handleSelectfPlayer} />
                                            </div>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <Form.Check className="create_tournament_form">
                                            {showAlert && (
                                                <Alert variant='danger'>
                                                    Заполните все обязательные поля
                                                </Alert>
                                            )}
                                            <div className='teammates_block'>
                                                <div className='teammates_block_header'>
                                                    ПЕРСОНАЛ КОМАНДЫ
                                                    <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverPersonal}>
                                                        <InfoCircleFill className='create_tournament_info' />
                                                    </OverlayTrigger>
                                                </div>
                                                {teamStaff.length !== 0 ? (
                                                    <div className='teammates__teamcreate_container'>
                                                        {filteredTeamStaff.map((member) => (
                                                            <div key={member.playerId} className='teammates__teamcreate_unit'>
                                                                {/* //TODO: в режиме редактирования удаление доступно только капитану */}
                                                                <X className='team_member_unit_name_x' onClick={() => handleRemoveTeamStaff(member)} />
                                                                <img className='teammates__teamcreate_avatar' src={`${process.env.REACT_APP_IMG_MINIO_URL}${member.avatarUrl}`} alt="" />
                                                                <a className='teammates__teamcreate_name' target='_blank' href={`/player/${member.playerId}`}>{member.playerName}</a>
                                                                <div className='rolesList_container'>
                                                                    <button onClick={() => {
                                                                        if (member.playerId) {
                                                                            handleVisibleMemberRoles(member.playerId)
                                                                        }
                                                                    }}>Роли</button>
                                                                    {visibleRolesMemberId === member.playerId && (
                                                                        <Form.Group className="create_tournament_form_checkboxes" controlId="memberRole">
                                                                            {(!hasRoleByRoleType(TeamParticipantEnum.Captain) || isMemberCaptain(member)) && member.playerId && (
                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    className='create_tournament_form_checkbox'
                                                                                    label="Капитан"
                                                                                    value={TeamParticipantEnum.Captain}
                                                                                    checked={participantRoles[member.playerId]?.includes(TeamParticipantEnum.Captain) || false}
                                                                                    onChange={(e) => {
                                                                                        if (member.playerId) {
                                                                                            handleAddOrRemoveParticipantType(member.playerId, TeamParticipantEnum.Captain, e.target.checked);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {member.playerId && (
                                                                                <>
                                                                                    {isVisibleCoachChecbox(member) && (
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            className='create_tournament_form_checkbox'
                                                                                            label="Тренер"
                                                                                            value={TeamParticipantEnum.Coach}
                                                                                            checked={participantRoles[member.playerId]?.includes(TeamParticipantEnum.Coach) || false}
                                                                                            onChange={(e) => {
                                                                                                if (member.playerId) {
                                                                                                    handleAddOrRemoveParticipantType(member.playerId, TeamParticipantEnum.Coach, e.target.checked);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    <Form.Check
                                                                                        disabled
                                                                                        type="checkbox"
                                                                                        className='create_tournament_form_checkbox'
                                                                                        label="Менеджер"
                                                                                        value={TeamParticipantEnum.Manager}
                                                                                        checked={participantRoles[member.playerId]?.includes(TeamParticipantEnum.Manager) || false}
                                                                                        onChange={(e) => {
                                                                                            if (member.playerId) {
                                                                                                handleAddOrRemoveParticipantType(member.playerId, TeamParticipantEnum.Manager, e.target.checked);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <Form.Check
                                                                                        disabled
                                                                                        type="checkbox"
                                                                                        className='create_tournament_form_checkbox'
                                                                                        label="Контент мэйкер"
                                                                                        value={TeamParticipantEnum.ContentMaker}
                                                                                        checked={participantRoles[member.playerId]?.includes(TeamParticipantEnum.ContentMaker) || false}
                                                                                        onChange={(e) => {
                                                                                            if (member.playerId) {
                                                                                                handleAddOrRemoveParticipantType(member.playerId, TeamParticipantEnum.ContentMaker, e.target.checked);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </Form.Group>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className='infoText'>В команде еще нет персонала</div>
                                                )}
                                                
                                                <PlayerSelector onPlayerSelect={handleSelectfStaff} />  
                                            </div>
                                        </Form.Check>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </Container>
    );
};

export default CreateTeam;