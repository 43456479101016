import { IPlayerStoreModel } from '../models/dto/player/IPlayerStoreModel';
import { IPartnerStoreModel } from '../models/dto/partner/IPartnerStoreModel';
import { IStoreModel } from '../models/dto/IStoreModel';
import { IUserLoginViewModel } from '../viewModels/IUserLoginViewModel';
import { IUserRegistrationViewModel } from '../viewModels/registration/IUserRegistrationViewModel';
import { guestInstance, authInstance } from './index';
import { IPlayerPageModel } from '../models/dto/player/IPlayerPageModel';
import { IPartnerPageModel } from '../models/dto/partner/IPartnerPageModel';

type IResponseModel = IPlayerStoreModel | IPartnerStoreModel;


export const signup = async (
    userData: IUserRegistrationViewModel
): Promise<string | null> => {
    try {
        if (userData.password !== userData.confirmPassword) {
            throw new Error('Пароли не совпадают!');
        }
        await guestInstance.post('Auth/Register', { ...userData });
        return null;
    } catch (e: any) {
        return e.response.data || ['Возникла ошибка при попытке регистрации'];
    }
};

export const login = async (
    userData: IUserLoginViewModel
): Promise<IResponseModel | string[]> => {
    try {
        const response = await guestInstance.post('Auth/Login', { ...userData });
        const user: IResponseModel = response.data;

        if (!user.token) {
            throw new Error('Token is missing from the response');
        }

        localStorage.setItem('token', user.token);
        return user;
    } catch (e: any) {
        return e.response?.data?.errors || ['Возникла ошибка при попытке входа'];
    }
};

export const logout = (): void => {
    localStorage.removeItem('token');
};

export const checkContext = async (): Promise<IStoreModel | false> => {
    let userToken: string | null;
    let userData: IStoreModel;
    try {
        userToken = localStorage.getItem('token');
        if (!userToken) {
            return false;
        }
        const response = await authInstance.post('Auth/CheckContext', { token: userToken });
        userData = response.data;
        if (userData?.player) {
            userToken = userData.player?.token ?? '';
        } else if (userData?.partner) {
            userToken = userData.partner?.token ?? '';
        }
        if (typeof userToken === 'string') {
            localStorage.setItem('token', userToken);
            return userData;
        } else {
            throw new Error('Invalid token format');
        }
    } catch (e: any) {
        localStorage.removeItem('token');
        console.log(e.message);
        return false;
    }
};

export const createPlayer = async (
    formData: FormData
): Promise<IPlayerStoreModel | false> => {
    let player: IPlayerStoreModel;
    try {
        const response = await guestInstance.post('Players/RegisterPlayer', formData);
        player = response.data;
        localStorage.setItem('token', player.token ?? '');
        return player;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Возникла ошибка создания профиля Игрока');
        return false;
    }
}

export const updatePlayer = async (
    editData: FormData,
    playerId: string | undefined
): Promise<string> => {
    try {
        const response = await authInstance.put(`Players/UpdatePlayer/${playerId}`, editData);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка обновления данных Игрока');
        return '';
    }
};


export const getPLayerInfo = async (
    playerId: string
): Promise<IPlayerPageModel | false> => {
    try {
        const response = await guestInstance.get(`Players/GetPlayerInfo?playerId=${playerId}`);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения данных об Игроке');
        return false;
    }
};

export const getPartnerInfo = async (
    partnerId: string
): Promise<IPartnerPageModel | false> => {
    try {
        const response = await guestInstance.get(`Partners/GetPartnerInfo/${partnerId}`);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения данных о Партнёре');
        return false;
    }
};

export const createOrg = async (
    formData: FormData
): Promise<IPartnerStoreModel | false> => {
    let organizator: IPartnerStoreModel;
    try {
        const response = await guestInstance.post('Partners/RegisterPartner', formData);
        organizator = response.data;
        localStorage.setItem('token', organizator.token ?? '');
        return organizator;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка создания профиля Партнёра');
        return false;
    }
}

export const resetPassword = async (
    userId: string | undefined,
    code: string | undefined,
    password: string,
    confirmPassword: string
): Promise<string | false> => {
    try {
        const response = await guestInstance.post('Auth/ResetPassword', { userId, code, password, confirmPassword });
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Возникла ошибка при сбросе пароля');
        return false;
    }
};

