import { authInstance } from '.';
import { IPlayerPageModel } from '../models/dto/player/IPlayerPageModel';
import { ICommonTeamModel } from '../models/dto/team/ICommonTeamModel';

export const approveTournament = async (
    tournamentId: string
): Promise<boolean> => {
    return authInstance.get(`AdminActions/ApproveTournament?&tournamentId=${tournamentId}`)
        .then(response => {
            return response.status === 200;
        })
        .catch(e => {
            console.error("Ошибка одобрения турнира:", e.response?.data || e.message);
            return false;
        });
};

export const getAllTeamsByGameTypeId = async (
    gameTypeId: string
): Promise<ICommonTeamModel[] | null> => {
    try {
        const response = await authInstance.get(`AdminActions/GetAllTeamsByGameTypeId?&gameTypeId=${gameTypeId}`);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения команд');
        return [];
    }
};

export const getAllPlayers = async (

): Promise<IPlayerPageModel[] | null> => {
    try {
        const response = await authInstance.get('AdminActions/GetPlayers');
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения игроков');
        return [];
    }
};

export const updateCS2Map = async (
    map: FormData,
    mapId: string
): Promise<string> => {
    try {
        const response = await authInstance.put(`CS2Tournaments/UpdateMap/${mapId}`, map);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка обновления карты');
        return '';
    }
};

export const createCS2Map = async (
    map: FormData
): Promise<string> => {
    try {
        const response = await authInstance.post('CS2Tournaments/AddNewMap', map);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка добавления карты');
        return '';
    }
};

export const deleteCS2Map = async (
    mapId: string
): Promise<string> => {
    try {
        const response = await authInstance.delete(`CS2Tournaments/RemoveMap/${mapId}`);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка удаления карты');
        return '';
    }
};