import { useState } from "react";

type UseModalReturnType = {
    isVisible: boolean;
    openModal: () => void;
    closeModal: () => void;
};

export const useModal = (): UseModalReturnType => {
    const [isVisible, setIsVisible] = useState(false);
    
    const openModal = () => {
        setIsVisible(true);
    };
    const closeModal = () => {
        setIsVisible(false);
    };
    
    return { isVisible, openModal, closeModal };
};