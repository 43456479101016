import { makeObservable, observable } from "mobx";
import { IUser } from "./IUser";

class User implements IUser {
    userId: string | null = null;
    email: string | null = null;
    isAuth: boolean = false;
    isAdmin: boolean = false;
    login: string | null = null;
    constructor() {
        makeObservable(this, {
            userId: observable,
            email: observable,
            isAuth: observable,
            isAdmin: observable,
            login: observable
        });
    }
}
export default User;