import React, { useEffect, useRef, useState } from "react";

import '../../Styles/Bracket.css'
import '../../Styles/EliminationStayles.css';


import { Participant, Round, Match, Stage, Group, BracketData, ChangesTournamentByBracketData } from "../../Inerfaces";
import { Id } from "../../Types";
import { CommonConstants } from "../../BracketHelpers/Constants";
import EditMatchModal from "../../BracketHelpers/BracketHelperComponents/EditMatchModal/EditMatchModal";
import BracketMatch from "../../BracketHelpers/BracketHelperComponents/BracketMatch/BracketMatch";
import HelperFunctions from "../../BracketHelpers/BracketHelperFunctions";
import { changeTournamentByBracket } from "../../../../http/tournament/tournamentActionsAPI";
import { Spinner } from "react-bootstrap";
import Draggable from "react-draggable";

// Интерфейс пропсов
interface SingleEliminationProps {
    participants: Participant[];
    rounds: Round[];
    matches: Match[];
    stages: Stage[];
    groups: Group[]
    match_game: Match;
    isAdmin: boolean;
    editMode: boolean;
    playerTeamId: string;
}

const SingleElimination: React.FC<SingleEliminationProps> = ({ participants, rounds, matches, stages, groups, match_game, isAdmin, editMode, playerTeamId }) => {
    // Стейты
    const [selectedOpponent, setSelectedOpponent] = useState<number | string | null>(null); // Отслеживание курсора на команде
    const [currentMatches, setCurrentMatches] = useState<Match[]>(matches); // Актуальный массив матчей
    const [isEditing, setIsEditing] = useState(false); // Если режим редактирования
    const [isMatchesRendered, setIsMatchesRendered] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
    const [changedMatches, setChangedMatches] = useState<Match[]>([]);
    const [originalMatches, setOriginalMatches] = useState<Match[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [shouldUpdateOriginal, setShouldUpdateOriginal] = useState(false);
    const [isRollBack, setIsRollBack] = useState<boolean>(false);
    
    
    
    // Настройки
    let hasConsolationFinal = stages[0].settings.consolationFinal; // Если матч за 3 место
    let totalRounds = rounds.length; // Кол-во раундов
    let participantMap = new Map(participants.map(participant => [participant.id, participant])); // Map по командам
    
    
    
    useEffect(() => {
        const handleResize = () => {
            setIsMatchesRendered(false);
            setTimeout(() => {
                setIsMatchesRendered(true);
            }, 100);
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    
    
    useEffect(() => {
        determineResults(currentMatches);
    }, [matches])
    
    
    
    useEffect(() => {
        setIsMatchesRendered(true);
    }, [currentMatches]);
    
    
    
    // Функции для отслеживания курсора на мыши
    const handleMouseEnter = (id: Id): void => {
        setSelectedOpponent(id);
    };
    
    
    
    const handleMouseLeave = (): void => {
        setSelectedOpponent(null);
    };
    
    
    
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
    };
    
    
    
    const generateFullJson = (): string => {
        return JSON.stringify({
            participant: participants,
            round: rounds,
            match: currentMatches,
            stage: stages,
            group: groups,
            match_game: match_game
        });
    };
    
    
    
    // Обработчик для начала редактирования конкретного матча
    const handleEditStart = (matchId: string | number): void => {
        if (!isAdmin) { return }
        
        if (isEditing) {
            const match = currentMatches.find(m => m.id === matchId) || null;
            setSelectedMatch(match);
            setModalIsOpen(true);
        }
    };
    
    
    
    const handleCloseModal = () => {
        setModalIsOpen(false);
        setSelectedMatch(null);
    };
    
    
    
    // Функция сохранения изменений
    const handleSave = async () => {
        setIsSaving(true);
        
        const updatedJson = generateFullJson();
        const changes: ChangesTournamentByBracketData = {
            tournament_id: stages[0].tournament_id as string,
            bracketData: updatedJson,
            matches: changedMatches,
            roll_back: isRollBack
        };
        
        try {
            const result = await changeTournamentByBracket(changes);
            if (result) {
                setIsEditing(false);
                setChangedMatches([]);
            } else {
                throw new Error("Error while changing tournament");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSaving(false);
            setIsRollBack(false);
        }
        
        setIsSaving(false);
        setIsEditing(false);
    };
    
    
    
    useEffect(() => {
        if (shouldUpdateOriginal) {
            setOriginalMatches(currentMatches.map(match => ({
                ...match,
                opponent1: { ...match.opponent1 },
                opponent2: { ...match.opponent2 }
            })));
            setShouldUpdateOriginal(false);
        }
    }, [shouldUpdateOriginal, currentMatches]);
    
    
    
    const startEditing = () => {
        setShouldUpdateOriginal(true);
        setIsEditing(true);
    };
    
    
    
    const handleCancel = () => {
        setCurrentMatches(originalMatches);
        setChangedMatches([]);
        setIsEditing(false);
    };
    
    
    
    const handleSaveMatch = (updatedMatch: Match) => {
        setCurrentMatches(prevMatches => {
            const newMatches = prevMatches.map(match =>
                match.id === updatedMatch.id ? updatedMatch : match
            );
            
            determineResults(newMatches);
            
            setChangedMatches(prevChanged => {
                const isMatchChanged = prevChanged.find(match => match.id === updatedMatch.id);
                let updatedChangedMatches = [];
                    
                if (isMatchChanged) {
                    updatedChangedMatches = prevChanged.map(match => match.id === updatedMatch.id ? updatedMatch : match);
                } else {
                    updatedChangedMatches = [...prevChanged, updatedMatch];
                }
                
                // Логика для добавления следующего матча для победителя
                if (updatedMatch.match_for_winner !== CommonConstants.GuidEmpty && updatedMatch.status !== CommonConstants.MatchRunning) {
                    const winnerMatch = currentMatches.find(match => match.id === updatedMatch.match_for_winner);
                    if (winnerMatch && !updatedChangedMatches.find(match => match.id === winnerMatch.id)) {
                        if (winnerMatch.opponent1.id !== null || winnerMatch.opponent2.id !== null) {
                            updatedChangedMatches.push(winnerMatch);
                        }
                    }
                }
                
                // Логика для добавления следующего матча для проигравшего
                if (updatedMatch.match_for_looser !== CommonConstants.GuidEmpty && updatedMatch.status !== CommonConstants.MatchRunning) {
                    const looserMatch = currentMatches.find(match => match.id === updatedMatch.match_for_looser);
                    if (looserMatch && !updatedChangedMatches.find(match => match.id === looserMatch.id)) {
                        if (looserMatch.opponent1.id !== null || looserMatch.opponent2.id !== null) {
                            updatedChangedMatches.push(looserMatch);
                        }
                    }
                }
                
                // console.log(updatedMatch)
                return updatedChangedMatches;
            });
            
            return newMatches;
        });
    };
    
    
    
    // Функция для отслеживания отпускания div
    const handleDrop = (event: React.DragEvent<HTMLDivElement>, targetId: Id | null, targetPosition: number | null, targetRoundId: Id) => {
        event.preventDefault();
        
        let dragData = JSON.parse(event.dataTransfer.getData("application/json"));
        let draggedId = dragData.id;
        let draggedPosition = dragData.position;
        let draggedRoundId = dragData.round_id;
        
        if (draggedId !== targetId && isEditing) {
            updateCurrentMatches(draggedId, targetId, draggedPosition, targetPosition, draggedRoundId, targetRoundId);
            
            if (draggedRoundId === targetRoundId) { // Сбрасываем сетку только если перестановки идут в одном раунде
                resetMatchResults();
            }
            
            setTimeout(() => {
                determineResults(currentMatches);
            }, 0);
        }
    }
    
    
    
    // Функция сброса результатов всех матчей
    const resetMatchResults = async () => {
        setIsRollBack(true);
        setChangedMatches([]);
        await handleSave();
        window.location.reload();
    };
    
    
    
    // Функци для изменеия результата матчей
    const handleScoreChange = (matchId: Id, opponentPosition: number, score: string): void => {
        let updatedMatches = currentMatches.map(match => {
            if (match.id === matchId) {
                const parsedScore = parseInt(score, 10);
                
                if (opponentPosition === 1 && match.opponent1) {
                    match.opponent1.score = parsedScore;
                } else if (opponentPosition === 2 && match.opponent2) {
                    match.opponent2.score = parsedScore;
                }
            }
            return match;
        });
        
        setCurrentMatches(updatedMatches);
        setTimeout(() => {
            determineResults(updatedMatches);
        }, 0);
    };
    
    
    
    const handleStatusChange = (matchId: Id, status: string | number): void => {
        let updatedMatches = currentMatches.map(match => {
            if (match.id === matchId) {
                match.status = status;
            }
            
            return match;
        });
        
        setCurrentMatches(updatedMatches);
        
        determineResults(updatedMatches);
    };
    
    
    
    // Определяем результат матча на основе счета
    const determineResults = (updatedMatches: Match[]) => {
        let matches = [...updatedMatches];
        
        matches.forEach(match => {
            const isOpponent2Empty = match.opponent2?.id === CommonConstants.GuidEmpty;
            
            // Сброс результатов, если они уже существуют
            if (match.status === CommonConstants.MatchRunning && match.opponent1) {
                match.opponent1.result = undefined;
            }
            if (match.status === CommonConstants.MatchRunning && match.opponent2) {
                match.opponent2.result = undefined;
            }
            
            // Проверяем статус матча
            if (match.status !== CommonConstants.MatchRunning && match.opponent1?.score !== undefined && match.opponent2?.score !== undefined) {
                let isOpponent1Winner = isOpponent2Empty || match.opponent1.score > match.opponent2.score;
                
                if (isOpponent1Winner) {
                    if (match.opponent1) {
                        match.opponent1.result = 'win';
                        match.status = CommonConstants.MatchCompleted;
                    }
                    
                    if (match.opponent2) {
                        match.opponent2.result = 'loss';
                        match.status = CommonConstants.MatchCompleted;
                    }
                } else {
                    if (match.opponent1) {
                        match.opponent1.result = 'loss';
                        match.status = CommonConstants.MatchCompleted;
                    }
                    
                    if (match.opponent2) {
                        match.opponent2.result = 'win';
                        match.status = CommonConstants.MatchCompleted;
                    }
                }
            }
        });
        
        setCurrentMatches(matches);
        matches.forEach(match => advanceToNextRound(match));
    };
    
    
    
    const getNextOpponentPosition = (previousMatchNumber: number, nextMatch: Match | undefined) => {
        if (!nextMatch) return undefined;
        
        // Определение позиции оппонента в следующем матче для победителя
        if (previousMatchNumber % 2 === 0) {
            return nextMatch.opponent2;
        } else {
            return nextMatch.opponent1;
        }
    };
    
    
    
    const advanceToNextRound = (currentMatch: Match) => {
        let previousMatchNumber = currentMatch.number;
        
        let isOpponent1Empty = currentMatch.opponent1?.id === CommonConstants.GuidEmpty;
        let isOpponent2Empty = currentMatch.opponent2?.id === CommonConstants.GuidEmpty;
        
        let winner =
            currentMatch.opponent1?.result === 'win' || isOpponent2Empty ? currentMatch.opponent1 :
            currentMatch.opponent2?.result === 'win' || isOpponent1Empty ? currentMatch.opponent2 :
            null;
        
        let winnerId = winner ? winner.id : null;
        let nextMatchForWinnerId = currentMatch.match_for_winner;
        let nextMatchForWinner = currentMatches.find(match => match.id === nextMatchForWinnerId);
        
        let looser =
            currentMatch.opponent1?.result === 'loss' || isOpponent1Empty ? currentMatch.opponent1 :
            currentMatch.opponent2?.result === 'loss' || isOpponent2Empty ? currentMatch.opponent2 :
            null;
        
        let looserId = looser ? looser.id : null;
        let nextMatchForLooserId = currentMatch.match_for_looser;
        let nextMatchForLooser = currentMatches.find(match => match.id === nextMatchForLooserId);
        
        let opponentPositionInNextMatchForWinner = getNextOpponentPosition(previousMatchNumber, nextMatchForWinner);
        let opponentPositionInNextMatchForLooser = getNextOpponentPosition(previousMatchNumber, nextMatchForLooser);
        
        if (isOpponent1Empty && isOpponent2Empty) {
            if (opponentPositionInNextMatchForWinner && opponentPositionInNextMatchForLooser) {
                opponentPositionInNextMatchForWinner.id = CommonConstants.GuidEmpty;
                if (opponentPositionInNextMatchForLooser.id === CommonConstants.GuidEmpty) {
                    return
                } else {
                    opponentPositionInNextMatchForWinner.id = CommonConstants.GuidEmpty;
                }
            }
        } else {
            if (opponentPositionInNextMatchForWinner) {
                opponentPositionInNextMatchForWinner.id = winnerId !== null ? winnerId : opponentPositionInNextMatchForWinner.id;
            }
            
            if (opponentPositionInNextMatchForLooser) {
                opponentPositionInNextMatchForLooser.id = looserId !== null ? looserId : opponentPositionInNextMatchForLooser.id;
            }
        }
    }
    
    
    
    // Возвращает все возможные матчи команды
    const getPossibleMatchesForTeam = (currentMatch: Match, currentRoundId: Id, endRoundId: Id): Match[] => {
        let possibleMatches: Match[] = [];
        let nextMatchId: Id | undefined = currentMatch.id;
        let nextRoundId: Id = currentRoundId;
        
        while (nextRoundId <= endRoundId) {
            const nextMatch = currentMatches.find(match => match.id === nextMatchId && match.round_id === nextRoundId);
            
            if (nextMatch) {
                possibleMatches.push(nextMatch);
                nextMatchId = nextMatch.match_for_winner;
                if (nextMatchId === undefined) {
                    break;
                }
                
                nextRoundId = HelperFunctions.incrementId(nextRoundId);
            } else {
                break;
            }
        }
        
        return possibleMatches;
    };
    
    
    
    // Функция редактирования матча
    const updateCurrentMatches = (
        draggedId: Id | null,
        targetId: Id | null,
        draggedPosition: number | null,
        targetPosition: number | null,
        draggedRoundId: Id,
        targetRoundId: Id
    ) => {
        let newMatches = [...currentMatches];
        
        let draggedMatchIndex = newMatches.findIndex(match =>
            (match.opponent1?.id === draggedId && match.opponent1.position === draggedPosition && match.round_id === draggedRoundId) ||
            (match.opponent2?.id === draggedId && match.opponent2.position === draggedPosition && match.round_id === draggedRoundId)
        );
        
        let targetMatchIndex = newMatches.findIndex(match =>
            (match.opponent1?.id === targetId && match.opponent1.position === targetPosition && match.round_id === targetRoundId) ||
            (match.opponent2?.id === targetId && match.opponent2.position === targetPosition && match.round_id === targetRoundId)
        );
        
        if (draggedMatchIndex !== -1 && targetMatchIndex !== -1) {
            let draggedMatch = newMatches[draggedMatchIndex];
            let targetMatch = newMatches[targetMatchIndex];
            
            const affectedMatches: Match[] = [];
            
            // Обмен оппонентами между матчами в одном раунде
            if (draggedRoundId === targetRoundId) {
                if (draggedMatch.opponent1?.id === draggedId) {
                    if (targetMatch.opponent1?.id === targetId) {
                        [draggedMatch.opponent1.id, targetMatch.opponent1.id] = [targetMatch.opponent1.id, draggedMatch.opponent1.id];
                    } else if (targetMatch.opponent2?.id === targetId) {
                        [draggedMatch.opponent1.id, targetMatch.opponent2.id] = [targetMatch.opponent2.id, draggedMatch.opponent1.id];
                    }
                } else if (draggedMatch.opponent2?.id === draggedId) {
                    if (targetMatch.opponent1?.id === targetId) {
                        [draggedMatch.opponent2.id, targetMatch.opponent1.id] = [targetMatch.opponent1.id, draggedMatch.opponent2.id];
                    } else if (targetMatch.opponent2?.id === targetId) {
                        [draggedMatch.opponent2.id, targetMatch.opponent2.id] = [targetMatch.opponent2.id, draggedMatch.opponent2.id];
                    }
                }
            } else { // Обработка обмена между раундами
                if (!isAdmin) { return }
                
                if (draggedId !== CommonConstants.GuidEmpty && draggedId !== null) {
                    if (draggedMatch.group_id === targetMatch.group_id) {
                        let previousMatchNumber = draggedMatch.number;
                        let possibleMatchesForTeam = getPossibleMatchesForTeam(draggedMatch, draggedMatch.round_id, targetMatch.round_id);
                        
                        possibleMatchesForTeam.forEach((currentMatch) => {
                            if (currentMatch.opponent1 && currentMatch.opponent2) {
                                if (currentMatch.id === draggedMatch.id) {
                                    draggedId === currentMatch.opponent1?.id ? currentMatch.opponent1.result = 'win' : currentMatch.opponent2.result = 'win';
                                    draggedId === currentMatch.opponent1?.id ? currentMatch.opponent2.result = 'loss' : currentMatch.opponent1.result = 'loss'
                                    
                                    currentMatch.status = CommonConstants.MatchCompleted;
                                } else if (currentMatch.id === targetMatch.id) {
                                    previousMatchNumber % 2 === 0 ? currentMatch.opponent2.id = draggedId : currentMatch.opponent1.id = draggedId;
                                } else {
                                    previousMatchNumber % 2 === 0 ? currentMatch.opponent2.result = 'win' : currentMatch.opponent1.result = 'win';
                                    previousMatchNumber % 2 === 0 ? currentMatch.opponent1.result = 'loss' : currentMatch.opponent2.result = 'loss';
                                }
                                
                                affectedMatches.push(currentMatch);
                                previousMatchNumber = currentMatch.number; // Сохраняем номер текущего матча для следующей итерации
                            }
                        });
                    }
                }
            }
            
            setCurrentMatches(newMatches);
            
            setChangedMatches(prevChanged => {
                const newChanges = [...prevChanged];
                
                affectedMatches.forEach(match => {
                    if (!newChanges.find(m => m.id === match.id)) {
                        newChanges.push(match);
                    }
                });
                
                if (!newChanges.find(match => match.id === draggedMatch.id)) {
                    newChanges.push(draggedMatch);
                }
                
                if (!newChanges.find(match => match.id === targetMatch.id)) {
                    newChanges.push(targetMatch);
                }
                
                [draggedMatch, targetMatch].forEach(match => {
                    if (match.match_for_looser !== CommonConstants.GuidEmpty) {
                        const looserMatch = newMatches.find(m => m.id === match.match_for_looser);
                        const looser = match.opponent1?.result === 'loss' ? match.opponent1 : match.opponent2;
                        if (looser?.id !== null && looserMatch) {
                            if (!newChanges.find(m => m.id === looserMatch.id)) {
                                newChanges.push(looserMatch);
                            }
                        }
                    }
                });
                
                return newChanges;
            });
            
            determineResults(newMatches);
            getPossibleMatchesForTeam(draggedMatch, draggedMatch.round_id, targetMatch.round_id).forEach(m => {
                advanceToNextRound(m)
            });
        } else {
            console.error('Не удалось найти draggedId или targetId');
        }
    };
    
    
    
    // Функция для определения названия раунда
    const getSingleBracketRoundName = (roundIndex: number): string => {
        return (
            hasConsolationFinal
                ?
                roundIndex === totalRounds - 1 ? "За 3 место" :
                roundIndex === totalRounds - 2 ? "Финал" :
                roundIndex === totalRounds - 3 ? "Полуфинал" :
                `${roundIndex + 1}`
                :
                roundIndex === totalRounds - 1 ? "Финал" :
                roundIndex === totalRounds - 2 ? "Полуфинал" :
                `${roundIndex + 1}`
        )
    };
    
    
    
    let windowBracketContainer = document.getElementsByClassName(`breacket_mainContainer`)[0] as HTMLElement;
    let bracketContainer = document.getElementsByClassName(`contentContainer`)[0] as HTMLElement;
    
    const maxHeight = () => {
        if (windowBracketContainer && bracketContainer) {
            if (bracketContainer.offsetHeight > windowBracketContainer.offsetHeight) {
                return bracketContainer.offsetHeight - windowBracketContainer.offsetHeight;
            } else {
                return bracketContainer.offsetHeight - windowBracketContainer.offsetHeight;
            }
        } else {
            return 0;
        }
    }
    
    const maxWidth = () => {
        if (windowBracketContainer && bracketContainer) {
            if (bracketContainer.offsetWidth > windowBracketContainer.offsetWidth) {
                return bracketContainer.offsetWidth - windowBracketContainer.offsetWidth + 75 + 60;
            } else {
                return windowBracketContainer.offsetWidth - windowBracketContainer.offsetWidth + 75 + 60;
            }
        } else {
            return 0;
        }
    }
    
    
    
    return (
        <Draggable disabled={!!selectedOpponent} bounds={{ left: -maxWidth(), top: -maxHeight(), right: 0, bottom: 0 }}>
            <div className="elimination_mainContainer">
                <EditMatchModal
                    show={modalIsOpen}
                    onHide={handleCloseModal}
                    match={selectedMatch}
                    participants={participantMap}
                    handleScoreChange={handleScoreChange}
                    handleStatusChange={handleStatusChange}
                    onSave={handleSaveMatch}
                />
                
                <div className="contentContainer">
                    <div className="adminsButton_container">
                        {isSaving  ? (
                                <Spinner className="bracketSavedSpinner" />
                            ) : (
                                <>
                                    {!isEditing && editMode && <button onClick={startEditing}>Редактирование</button>}
                                    {isEditing && (
                                        <>
                                            <button onClick={handleSave} disabled={isSaving}>Сохранить</button>
                                            <button onClick={handleCancel}>Отмена</button>
                                        </>
                                    )}
                                    {isAdmin && isEditing && <button onClick={resetMatchResults}>Сброс сетки</button>}
                                </>
                            )
                        }
                    </div>
                    
                    <div className="elimination_bracketInfo">
                        <div className="elimination_roundNameContainer">
                            {
                                rounds.map((round, index) => (
                                    <h2 key={round.id} className="elimination_roundName">{getSingleBracketRoundName(index) === 'Финал' || getSingleBracketRoundName(index) === 'Полуфинал' || getSingleBracketRoundName(index) === 'За 3 место' ? '' : 'Раунд'} {`${getSingleBracketRoundName(index)}`}</h2>
                                ))
                            }
                        </div>
                    </div>
                    
                    <div className="elimination_bracket">
                        {rounds.filter(round => round.group_id === 0).map((round, index) => (
                            <div key={round.id} className={`elimination_round ${round.id}`}>
                                {currentMatches.filter(match => match.round_id === round.id && match.group_id === 0).map(match => (
                                    <BracketMatch
                                        key={match.id}
                                        match={match}
                                        participantMap={participantMap}
                                        selectedOpponent={selectedOpponent}
                                        isEditing={isEditing}
                                        handleEditStart={handleEditStart}
                                        handleMouseEnter={handleMouseEnter}
                                        handleMouseLeave={handleMouseLeave}
                                        handleDragStart={HelperFunctions.handleDragStart}
                                        handleDragOver={handleDragOver}
                                        handleDrop={handleDrop}
                                        bracketType={stages[0].type}
                                        matches={currentMatches}
                                        roundName={getSingleBracketRoundName(index)}
                                        playerTeamId={playerTeamId}
                                    />
                                ))}
                            </div>
                        ))}
                        
                        {hasConsolationFinal && (
                            <div className="elimination_round">
                                {currentMatches.filter(match => match.match_type === 3).map(match => (
                                    <BracketMatch
                                        key={match.id}
                                        match={match}
                                        participantMap={participantMap}
                                        selectedOpponent={selectedOpponent}
                                        isEditing={isEditing}
                                        handleEditStart={handleEditStart}
                                        handleMouseEnter={handleMouseEnter}
                                        handleMouseLeave={handleMouseLeave}
                                        handleDragStart={HelperFunctions.handleDragStart}
                                        handleDragOver={handleDragOver}
                                        handleDrop={handleDrop}
                                        bracketType={stages[0].type}
                                        matches={currentMatches}
                                        roundName={getSingleBracketRoundName(1)}
                                        playerTeamId={playerTeamId}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>                        
        </Draggable>
    );
}

export default SingleElimination;