import React from 'react';
import './TournamentItem.css';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { getGameIconSrc, getGameTypeName } from '../../logic/CommonLogic';

interface TournamentsItemProps {
    tournamentId: string;
    logoUrl: string;
    name: string;
    startDate: string;
    gameTypeId: string;
    maxSlots: number;
    activeSlots: number;
}

function formatDateWithWeekday(dateString: string) {
    const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // +1, так как месяцы начинаются с 0
    const day = dateObj.getDate().toString().padStart(2, '0');
    const dayOfWeek = daysOfWeek[dateObj.getDay()];

    return `${dayOfWeek} ${day}-${month}-${year}`;
}

const TournamentsItem: React.FC<TournamentsItemProps> = ({ name, gameTypeId, logoUrl, startDate, maxSlots, activeSlots, tournamentId }) => {
    const navigate = useNavigate();
    const formattedDate = formatDateWithWeekday(startDate);
    const tags = ['CS2', '2024', 'SPB'];
    
    return (
        <div className="tournaments_item_container"
            onClick={() => navigate(`/tournament/${tournamentId}`)}>
            <img src={process.env.REACT_APP_IMG_MINIO_URL + logoUrl} alt='' className='tournaments_item_tournamentsImgSrc' />
            <div className='tournaments_item_main_info'>
                <div className='tournaments_item_main'>
                    <div className='tournaments_item_gameImgSrc'><img src={getGameIconSrc(gameTypeId)} alt='' className='tournaments_item_gameImgSrc' /></div>
                    <div className='tournaments_item_info'>
                        <div className='tournaments_item_info_title'>{getGameTypeName(gameTypeId)}: {name}</div>
                        <p>{formattedDate}</p>
                    </div>
                </div>
                <div className='tournaments_item_tags'>
                    {tags.map((tag, index) => (
                        <div key={index} className="tag"><p>{tag}</p></div>
                    ))}
                </div>
                <div className='tournaments_item_slots'>
                    <ProgressBar className='tournament_item_progress_bar' max={maxSlots} now={activeSlots} />
                    <div className='tournaments_item_slots_text'>
                        <div>{maxSlots} SLOTS</div><div>{maxSlots - activeSlots} LEFT</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TournamentsItem;